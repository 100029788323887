import React from 'react';
import PropTypes from 'prop-types';

function ThirdButton({ text, handleClick }) {
  return (
    <button
      type="button"
      className="text-blue-800 font-bold text-xl hover:text-blue-600 font-poppins"
      onClick={handleClick}
    >
      {text}
    </button>
  );
}

ThirdButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ThirdButton;
