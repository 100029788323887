import React from 'react';
// import PropTypes from 'prop-types';
import { Tooltip } from '@mantine/core';
import classNames from '../utils/classNames';

function H4({ text, blue }) {
  return (
    <Tooltip label={text} color="dark" transition="fade" position="bottom">
      <h4
        className={classNames(
          'text-base md:text-sm font-poppins font-bold truncate',
          blue && 'text-purpleTH'
        )}
      >
        {text}
      </h4>
    </Tooltip>
  );
}

// H4.propTypes = {
//   text: PropTypes.string.isRequired,
// };

export default H4;
