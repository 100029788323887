import React from 'react';
// import PropTypes from 'prop-types';

function CardContainer({ children }) {
  return (
    <section className="flex flex-1 flex-col bg-white rounded-xl w-full p-4 items-center justify-center gap-5 md:flex-row md:flex-wrap">
      {children}
    </section>
  );
}

CardContainer.propTypes = {};

export default CardContainer;
