import React from 'react';
// import PropTypes from 'prop-types'
import {
  ChevronDoubleLeft,
  ChevronDoubleRigt,
} from '../../../_desingModels/iconComponets/PaginationIcon';
import H3 from '../../../_desingModels/textComponents/H3';

function Pagination() {
  return (
    <div className="flex justify-center items-center gap-5 text-gray-800">
      <button
        type="button"
        className="text-indigo-500 font-bold hover:text-indigo-800 hover:bg-indigo-500/20"
      >
        <ChevronDoubleLeft />
      </button>
      <H3 text="1 de 67" />
      <button
        type="button"
        className="text-indigo-500 font-bold hover:text-indigo-800 hover:bg-indigo-500/20"
      >
        <ChevronDoubleRigt />
      </button>
    </div>
  );
}

Pagination.propTypes = {};

export default Pagination;
