import React from 'react';
// import PropTypes from 'prop-types'

function InputSend({ type, text }) {
  return (
    <input
      className=" w-full border-0 rounded-lg p-2 bg-indigo-500 text-white font-bold text-lg cursor-pointer hover:bg-indigo-700"
      type={type}
      value={text}
    />
  );
}

InputSend.propTypes = {};

export default InputSend;
