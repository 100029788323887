import React from 'react';
// import PropTypes from 'prop-types';
import { Tooltip } from '@mantine/core';
import className from '../utils/classNames';

const textFillColor = (bgColor) => {
  let textColor = '';
  if (bgColor === 'bgRed') {
    textColor = 'bg-rose-700/20';
  }
  if (bgColor === 'bgIndigo') {
    textColor = 'bg-indigo-500/20';
  }
  return `${textColor} py-1 px-2 rounded-md w-fit`;
};

function H5({ text, colorText, bgText }) {
  return (
    <Tooltip label={text} color="dark" transition="fade" position="bottom">
      <h5
        className={className(
          colorText === 'colorGray800' && 'text-gray-800',
          colorText === 'colorGray400' && 'text-gray-400',
          colorText === 'colorGray600' && 'text-gray-600',
          bgText && textFillColor(bgText),
          'text-sm font-semibold truncate font-poppins lg:text-xs'
        )}
      >
        {text}
      </h5>
    </Tooltip>
  );
}

// H5.propTypes = {
//   text: PropTypes.string.isRequired,
// };

export default H5;
