/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query';
import { showTipoPago } from '../api/requestCatalogos';
import {
  showActividad,
  showAntiguedad,
  showTamanio,
  showDepartamento,
  showProvincia,
  showDistrito,
  showComboClient,
  showComboSede,
  showComboContratista,
} from '../api/requests';

// const key = 'selects';
/* Select activifad tamño, antiguedad */
export const useShowActividad = () => useQuery('actividad', showActividad);
export const useShowAntiguedad = () => useQuery('antiguedad', showAntiguedad);
export const useShowTamanio = () => useQuery('tamanio', showTamanio);

/* Select client sede contratista */
export const useShowComboClient = () =>
  useQuery('comboClient', showComboClient);
export const useShowComboSede = (idClient) =>
  useQuery(['comboSede', idClient], () => showComboSede(idClient), {
    enabled: !!idClient,
  });
export const useShowComboContratista = (idClient) =>
  useQuery(
    ['comboContratista', idClient],
    () => showComboContratista(idClient),
    {
      enabled: !!idClient,
    }
  );

/* Select Departamento Provincia Distrito */

export const useShowDepartameto = () =>
  useQuery('departamento', showDepartamento);
export const useShowProvincia = (idDepartamento) =>
  useQuery(['provincia', idDepartamento], () => showProvincia(idDepartamento), {
    // The query will not execute until the userId exists
    enabled: !!idDepartamento,
  });
export const useShowDistrito = (idDepartamento, idProvincia) =>
  useQuery(
    ['provincia', idDepartamento, idProvincia],
    () => showDistrito(idDepartamento, idProvincia),
    {
      // The query will not execute until the userId exists
      enabled: !!idProvincia,
    }
  );
export const useShowTipoPago = () => useQuery('tipoPago', showTipoPago);
