import React from 'react';
// import PropTypes from 'prop-types';
import H5 from '../../../textComponents/H5';
import P from '../../../textComponents/P';
import classNames from '../../../utils/classNames';

function Td({ td, mainText, secondaryText }) {
  if (td === 'td1') {
    return (
      <td
        className={classNames(
          'flex flex-col w-full justify-center items-center'
          // full ? 'w-32 text-center' : 'max-w-[100px]'
        )}
      >
        <H5 text={mainText} colorText="colorGray800" />
        <H5 text={secondaryText} colorText="colorGray400" />
      </td>
    );
  }
  if (td === 'td2') {
    return (
      <td
        className={classNames(
          'flex flex-col w-full justify-center items-center'
          // full ? 'w-32 text-center' : 'max-w-[100px]'
        )}
      >
        <H5 text={mainText} colorText="colorGray600" />
        <H5 text={secondaryText} colorText="colorGray400" />
      </td>
    );
  }
  if (td === 'td3') {
    return (
      <td
        className={classNames(
          'flex flex-col w-full justify-center items-center'
          // full ? 'w-32 text-center' : 'max-w-[100px]'
        )}
      >
        <H5 text={mainText} colorText="colorGray800" />
      </td>
    );
  }
  if (td === 'td4') {
    return (
      <td
        className={classNames(
          'flex flex-col w-full justify-center items-center'
          // full ? 'w-32 text-center' : 'max-w-[100px]'
        )}
      >
        <H5 text={mainText} colorText="colorGray800" bgText="bgRed" />
      </td>
    );
  }
  if (td === 'td5') {
    return (
      <td
        className={classNames(
          'flex flex-col w-full justify-center items-center'
          // full ? 'w-32 text-center' : 'max-w-[100px]'
        )}
      >
        <H5 text={mainText} colorText="colorGray800" bgText="bgIndigo" />
      </td>
    );
  }
  if (td === 'td6') {
    return (
      <td
        className={classNames(
          'flex flex-col w-full justify-center items-center'
          // full ? 'w-32 text-center' : 'max-w-[100px]'
        )}
      >
        <H5 text={mainText} colorText="colorGray400" />
        <H5 text={secondaryText} colorText="colorGray800" />
      </td>
    );
  }
  return (
    <td
      className={classNames(
        'flex flex-col w-full justify-center items-center'
        // full ? 'w-32 text-center' : 'max-w-[100px]'
      )}
    >
      <P text={mainText} />
    </td>
  );
}

// Td.propTypes = {
//   mainText: PropTypes.string.isRequired,
//   secondaryText: PropTypes.string.isRequired,
// };

export default Td;
