/* eslint-disable no-unused-vars */
import { Notification } from '@mantine/core';
import moment from 'moment';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useStateData } from '../../../../context/dataContext';
import { useMutateProtocol } from '../../../../hooks/protocols';
import { useShowComboClient } from '../../../../hooks/selects';
import CheckCircleIcon from '../../../../_desingModels/iconComponets/CheckCircle';
import XIcon from '../../../../_desingModels/iconComponets/XIcon';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import InputCalendar from '../../../../_desingModels/layout/components/calendar/InputCalendar';
import Input from '../../../../_desingModels/layout/components/form/Input/Input';
import TextArea from '../../../../_desingModels/layout/components/form/textArea/TextArea';
import Radio from '../../../../_desingModels/layout/components/radio/Radio';
import Checkbox from '../../../../_desingModels/layout/components/select/Checkbox';
import Select from '../../../../_desingModels/layout/components/select/Select';
import SelectSearch from '../../../../_desingModels/layout/components/select/SelectSearch';

const estado = [
  { value: 1, label: 'Vigente' },
  { value: 2, label: 'Histórico' },
  { value: 3, label: 'Cotización' },
  { value: 4, label: 'Complementario' },
];
const option2 = [
  { name: 'Por Examén', id: 1 },
  { name: 'Preocupacional', id: 2 },
  { name: 'Periódico', id: 3 },
  { name: 'Preventivo', id: 4 },
  { name: 'Retiro', id: 5 },
  { name: 'Reubicación', id: 6 },
  { name: 'Otros', id: 7 },
  { name: 'Visita', id: 8 },
  { name: 'Reincorporación laboral', id: 9 },
];
const option1 = [{ name: 'Ocupacional', id: 1 }];
const caducidad = [
  { name: '1 año', id: 1 },
  { name: '2 años', id: 2 },
];

function InputContainers({ children }) {
  return (
    <div className="lg:px-16 w-full flex flex-col gap-5 md:flex-row md:gap-2">
      {children}
    </div>
  );
}
function ModalAddProtocol({ setOpened }) {
  const { register, reset, control, handleSubmit, setValue } = useForm();
  const { examData } = useStateData();
  const comboClient = useShowComboClient();
  const {
    mutate,
    isLoading,
    isSuccess,
    isError,
    reset: resetQuery,
  } = useMutateProtocol();
  // console.log('combo', comboClient?.data?.data?.sede_combo);
  const onSubmit = (data) => {
    const newData = {
      ...data,
      examen: examData,
      caducidad: data.id_tiempo_caducidad,
    };
    console.log(newData, 'protocol');
    mutate(newData);
    reset();
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pb-5 w-full flex flex-col gap-5 mt-10"
      >
        <InputContainers>
          <div className="flex flex-col font-bold text-blue-800 w-full">
            Empresa
            <Controller
              control={control}
              name="empresa"
              render={({ field }) => (
                <SelectSearch
                  dataClient={comboClient?.data?.data?.sede_combo}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </div>
          <div>
            <div className="flex flex-col  font-bold text-blue-800 w-full">
              Categoría
              <Select
                itemArray={option1}
                modal
                setValue={setValue}
                registerValue={register('categoria', { required: true })}
                idSelect="categoria"
                isSelect
              />
            </div>
          </div>
          <div>
            <div className="flex flex-col  font-bold text-blue-800 w-full">
              Tipo
              <Select
                itemArray={option2}
                modal
                setValue={setValue}
                registerValue={register('tipo', { required: true })}
                idSelect="tipo"
                isSelect
              />
            </div>
          </div>
        </InputContainers>
        <InputContainers>
          <div>
            <div className="flex flex-col  font-bold text-blue-800">
              Estado
              <div className="flex flex-col items-start gap-2 md:flex-row">
                {estado.map((e) => (
                  <Radio
                    key={e.value}
                    modal
                    name={e.label}
                    htmlFor={e.label}
                    textLabel={e.label}
                    value={e.value}
                    registerValue={register('estado', {
                      required: true,
                    })}
                  />
                ))}
              </div>
            </div>
          </div>
        </InputContainers>
        <InputContainers>
          <div className="w-full">
            <Input
              modal
              textLabel="Nombre del Protocolo"
              type="text"
              placeholder=""
              registerValue={register('nombreProtocolo', {
                required: true,
              })}
            />
          </div>
          <div>
            <div className="flex flex-col  font-bold text-blue-800 w-full gap-1">
              Caducidad
              <Select
                itemArray={caducidad}
                modal
                setValue={setValue}
                registerValue={register('id_tiempo_caducidad', {
                  required: true,
                })}
                idSelect="id_tiempo_caducidad"
                isSelect
              />
            </div>
          </div>
          <div className="flex flex-col  font-bold text-blue-800 w-full gap-1">
            Fecha de vencimiento
            <div className="w-full">
              <Controller
                control={control}
                name="vencimiento"
                render={({ field }) => (
                  <InputCalendar
                    placeholder="Mes 00, 0000"
                    onChange={(date) =>
                      field.onChange(moment(date).format('YYYY/MM/DD'))
                    }
                    selected={moment(field.value).format('YYYY/MM/DD')}
                  />
                )}
              />
            </div>
          </div>
        </InputContainers>
        <InputContainers>
          <article className="flex gap-4 w-full items-center ">
            <div className="w-full">
              <TextArea
                modal
                textLabel=""
                placeholder="Comentarios"
                registerValue={register('comentarios', {
                  required: true,
                })}
              />
            </div>
          </article>
        </InputContainers>

        <InputContainers>
          <div className="w-full">
            <Checkbox modalProtocol />
          </div>
        </InputContainers>
        <div className="pt-8 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
          <PrimaryButton type="input" text="Guardar" blue />
          <PrimaryButton
            text={isSuccess ? 'Cerrar' : 'Cancelar'}
            modal
            handleClick={() => setOpened(false)}
          />
        </div>
      </form>
      {isLoading ? (
        <Notification
          loading
          title="Uploading data to the server"
          disallowClose
        >
          Espere hasta que se carguen los datos, no puede cerrar esta
          notificación aún
        </Notification>
      ) : (
        <>
          {isError ? (
            <Notification
              icon={<XIcon size={18} />}
              color="red"
              title="Error"
              onClose={resetQuery}
            >
              Ups! Hubo un error al guardar los datos
            </Notification>
          ) : null}

          {isSuccess ? (
            <Notification
              icon={<CheckCircleIcon size={18} />}
              color="teal"
              title="Correcto"
              onClose={resetQuery}
            >
              Se guardarón los datos correctamente
            </Notification>
          ) : null}
        </>
      )}
    </>
  );
}

export default ModalAddProtocol;
