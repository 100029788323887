/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */

import React, { useState } from 'react';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import H1 from '../../../../_desingModels/textComponents/H1';
import ModalWithBtn from '../../../layaouts/modal/ModalWithBtn';
import Pagination from '../../../layaouts/pagination/Pagination';
import AccordionItem from './AccordionItem';
import ModalForm from './ModalFormAdd';
import ModalFormEdited from './ModalFormEdited';
import { useRole } from '../../../../hooks/roles';
import { useModule } from '../../../../hooks/modules';
import Loading from '../../../../_desingModels/layout/components/loading/Loading';
import Notification from '../../../../_desingModels/layout/components/notifications/Notification';

function Roles() {
  const [opened, setOpened] = useState(false);
  const [openedEdited, setOpenedEdited] = useState(false);
  const [dataRol, setDataRole] = useState({});

  const { isLoading, isError, data } = useRole();
  const modules = useModule();

  const handleClick = () => {
    setOpened(true);
  };
  const handleClickEdited = (item) => {
    setOpenedEdited(true);
    setDataRole(item);
  };

  return (
    <section className="flex flex-col bg-white w-full rounded-xl ">
      <section className="p-4 w-full md:px-20 flex flex-col md:flex-row md:justify-between">
        <H1 text="Roles" />
        <PrimaryButton
          type="button"
          text="Nuevo"
          blue
          handleClick={handleClick}
        />
        <ModalWithBtn opened={opened} setOpened={setOpened} title="Nuevo Rol">
          <ModalForm
            modulesName={modules.isSuccess && modules.data.data.modulos}
            setOpened={setOpened}
          />
        </ModalWithBtn>
      </section>
      <section className="w-full px-5 md:px-20 flex-1">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {isError ? (
              <Notification message="Ups hubo un error, intentar más tarde!" />
            ) : (
              <AccordionItem
                roles={data.data.roles_detalle}
                handleClickEdited={handleClickEdited}
              />
            )}
          </>
        )}

        <ModalWithBtn
          opened={openedEdited}
          setOpened={setOpenedEdited}
          title="Editar Rol"
        >
          <ModalFormEdited
            modulesName={modules.isSuccess && modules.data.data.modulos}
            setOpened={setOpenedEdited}
            dataRol={dataRol}
          />
        </ModalWithBtn>
      </section>
      <footer className="w-full p-4 flex flex-col gap-5">
        {isLoading ? null : <>{isError ? null : <Pagination />}</>}
      </footer>
    </section>
  );
}

Roles.propTypes = {};

export default Roles;
