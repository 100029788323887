/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { useStateData } from '../../../../context/dataContext';
import PrimaryButton from '../Buttons/PrimaryButton';

function Checkbox({ modalProtocol, secondModalProtocol, tree, handleChange }) {
  const { setExamData, setExamSecondData, checkedSecond, setCheckedSecond } =
    useStateData();
  const { checked, setChecked } = useStateData();
  const [expanded, setExpanded] = useState([]);
  const [expandedSecond, setExpandedSecond] = useState([]);

  if (!modalProtocol) {
    return (
      <>
        <div className="w-full h-40 overflow-scroll">
          <CheckboxTree
            nodes={tree}
            checked={checked}
            expanded={expanded}
            iconsClass="fa5"
            onCheck={(check) => setChecked(check)}
            onExpand={(expand) => setExpanded(expand)}
          />
        </div>
        <div className="mt-4 mb-2 w-full flex flex-col justify-start items-start md:flex-row">
          <PrimaryButton
            text="Guardar"
            modal
            handleClick={() => handleChange(checked)}
          />
        </div>
      </>
    );
  }
  if (secondModalProtocol) {
    setExamSecondData(checkedSecond);
    return (
      <div className="w-full h-40 overflow-scroll">
        <CheckboxTree
          nodes={tree}
          checked={checkedSecond}
          expanded={expandedSecond}
          iconsClass="fa5"
          onCheck={(check) => setCheckedSecond(check)}
          onExpand={(expand) => setExpandedSecond(expand)}
        />
      </div>
    );
  }
  setExamData(checked);
  return (
    <div className="w-full h-40 overflow-scroll">
      <CheckboxTree
        nodes={tree}
        checked={checked}
        expanded={expanded}
        iconsClass="fa5"
        onCheck={(check) => setChecked(check)}
        onExpand={(expand) => setExpanded(expand)}
      />
    </div>
  );
}

export default Checkbox;
