import React from 'react';
import classNames from '../../../_desingModels/utils/classNames';
// import PropTypes from 'prop-types';

function Wrapper({ children, nofullScreen = false }) {
  return (
    <section
      className={classNames(
        'flex flex-col bg-white rounded-xl w-full items-center gap-5 ',
        nofullScreen ? 'h-full' : 'md:h-screen'
      )}
    >
      {children}
    </section>
  );
}

Wrapper.propTypes = {};

export default Wrapper;
// justify-center
