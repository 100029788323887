import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mantine/core';

function H1({ text }) {
  return (
    <Tooltip label={text} color="dark" transition="fade" position="bottom">
      <h1 className="text-2xl text-purpleT font-poppins font-bold md:text-3xl truncate">
        {text}
      </h1>
    </Tooltip>
  );
}

H1.propTypes = {
  text: PropTypes.string.isRequired,
};

export default H1;
