import React from 'react';
// import PropTypes from 'prop-types'
import { Disclosure } from '@headlessui/react';
// import classNames from '../../../utils/classNames'; fixed z-10 shadow-lg right-0

function ContMenuVertical({ children }) {
  return (
    <Disclosure
      as="header"
      className="rounded-md bg-white lg:hidden w-full border "
    >
      {children}
    </Disclosure>
  );
}

ContMenuVertical.propTypes = {};

export default ContMenuVertical;
