import React from 'react';
import { useNavigate } from 'react-router-dom';
import ThirdButton from '../../../../../_desingModels/layout/components/Buttons/ThirdButton';
import H2 from '../../../../../_desingModels/textComponents/H2';
import TableAdmission from '../componets/TableAdmission';
// import PropTypes from 'prop-types'

const data = [
  {
    name: 'José Martinez Salas',
    number: '444444444',
    empresa: 'Empresa',
    tipoexamen: 'Tipo Examen',
  },
  {
    name: 'José Martinez Salas',
    number: '444444444',
    empresa: 'Empresa',
    tipoexamen: 'Tipo Examen',
  },
  {
    name: 'José Martinez Salas',
    number: '444444444',
    empresa: 'Empresa',
    tipoexamen: 'Tipo Examen',
  },
  {
    name: 'José Martinez Salas',
    number: '444444444',
    empresa: 'Empresa',
    tipoexamen: 'Tipo Examen',
  },
  {
    name: 'José Martinez Salas',
    number: '444444444',
    empresa: 'Empresa',
    tipoexamen: 'Tipo Examen',
  },
  {
    name: 'José Martinez Salas',
    number: '444444444',
    empresa: 'Empresa',
    tipoexamen: 'Tipo Examen',
  },
  {
    name: 'José Martinez Salas',
    number: '444444444',
    empresa: 'Empresa',
    tipoexamen: 'Tipo Examen',
  },
  {
    name: 'José Martinez Salas',
    number: '444444444',
    empresa: 'Empresa',
    tipoexamen: 'Tipo Examen',
  },
];
function Admission() {
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    navigate('../admision');
  };

  return (
    <section className="flex flex-col bg-white rounded-xl w-full p-4 items-center justify-center gap-5 lg:w-1/2">
      <H2 text="Admisión" />
      <TableAdmission data={data} />
      <ThirdButton text="Ver más" handleClick={handleClick} />
    </section>
  );
}

Admission.propTypes = {};

export default Admission;
