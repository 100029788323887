/* eslint-disable react/no-array-index-key */
// import { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import HeaderDashboard from '../../../layaouts/headerDashboard/HeaderDashboard';
import Select from '../../../../_desingModels/layout/components/select/Select';
// import PatientCard from '../../../layaouts/cards/PatientCard';
import CardContainer from '../../../layaouts/container/CardContainer';
import Wraper from '../../../layaouts/wrapper/Wrapper';
import Dictionary from './componets/Dictionary';
import Pagination from '../../../layaouts/pagination/Pagination';
// import PatientRegist from './PatientRegist';

const option1 = [
  { name: 'Por atención', id: 1 },
  { name: 'Atendiendose', id: 2 },
  { name: 'Atendiendose', id: 3 },
  { name: 'Completo', id: 4 },
  { name: 'Observado', id: 5 },
];
const option2 = [
  { name: 'Por Examén', id: 1 },
  { name: 'Preocupacional', id: 2 },
  { name: 'Periódico', id: 3 },
  { name: 'Preventivo', id: 4 },
  { name: 'Retiro', id: 5 },
  { name: 'Reubicación', id: 6 },
  { name: 'Otros', id: 7 },
  { name: 'Visita', id: 8 },
  { name: 'Reincorporación laboral', id: 9 },
];

// const pacientes = [
//   {
//     cod: '123-abcd',
//     nombres: 'Castañeda Solorzano',
//     apellidoPaterno: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Ausente',
//   },
//   {
//     cod: '123-abcd',
//     nombres: 'Castañeda Solorzano',
//     apellidoPaterno: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Completo',
//   },
//   {
//     cod: '123-abcd',
//     nombres: 'Castañeda Solorzano',
//     apellidoPaterno: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Atendiendose',
//   },
//   {
//     cod: '123-abcd',
//     nombres: 'Castañeda Solorzano',
//     apellidoPaterno: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Observado',
//   },
//   {
//     cod: '123-abcd',
//     nombres: 'Castañeda Solorzano',
//     apellidoPaterno: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Ausente',
//   },
//   {
//     cod: '123-abcd',
//     nombres: 'Castañeda Solorzano',
//     apellidoPaterno: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Completo',
//   },
//   {
//     cod: '123-abcd',
//     nombres: 'Castañeda Solorzano',
//     apellidoPaterno: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Atendiendose',
//   },
//   {
//     cod: '123-abcd',
//     nombres: 'Castañeda Solorzano',
//     apellidoPaterno: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Observado',
//   },
//   {
//     cod: '123-abcd',
//     nombres: 'Castañeda Solorzano',
//     apellidoPaterno: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Ausente',
//   },
//   {
//     cod: '123-abcd',
//     nombres: 'Castañeda Solorzano',
//     apellidoPaterno: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Completo',
//   },
// ];

function MainAdmission() {
  return (
    <>
      <Wraper>
        <HeaderDashboard text="Admisión">
          <Select itemArray={option1} />
          <Select itemArray={option2} />
        </HeaderDashboard>
        <CardContainer>
          {/* {pacientes.map((paciente, index) => (
            <PatientCard key={index} patient={paciente} />
          ))} */}
        </CardContainer>
        <footer className="w-full p-4 flex flex-col gap-5 lg:flex-row lg:justify-between">
          <Dictionary />
          <Pagination />
        </footer>
      </Wraper>

      {/* <PatientRegist /> */}
    </>
  );
}

MainAdmission.propTypes = {};

export default MainAdmission;
