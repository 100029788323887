/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Notification } from '@mantine/core';
import { CheckCircleIcon } from '@heroicons/react/solid';
import Input from '../../../../_desingModels/layout/components/form/Input/Input';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import XIconIconMobile from '../../../../_desingModels/iconComponets/XIcon';
import { useUpdateExam } from '../../../../hooks/catalogo';
import H2 from '../../../../_desingModels/textComponents/H2';

function InputContainers({ children }) {
  return (
    <div className="lg:px-10 w-full flex flex-col gap-5 md:flex-row md:gap-2">
      {children}
    </div>
  );
}

function ModalEditExam({ setOpened, edit }) {
  const { register, control, handleSubmit, setValue, resetField } = useForm();

  const updateExtra = useUpdateExam();
  const onSubmit = async (dataForm) => {
    try {
      const newData = {
        id_examen: edit.id,
        ...dataForm,
      };
      updateExtra.mutate(newData);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <form
        id="examen"
        onSubmit={handleSubmit(onSubmit)}
        className="pb-5 w-full flex flex-col gap-5 mt-10"
      >
        <InputContainers>
          <div className="w-full md:w-1/2">
            <Input
              modal
              textLabel="Examen"
              type="text"
              defaultValue={edit.examen}
              registerValue={register('examen', { required: true })}
            />
          </div>
          <div className="w-full md:w-1/2">
            <Input
              modal
              textLabel="Descripcion"
              type="text"
              defaultValue={edit.descripcion}
              registerValue={register('descripcion', { required: true })}
            />
          </div>
        </InputContainers>
        <InputContainers>
          <H2 text="Precio:" />
        </InputContainers>
        <InputContainers>
          <div className="w-full md:w-1/3">
            <Input
              textLabel="Minimo"
              type="number"
              placeholder="0"
              defaultValue={edit.minimo}
              registerValue={register('minimo', { required: true })}
              modal
            />
          </div>
          <div className="w-full md:w-1/3">
            <Input
              textLabel="Normal"
              type="number"
              placeholder="0"
              defaultValue={edit.normal}
              registerValue={register('normal', { required: true })}
              modal
            />
          </div>
          <div className="w-full md:w-1/3">
            <Input
              textLabel="Maximo"
              type="number"
              placeholder="0"
              defaultValue={edit.alto}
              registerValue={register('alto', { required: true })}
              modal
            />
          </div>
        </InputContainers>
        <div className="mt-4 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
          <PrimaryButton type="submit" text="Guardar" blue />
          <PrimaryButton
            text={updateExtra.isSuccess ? 'Cerrar' : 'Cancelar'}
            modal
            handleClick={() => setOpened(false)}
          />
        </div>
      </form>
      {updateExtra.isLoading ? (
        <Notification loading title="Cargando datos" disallowClose>
          Espere hasta que se carguen los datos, no puede cerrar esta
          notificación aún
        </Notification>
      ) : (
        <>
          {updateExtra.isError ? (
            <Notification
              icon={<XIconIconMobile size={18} />}
              color="red"
              title="Error"
              onClose={updateExtra.reset}
            >
              Ups! Hubo un error al guardar los datos
            </Notification>
          ) : null}

          {updateExtra.isSuccess ? (
            <Notification
              icon={<CheckCircleIcon size={18} />}
              color="teal"
              title="Se actualizo correctamente"
              onClose={updateExtra.reset}
            >
              Se guardarón los datos correctamente
            </Notification>
          ) : null}
        </>
      )}
    </>
  );
}

export default ModalEditExam;
