/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { mutateMinPrice, useUpdateQuoteExam } from '../../../../hooks/quote';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import Card from '../../../../_desingModels/layout/components/card/Card';
import SelectDefault from '../../../../_desingModels/layout/components/select/SelectDefault';
import H4 from '../../../../_desingModels/textComponents/H4';
import H5 from '../../../../_desingModels/textComponents/H5';
import classNames from '../../../../_desingModels/utils/classNames';
import Check from '../../../../_desingModels/iconComponets/Check';
import { useStateData } from '../../../../context/dataContext';
import ModalWithBtn from '../../../layaouts/modal/ModalWithBtn';
import ModalInputToken from './ModalInputToken';
import { minPrice } from '../../../../api/requests';

function ModalAddEditExam({ setOpened, dataExam, limitantes, total }) {
  // console.log('exam', dataExam);
  const [modalToken, setModalToken] = useState(false);
  const newLimit = limitantes?.data?.data?.roles?.filter(
    (limit) => dataExam?.limitante !== limit.value
  );
  const defaultV = limitantes?.data?.data?.roles?.filter(
    (limit) => dataExam?.limitante === limit.value
  );
  // console.log(defaultV);
  const { disableButton, setDisableButton } = useStateData();
  const [newPrice, setNewPrice] = useState(total);
  const [minPriceExam, setMinPriceExam] = useState();
  const clientParams = useParams();
  const nameQuote = clientParams['*'].split('/');
  const { register, control, handleSubmit, setValue } = useForm();
  const {
    mutate,
    isLoading,
    isSuccess,
    isError,
    reset: resetQuery,
  } = useUpdateQuoteExam();
  // const probando = minPrice.mutate();
  const onSubmit = (data) => {
    const idQuote = nameQuote[1].split('-');
    const newData = {
      id_cotizacion: idQuote[idQuote.length - 1],
      id_exam: data.id_exam,
      costo: data.costo,
      id_limitante: data.id_limitante,
    };
    mutate(newData);
    // console.log('hola', newLimit);
    console.log(newData);
  };
  const handleChange = async (price) => {
    setNewPrice(parseInt(total, 10) - dataExam.costo + parseInt(price, 10));
    const newData = {
      id_examen: dataExam.value,
      cantidad: price,
    };
    await minPrice(newData).then((response) => {
      console.log(response.data?.[0]);
      setMinPriceExam(response.data?.[0].minPrice);
      if (response.data?.[0].resultado === 0) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
        setModalToken(true);
      }
    });
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-fit">
        <H5 text={`Total: S/. ${newPrice}`} colorText="colorBlack400" />
        <Card
          classNames={classNames(
            'flex flex-col border-2 rounded-md w-56 border-purpleT mt-2'
          )}
        >
          <div className="text-gray-800 p-4 flex flex-col gap-2">
            <H4 text={dataExam.label} blue />
            <div className="w-full flex flex-row items-center gap-2">
              <input
                {...register(`id_exam`, {
                  required: true,
                })}
                className="hidden"
                type="text"
                defaultValue={dataExam.value}
              />
              <input
                {...register(`costo`, {
                  required: true,
                })}
                className="w-24 border-1 rounded-lg text-black/60"
                type="number"
                defaultValue={dataExam.costo}
                onBlur={(e) => handleChange(e.target.value)}
              />
              <H4 text="soles" />
            </div>
            <SelectDefault
              itemArray={newLimit}
              setValue={setValue}
              registerValue={register(`id_limitante`, {
                required: true,
              })}
              idSelect="id_limitante"
              defaultValue={defaultV}
            />
          </div>
        </Card>
        <ModalWithBtn
          opened={modalToken}
          setOpened={setModalToken}
          title="Monto muy bajo"
          tamanio="auto"
        >
          <ModalInputToken
            setOpened={setModalToken}
            minPriceExam={minPriceExam}
          />
        </ModalWithBtn>
        <div className="pt-8 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
          <PrimaryButton
            disabled={!disableButton}
            type="submit"
            text="Guardar"
            blue
          />
          <PrimaryButton
            text={isSuccess ? 'Cerrar' : 'Cancelar'}
            modal
            handleClick={() => setOpened(false)}
          />
        </div>
      </form>
      {isLoading ? (
        <div className="flex flex-row">
          <div className="animate-spin h-5 w-5 rounded-full border-4 border-l-azul" />
          <H4 text="Cargando" />
        </div>
      ) : (
        <>
          {isError ? <H4 text="error" /> : null}

          {isSuccess ? <Check /> : null}
        </>
      )}
    </>
  );
}

export default ModalAddEditExam;
