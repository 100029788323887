import axios from 'axios';

const clienteAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL || 'http://localhost:8081',
  // headers: {
  //   'app-token':
  //     .getItem('app-token') !== null
  //       ? JSON.parse(.getItem('app-token'))
  //       : null,
  //   Authorization:
  //     localStorage.getItem('token') !== null
  //       ? JSON.parse(localStorage.getItem('token'))
  //       : null,
  //   'content-type': 'multipart/form-data',
  // },
});

export const axiosRuc = axios.create({
  baseURL: 'https://api.apis.net.pe/v1/',
});

export default clienteAxios;
