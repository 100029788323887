/* eslint-disable no-nested-ternary */
import React from 'react';
// import PropTypes from 'prop-types'
import Card from '../../../_desingModels/layout/components/card/Card';
import HeaderCard from '../../../_desingModels/layout/components/card/HeaderCard';
import H3 from '../../../_desingModels/textComponents/H3';
import P from '../../../_desingModels/textComponents/P';
import H4 from '../../../_desingModels/textComponents/H4';
import classNames from '../../../_desingModels/utils/classNames';
import PrimaryButton from '../../../_desingModels/layout/components/Buttons/PrimaryButton';

function ProtocolsCard({ protocol, handleClickDetailProtocol }) {
  return (
    <Card
      classNames={classNames(
        'flex flex-col border-2 rounded-md w-56 border-azul2'
      )}
    >
      <HeaderCard classNames={classNames('text-blanc1 p-2 bg-azul2')}>
        <div className="flex justify-between">
          <P text={protocol?.state} />
        </div>
        <H3 text={protocol?.quoteName} />
      </HeaderCard>

      <section>
        <div className="text-gray-800 p-2">
          <P text={protocol?.company} />
          <P text={protocol?.ruc} />
          <div className="flex justify-between pr-2 pt-2">
            <div>
              <H4 text="Sub Total" />
              <P text={protocol?.subtotal} />
            </div>
            <div>
              <H4 text="Total" />
              <P text={protocol?.total} />
            </div>
          </div>
        </div>
        <div className="p-2 flex justify-around">
          <PrimaryButton
            text="Editar"
            handleClick={(e) => {
              handleClickDetailProtocol(e, protocol);
            }}
          />
          <PrimaryButton text="Eliminar" delet />
        </div>
      </section>
    </Card>
  );
}

// QuotesCard.propTypes = {}

export default ProtocolsCard;
