import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from '../App';
import Login from '../pages/login/Login';
import Page404 from '../pages/Page404/Page404';
// import PropTypes from 'prop-types'

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Login />} />
        <Route path="dashboard/*" element={<App />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
}

AppRoutes.propTypes = {};

export default AppRoutes;
