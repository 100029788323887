import { useState } from 'react';
import { DatePicker } from '@mantine/dates';
import { CalendarIcon } from '@heroicons/react/solid';
import moment from 'moment';
// import { Calendar } from 'tabler-icons-react';

function DatePickerCompont({ search, handleSearchDate }) {
  if (search) {
    // const [value, onChange] = useState(new Date());
    // console.log(moment(value).format('YYYY/MM/DD'));

    return (
      <DatePicker
        className="bg-grayT"
        placeholder={new Date().toDateString()}
        label=""
        /* value={new Date()} */
        onChange={(e) => {
          handleSearchDate(moment(e).format('YYYY/MM/DD'));
        }}
        icon={<CalendarIcon className="h-5 w-5" />}
      />
    );
  }

  const [value, onChange] = useState(new Date());
  // console.log(moment(value).format('YYYY/MM/DD'));

  return (
    <DatePicker
      className="bg-grayT"
      placeholder={new Date().toDateString()}
      label=""
      value={value}
      onChange={onChange}
      icon={<CalendarIcon className="h-5 w-5" />}
    />
  );
}
export default DatePickerCompont;
