/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

function SwitchSquare({ isChecked, setIsChecked }) {
  const handleSwitchToggle = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="flex items-center space-x-2">
      <span className="flex flex-col gap-1 font-bold text-blue-800 w-20">
        {!isChecked ? 'VENTA' : 'COMPRA'}
      </span>
      <label
        htmlFor="switchCheckbox"
        className="flex items-center cursor-pointer w-16 h-8 rounded-sm bg-gray-400"
      >
        <input
          id="switchCheckbox"
          type="checkbox"
          className="hidden"
          checked={isChecked}
          onChange={handleSwitchToggle}
        />
        <span
          className={`absolute w-8 h-8 rounded-sm bg-blue-800 transition-transform duration-300 transform ${
            isChecked ? 'translate-x-8' : 'translate-x-0'
          }`}
        />
      </label>
    </div>
  );
}

export default SwitchSquare;
