/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { addProtocol } from '../api/requests';

const key = 'protocol';
export const useMutateProtocol = () => {
  const queryClient = useQueryClient();
  return useMutation(addProtocol, {
    onSuccess: () => {
      queryClient.invalidateQueries(key);
    },
  });
};
