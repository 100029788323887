// import { Outlet } from 'react-router-dom';
// import Header from '../../../layaouts/header/Header';
import { useNavigate } from 'react-router-dom';
import HeaderDashboard from '../../../layaouts/headerDashboard/HeaderDashboard';
import Admission from './modules/Admission';
import Sales from './modules/Sales';
import Quotes from './modules/Quotes';
import ButtonWhitIcon from '../../../../_desingModels/layout/components/Buttons/ButtonWhitIcon';
// // import PropTypes from 'prop-types'

function ComercialInicio() {
  const navigate = useNavigate();

  const handleClickFacturar = (event) => {
    event.preventDefault();
    navigate('../pacientes');
  };

  const handleClickCitas = (event) => {
    event.preventDefault();
    navigate('../citas');
  };

  const handleClickClients = (event) => {
    event.preventDefault();
    navigate('../clientes');
  };

  const handleClickProtocols = (event) => {
    event.preventDefault();
    navigate('../protocolos');
  };

  return (
    <>
      <HeaderDashboard text="Bienvenid@">
        <button
          className="text-white bg-teal-600 p-2 font-bold rounded-md md:h-fit md:text-sm hover:bg-teal-700"
          type="button"
        >
          Abrir Caja chica
        </button>
      </HeaderDashboard>
      <div className="flex flex-col w-full justify-center items-center gap-3 xl:flex-row xl:items-start">
        <section className="flex flex-col w-full xl:w-1/2 ">
          <Sales />
        </section>
        <section className="flex flex-col w-full xl:w-1/2 gap-3 ">
          <div className="flex flex-col gap-3 w-full lg:flex-row">
            <Admission />
            <Quotes />
          </div>
          <div className="flex flex-col bg-white rounded-xl w-full p-4 items-center justify-center gap-3 md:flex-row">
            <ButtonWhitIcon text="Citas" handleClick={handleClickCitas} />
            <ButtonWhitIcon text="Facturar" handleClick={handleClickFacturar} />
            <ButtonWhitIcon text="Clientes" handleClick={handleClickClients} />
            <ButtonWhitIcon
              text="Protocolos"
              handleClick={handleClickProtocols}
            />
          </div>
        </section>
      </div>
    </>
  );
}

ComercialInicio.propTypes = {};

export default ComercialInicio;
