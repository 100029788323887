import React from 'react';
// import PropTypes from 'prop-types'

function Image({ url, alt }) {
  return <img className="object-cover" src={url} alt={alt} />;
}

Image.propTypes = {};

export default Image;
