/* eslint-disable no-unused-vars */
import React from 'react';
import { UploadIcon } from '@heroicons/react/outline';

function UploadX({ name }) {
  return (
    <div className="flex items-center space-x-2">
      <span className="flex flex-col gap-1 font-bold text-blue-800">
        {name}
      </span>
      <div className="flex cursor-pointer w-10 h-10 bg-blue-800 justify-center items-center">
        <UploadIcon
          className="block h-6 w-6 text-white text-center"
          aria-hidden="true"
        />
      </div>
      <span className="flex flex-col gap-1 font-light text-blue-800">
        Comprobante.formato
      </span>
    </div>
  );
}

export default UploadX;
