import React, { useEffect, useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

function TreeCheckSearch({ tree, checkSi }) {
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (searchText.trim() === '') {
      setExpanded([]);
      return;
    }

    const matchingValues = tree.reduce((acc, node) => {
      if (node.children) {
        const matchingChildren = node.children
          .map((child) =>
            child.label.toLowerCase().includes(searchText.toLowerCase())
              ? node.value
              : null
          )
          .filter(Boolean);
        if (matchingChildren.length > 0) {
          acc.push(...matchingChildren);
        }
      }
      return acc;
    }, []);

    setExpanded(matchingValues);
  }, [searchText]);

  useEffect(() => {
    checkSi(checked);
  }, [checked, checkSi]);

  return (
    <div className="w-full flex flex-col gap-2">
      <input
        type="text"
        placeholder="Buscar..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        className="w-full md:w-1/2 rounded-lg p-2 text-black/60 bg-white border border-purpleT"
      />
      <div className="w-full max-h-52 overflow-scroll">
        <CheckboxTree
          nodes={tree}
          checked={checked}
          expanded={expanded}
          iconsClass="fa5"
          onCheck={(check) => setChecked(check)}
          onExpand={(expand) => setExpanded(expand)}
        />
      </div>
    </div>
  );
}

export default TreeCheckSearch;
