/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useContext, useState } from 'react';

// crear el contexto

const DataContext = createContext();

// crear componente Provider, state

export function DataProvider({ children }) {
  // state
  const [departamento, setDepartamento] = useState();
  const [provincia, setProvincia] = useState('');
  const [departamentoDomi, setDepartamentoDomi] = useState();
  const [provinciaDomi, setProvinciaDomi] = useState('');
  const [authenticatedUser, setAuthenticatedUser] = useState({});
  const [userId, setUserId] = useState('');
  const [cliente, setCliente] = useState('');
  const [examData, setExamData] = useState([]);
  const [examSecondData, setExamSecondData] = useState([]);
  const [quoteDataMain, setQuoteDataMain] = useState([]);
  const [tokenData, setTokenData] = useState();
  const [minimun, setMinimun] = useState();
  const [activated, setActivated] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [newFormView, setNewFormView] = useState();
  const [dataExam, setDataExam] = useState([]);
  const [formState, setFormState] = useState(false);
  const [checked, setChecked] = useState([]);
  const [checkedSecond, setCheckedSecond] = useState([]);
  const [count, setCount] = useState('');

  return (
    <DataContext.Provider
      value={{
        // states
        departamento,
        provincia,
        authenticatedUser,
        departamentoDomi,
        provinciaDomi,
        userId,
        cliente,
        examData,
        examSecondData,
        quoteDataMain,
        tokenData,
        minimun,
        activated,
        disableButton,
        newFormView,
        dataExam,
        formState,
        checked,
        checkedSecond,
        count,
        // func
        setDepartamento,
        setProvincia,
        setDepartamentoDomi,
        setProvinciaDomi,
        setAuthenticatedUser,
        setUserId,
        setCliente,
        setExamData,
        setExamSecondData,
        setQuoteDataMain,
        setTokenData,
        setMinimun,
        setActivated,
        setDisableButton,
        setNewFormView,
        setDataExam,
        setFormState,
        setChecked,
        setCheckedSecond,
        setCount,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

// custom hook
export const useStateData = () => {
  const context = useContext(DataContext);

  if (context === undefined) {
    throw new Error('useStateAuth must be used within a DataProvider');
  }

  return context;
};
