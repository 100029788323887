/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  addRole,
  showRoles,
  showRolesCombo,
  editedRoles,
} from '../api/requests';

const key = 'roles';

export const useMutateRole = () => {
  const queryClient = useQueryClient();

  return useMutation(addRole, {
    onSuccess: () => {
      queryClient.invalidateQueries(key);
    },
  });
};
export const useMutateRoleEdit = () => {
  const queryClient = useQueryClient();

  return useMutation(editedRoles, {
    onSuccess: () => {
      queryClient.invalidateQueries(key);
    },
  });
};

export const useRole = () => useQuery(key, showRoles);
export const useRoleCombo = () => useQuery(key, showRolesCombo);
