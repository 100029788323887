import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
// import moment from 'moment';
import InputContainers from '../../../layaouts/NewPatient/InputContainers';
// import SelectSearch from '../../../../_desingModels/layout/components/select/SelectSearch';
import Select from '../../../../_desingModels/layout/components/select/Select';
import Input from '../../../../_desingModels/layout/components/form/Input/Input';
import CheckBox from '../../../../_desingModels/layout/components/radio/CheckBox';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';

function ModalAddPettyCashSale({ setOpened }) {
  const { register, setValue } = useForm();
  // eslint-disable-next-line no-unused-vars
  const [nameQuote, setNameQuote] = useState('');

  // const comboClient = {
  //   data: {
  //     data: {
  //       sede_combo: ['CC1', 'CC2', 'CC3'],
  //     },
  //   },
  // };

  const option1 = [
    { name: 'Tipo', id: 0, abrev: '?' },
    { name: 'EXAMEN DE VISITA ANEXO 16A', id: 1, abrev: 'EVA16A' },
    { name: 'PRE OCUPACIONAL', id: 2, abrev: 'PREO' },
    { name: 'PERIODICO', id: 3, abrev: 'PER' },
    { name: 'RETIRO', id: 4, abrev: 'RET' },
    { name: 'REINCORPORACION', id: 5, abrev: 'REINC' },
    {
      name: 'EXAMENES DE APOYO DIAGNOSTICO: RX - EKG - AUDIO - ESPIRO PISCOLO Y OTROS',
      id: 6,
      abrev: 'EXAMAD',
    },
    { name: 'ANALISIS CLINICOS DE LAB', id: 7, abrev: 'ANALAB' },
    { name: 'EXAMEN DE VISITA ANEXO 16A', id: 8, abrev: 'EXAM16A' },
  ];
  const option2 = [
    { name: 'Subtipo', id: 0, abrev: '?' },
    { name: 'EXAMEN DE VISITA ANEXO 16A', id: 1, abrev: 'EVA16A' },
    { name: 'PRE OCUPACIONAL', id: 2, abrev: 'PREO' },
    { name: 'PERIODICO', id: 3, abrev: 'PER' },
    { name: 'RETIRO', id: 4, abrev: 'RET' },
    { name: 'REINCORPORACION', id: 5, abrev: 'REINC' },
    {
      name: 'EXAMENES DE APOYO DIAGNOSTICO: RX - EKG - AUDIO - ESPIRO PISCOLO Y OTROS',
      id: 6,
      abrev: 'EXAMAD',
    },
    { name: 'ANALISIS CLINICOS DE LAB', id: 7, abrev: 'ANALAB' },
    { name: 'EXAMEN DE VISITA ANEXO 16A', id: 8, abrev: 'EXAM16A' },
  ];
  const option3 = [
    { name: 'Elige el metodo', id: 0, abrev: '?' },
    { name: 'Transferencia', id: 1, abrev: 'EVA16A' },
    { name: 'N° de operacion', id: 2, abrev: 'PREO' },
    { name: 'Efectivo', id: 3, abrev: 'PER' },
  ];

  return (
    <>
      <InputContainers>
        <div>
          <div className="flex flex-row gap-4 font-bold text-blue-800 w-full">
            Comprobante
            <Select
              itemArray={option1}
              modal
              setValue={setValue}
              registerValue={register('categoria', { required: true })}
              idSelect="categoria"
              isSelect
              //   handleChange={(e) => handleChangeOption(e.target.value)}
            />
            <Select
              itemArray={option2}
              modal
              setValue={setValue}
              registerValue={register('categoria', { required: true })}
              idSelect="categoria"
              isSelect
              //   handleChange={(e) => handleChangeOption(e.target.value)}
            />
          </div>
        </div>
        <div className="w-full">
          <Input
            modal
            inputUseState
            textLabel="Nombre / Razon Social"
            type="text"
            placeholder="Ingresa el nombre o razon social"
            value={nameQuote}
            // disabled
          />
        </div>
        <div className="w-full">
          <Input
            modal
            inputUseState
            textLabel="Ruc / DNI"
            type="text"
            placeholder="Ingresa tu RUC"
            value={nameQuote}
            // disabled
          />
        </div>
        <div className="w-full">
          <Input
            modal
            inputUseState
            textLabel="Concepto"
            type="text"
            placeholder="Concepto por el cual se emite el comprobante"
            value={nameQuote}
            // disabled
          />
        </div>
        <div className="w-full">
          <Input
            modal
            inputUseState
            textLabel="Importe"
            type="number"
            placeholder="0"
            value={nameQuote}
            // disabled
          />
        </div>
      </InputContainers>
      <InputContainers>
        <div className="flex flex-col gap-1 font-bold text-blue-800 w-full">
          Tipo de Pago
          <Select
            itemArray={option3}
            modal
            setValue={setValue}
            registerValue={register('categoria', { required: true })}
            idSelect="categoria"
            isSelect
            //   handleChange={(e) => handleChangeOption(e.target.value)}
          />
        </div>
      </InputContainers>
      <InputContainers>
        <CheckBox modalProtocol />
      </InputContainers>
      <div className="mt-4 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
        <PrimaryButton type="input" text="Guardar" blue />
        <PrimaryButton
          text={true ? 'Cerrar' : 'Cancelar'}
          modal
          handleClick={() => setOpened(false)}
        />
      </div>
    </>
  );
}

export default ModalAddPettyCashSale;
