import React from 'react';
// import PropTypes from 'prop-types'

function TBody({ children }) {
  return <tbody className="w-full flex flex-col gap-2">{children}</tbody>;
}

TBody.propTypes = {};

export default TBody;
