/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
// import { Pagination } from '@mantine/core';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import UserCard from '../../../layaouts/cards/UserCard';
import CardContainer from '../../../layaouts/container/CardContainer';
import HeaderDashboard from '../../../layaouts/headerDashboard/HeaderDashboard';
import ModalWithBtn from '../../../layaouts/modal/ModalWithBtn';
import ModalForm from './ModalForm';

import Wrapper from '../../../layaouts/wrapper/Wrapper';
import Pagination from '../../../layaouts/pagination/Pagination';

import { useUser, useSpecialties } from '../../../../hooks/users';
import { useRoleCombo } from '../../../../hooks/roles';
import Loading from '../../../../_desingModels/layout/components/loading/Loading';
import Notification from '../../../../_desingModels/layout/components/notifications/Notification';

function Users({ handleClickDestailUser }) {
  const [opened, setOpened] = useState(false);
  const navigate = useNavigate();
  const { isLoading, isError, data } = useUser();
  const fetchRoles = useRoleCombo();
  const fetchSpecialties = useSpecialties();
  // console.log(fetchSpecialties?.data?.data?.especialidades, 'fetchSpecialties');

  const handleClick = () => {
    setOpened(true);
  };
  const handleClickRoles = () => {
    navigate(`../roles`);
  };
  // console.log(data, 'users');
  return (
    <Wrapper>
      <HeaderDashboard text="Empleados" basic>
        <ModalWithBtn opened={opened} setOpened={setOpened} title="Nuevo ">
          <ModalForm
            roles={fetchRoles.isSuccess && fetchRoles.data.data?.roles}
            specialties={
              fetchSpecialties.isSuccess &&
              fetchSpecialties?.data?.data?.especialidades
            }
            setOpened={setOpened}
          />
        </ModalWithBtn>
        <div className="w-full flex flex-col gap-2 md:flex-row">
          <PrimaryButton text="Roles" blue handleClick={handleClickRoles} />
          <PrimaryButton text="Nuevo" blue handleClick={handleClick} />
        </div>
      </HeaderDashboard>
      <div className="flex-1 ">
        <CardContainer>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {isError ? (
                <Notification message="Ups hubo un error, intentar más tarde!" />
              ) : (
                data.data.users.map((user, index) => (
                  <UserCard
                    key={index}
                    user={user}
                    handleClickDestailUser={handleClickDestailUser}
                  />
                ))
              )}
            </>
          )}
        </CardContainer>
      </div>
      <footer className="w-full p-4 flex flex-col gap-5">
        <Pagination />
      </footer>
    </Wrapper>
  );
}

Users.propTypes = {};

export default Users;
