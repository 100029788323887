/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import ModalAddPettyCashSale from './ModalAddPettyCashSale';
import ModalAddPettyCashBuy from './ModalAddPettyCashBuy';
import SwitchSquare from '../../../../_desingModels/layout/components/switch/SwitchSquare';

function ModalAddPettyCash({ setOpened }) {
  // FALSE to Sale, TRUE to Buy
  const [isBuyOrSell, setIsBuyOrSell] = useState(false);

  return (
    <form
      //   onSubmit={handleSubmit(onSubmit)}
      className="pb-5 w-full flex flex-col gap-5 mt-10"
    >
      <SwitchSquare isChecked={isBuyOrSell} setIsChecked={setIsBuyOrSell} />
      {isBuyOrSell === false ? (
        <ModalAddPettyCashSale setOpened={setOpened} />
      ) : (
        <ModalAddPettyCashBuy setOpened={setOpened} />
      )}
    </form>
  );
}

export default ModalAddPettyCash;
