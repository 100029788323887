import CommercialRoutes from './pages/commercial/CommercialRoutes';
// import ManagementRoutes from './pages/management/ManagementRoutes';

function App() {
  return (
    <div className="bg-gray-100 flex flex-col">
      <CommercialRoutes />
      {/* <ManagementRoutes /> */}
    </div>
  );
}

export default App;
