import React from 'react';
// import PropTypes from 'prop-types';

function Tr({ children }) {
  return (
    <tr className="w-full border rounded-xl md:border-x-0 md:border-t-0 md:border-b-2 md:py-3 md:rounded-none md:px-0 p-5 flex flex-col justify-center gap-2 md:flex-row md:gap-1">
      {children}
    </tr>
  );
}

Tr.propTypes = {};

export default Tr;
