import { BookOpenIcon } from '@heroicons/react/outline';

function ReadingIcon() {
  return (
    <div className="mr-1 p-1 w-auto h-7 rounded-md text-white bg-blue-800">
      <BookOpenIcon className="block h-5 w-5" aria-hidden="true" />
    </div>
  );
}

export default ReadingIcon;
