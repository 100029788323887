/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  showExams,
  showPermissions,
  addExam,
  showExamsUser,
  showExamsCombo,
  showGroupExam,
  addGroup,
  createExam,
  createResponse,
  showExam,
  showRespuesta,
  showExamsTemplate,
} from '../api/requests';

const key = 'exams';

export const useExam = () => useQuery(key, showExams);
export const useExamCombo = () => useQuery('comboExam', showExamsCombo);
export const useGroupCombo = () => useQuery('groupExam', showGroupExam);
export const useExamsTemplate = () =>
  useQuery('templateExam', showExamsTemplate);

export const useMutateExam = () => {
  const queryClient = useQueryClient();
  return useMutation(addExam, {
    onSuccess: () => {
      queryClient.invalidateQueries(key);
    },
  });
};

export const useMutateGroup = () => {
  const queryClient = useQueryClient();
  return useMutation(addGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries('groupExam');
    },
  });
};

export const useMutateCreateExam = () => {
  const queryClient = useQueryClient();
  return useMutation(createExam, {
    onSuccess: () => {
      queryClient.invalidateQueries('createExam');
    },
  });
};

export const useMutateCreateResponse = () => {
  const queryClient = useQueryClient();
  return useMutation(createResponse, {
    onSuccess: () => {
      queryClient.invalidateQueries('createResponse');
    },
  });
};

export const useNewExam = (idExam) =>
  useQuery([key, idExam], () => showExam(idExam), {
    enabled: !!idExam,
  });
export const useNewRespuesta = (idExam, idPaciente) =>
  useQuery([key, idExam, idPaciente], () => showRespuesta(idExam, idPaciente), {
    enabled: [!!idExam, !!idPaciente],
  });

export const useExamsUser = (idUser) =>
  useQuery([key, idUser], () => showExamsUser(idUser), {
    enabled: !!idUser,
  });

const keyPermissions = 'permissions';

export const usePermissions = () => useQuery(keyPermissions, showPermissions);
