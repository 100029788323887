/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useMutateGroup } from '../../../../hooks/exams';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import H4 from '../../../../_desingModels/textComponents/H4';
import Check from '../../../../_desingModels/iconComponets/Check';

function ModalAddGroup({ setOpened }) {
  const { mutate, isLoading, isError, isSuccess } = useMutateGroup();
  const [active, setActive] = useState(false);
  const detectValue = () => {
    const inputValue = document.getElementById('nombre_examen').value;
    if (inputValue.length < 5) {
      setActive(false);
    } else {
      setActive(true);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      nombre_examen: document.getElementById('nombre_examen').value,
    };
    mutate(data);
  };
  return (
    <>
      <div className="w-full p-4 flex flex-col items-start">
        <div className="w-full flex flex-col">
          <label className="font-bold text-purpleT" htmlFor="nombre_examen">
            Nombre de grupo
          </label>
          <input
            className="rounded-lg p-1.5  mt-1 text-black/60 bg-white border border-purpleT"
            id="nombre_examen"
            name="nombre_examen"
            type="text"
            onBlur={detectValue}
          />
        </div>
        <section className="w-full flex justify-center mt-4 gap-2">
          <PrimaryButton
            disabled={!active}
            text="Guardar"
            handleClick={handleSubmit}
          />
          <PrimaryButton
            text={isSuccess ? 'Cerrar' : 'Cancelar'}
            modal
            handleClick={() => setOpened(false)}
          />
        </section>
      </div>
      {isLoading ? (
        <div className="flex gap-2">
          <div className="animate-spin h-5 w-5 rounded-full border-4 border-l-azul" />
          <H4 text="Cargando" />
        </div>
      ) : (
        <>
          {isError ? <H4 text="error" /> : null}

          {isSuccess ? (
            <div className="flex gap-2">
              <Check />
              <H4 text="Datos Guardados" />
            </div>
          ) : null}
        </>
      )}
    </>
  );
}

export default ModalAddGroup;
