/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Check from '../../../_desingModels/iconComponets/Check';
import PrimaryButton from '../../../_desingModels/layout/components/Buttons/PrimaryButton';
import H4 from '../../../_desingModels/textComponents/H4';
import TextArea from '../../../_desingModels/layout/components/form/textArea/TextArea';
import Input from '../../../_desingModels/layout/components/form/Input/Input';
import { useStateData } from '../../../context/dataContext';
import { mutateActivateToken } from '../../../hooks/quote';

function ModalToken({ setOpened }) {
  const { tokenData } = useStateData();
  // console.log('exam', dataExam);
  const { register, handleSubmit } = useForm();
  const { mutate, isLoading, isSuccess, isError } = mutateActivateToken();
  const onSubmit = (data) => {
    const newData = {
      id_token: tokenData.id,
      ...data,
    };
    mutate(newData);
    console.log(newData);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-fit">
        <article className="flex flex-col w-fit">
          <div className="w-fit">
            <Input
              modal
              inputUseState
              textLabel="Token"
              type="text"
              defaultValue={tokenData.token}
              disabled
            />
          </div>
          <div className="w-full">
            <TextArea
              modal
              textLabel=""
              placeholder="Motivo"
              registerValue={register('motivo', {
                required: true,
              })}
            />
          </div>
        </article>
        <div className="pt-4 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
          <PrimaryButton type="submit" text="Guardar" blue />
          <PrimaryButton
            text={isSuccess ? 'Cerrar' : 'Cancelar'}
            modal
            handleClick={() => setOpened(false)}
          />
        </div>
      </form>
      {isLoading ? (
        <div className="flex flex-row">
          <div className="animate-spin h-5 w-5 rounded-full border-4 border-l-azul" />
          <H4 text="Cargando" />
        </div>
      ) : (
        <>
          {isError ? <H4 text="error" /> : null}

          {isSuccess ? <Check /> : null}
        </>
      )}
    </>
  );
}

export default ModalToken;
