/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import HeaderDashVertical from '../../../layaouts/headerDashboard/HeaderDashVertical';
import HeaderDashHorizont from '../../../layaouts/headerDashboard/HeaderDashHorizont';
import Wraper from '../../../layaouts/wrapper/Wrapper';
import CardContainer from '../../../layaouts/container/CardContainer';
import QuotesCard from '../../../layaouts/cards/QuotesCard';
import Pagination from '../../../layaouts/pagination/Pagination';
import { useStateData } from '../../../../context/dataContext';
import TableComponent from '../../../../_desingModels/layout/components/table/Table';
import ModalWithBtn from '../../../layaouts/modal/ModalWithBtn';
import ModalAddQuote from './ModalAddQuote';
import { useCopyQuote, useExamTree, useQuote } from '../../../../hooks/quote';

const option = [
  { name: 'Nombre', id: 1 },
  { name: 'RUC', id: 2 },
  { name: 'Empresa', id: 3 },
];

const quotes = [
  {
    estado: 'Primera Revisión',
    fecha_cotizacion: '2022/08/12',
    no_cotizacion: 'JM-06102022-ADMN-T-01',
    categoria: 'Preocupacional',
    razonSocial: 'CASAGRANDE SAC',
    rucDni: '1234567890',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    estado: 'Aceptada',
    fecha_cotizacion: '2022/08/20',
    no_cotizacion: 'JM-06102022-ADMN-T-02',
    categoria: 'Preocupacional',
    razonSocial: 'CASAGRANDE SAC',
    rucDni: '1234567890',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    estado: 'Primera Revisión',
    fecha_cotizacion: '2022/08/27',
    no_cotizacion: 'JM-06102022-ADMN-T-03',
    categoria: 'Preocupacional',
    razonSocial: 'LASLOMAS SAC',
    rucDni: '2078945658',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    estado: 'Aceptada',
    fecha_cotizacion: '2022/09/11',
    no_cotizacion: 'JM-06102022-ADMN-T-04',
    categoria: 'Preocupacional',
    razonSocial: 'LASLOMAS SAC',
    rucDni: '2078945658',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    estado: 'Primera Revisión',
    fecha_cotizacion: '2022/09/11',
    no_cotizacion: 'JM-06102022-ADMN-T-10',
    categoria: 'Preocupacional',
    razonSocial: 'PERU SAC',
    rucDni: '2045612389',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    estado: 'Aceptada',
    fecha_cotizacion: '2022/09/11',
    no_cotizacion: 'JM-06102022-ADMN-T-12',
    categoria: 'Preocupacional',
    razonSocial: 'PERU SAC',
    rucDni: '2045612389',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    estado: 'Primera Revisión',
    fecha_cotizacion: '2022/09/24',
    no_cotizacion: 'JM-06102022-ADMN-T-13',
    categoria: 'Preocupacional',
    razonSocial: 'PERU SAC',
    rucDni: '2045612389',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    estado: 'Aceptada',
    fecha_cotizacion: '2022/09/24',
    no_cotizacion: 'JM-06102022-ADMN-T-14',
    categoria: 'Preocupacional',
    razonSocial: 'CARGOS SAC',
    rucDni: '2023568945',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    estado: 'Primera Revisión',
    fecha_cotizacion: '2022/10/11',
    no_cotizacion: 'JM-06102022-ADMN-T-15',
    categoria: 'Preocupacional',
    razonSocial: 'CARGOS SAC',
    rucDni: '2023568945',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    estado: 'Aceptada',
    fecha_cotizacion: '2022/10/11',
    no_cotizacion: 'JM-06102022-ADMN-T-16',
    categoria: 'Preocupacional',
    razonSocial: 'CASABLANCA SAC',
    rucDni: '2012457856',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
];

function MainQuotesAu({ quotedata, handleClickDetailQuote }) {
  const [newQuote, setNewQuote] = useState();
  const checkTree = useExamTree();
  const copyQuote = useCopyQuote();

  const handleNewQuote = (event) => {
    event.preventDefault();
    setNewQuote(true);
  };
  const handleClickCopyQuote = (event, qCopy) => {
    event.preventDefault();
    const copyQ = {
      id_cotizacion: qCopy.id,
    };
    copyQuote.mutate(copyQ);
  };

  return (
    <div className="flex w-full flex-col lg:flex-row gap-3">
      <HeaderDashVertical text="Cotizaciones" select={option} data={quotes}>
        {null}
      </HeaderDashVertical>
      <Wraper nofullScreen>
        <HeaderDashHorizont
          title="Cotizaciones recientes"
          handleClick={handleNewQuote}
        >
          <ModalWithBtn
            opened={newQuote}
            setOpened={setNewQuote}
            title="Agregar Cotización"
          >
            <ModalAddQuote
              examTree={checkTree?.data?.data?.examenes}
              setOpened={setNewQuote}
            />
          </ModalWithBtn>
        </HeaderDashHorizont>
        <div className="md:hidden">
          <CardContainer>
            {quotedata?.map((quote, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <QuotesCard
                key={index}
                quote={quote}
                handleClickDetailQuote={handleClickDetailQuote}
              />
            ))}
          </CardContainer>
        </div>
        <div className="hidden md:flex w-full">
          <TableComponent
            data={quotedata}
            handleClickDetailQuote={handleClickDetailQuote}
            handleClickCopyQuote={handleClickCopyQuote}
          />
        </div>
        <Pagination />
      </Wraper>
    </div>
  );
}

MainQuotesAu.propTypes = {};

export default MainQuotesAu;
