/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateToken } from '../../../../api/requests';
import { useStateData } from '../../../../context/dataContext';
import { mutateValidateToken } from '../../../../hooks/quote';
import Check from '../../../../_desingModels/iconComponets/Check';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import Input from '../../../../_desingModels/layout/components/form/Input/Input';
import H4 from '../../../../_desingModels/textComponents/H4';
import P from '../../../../_desingModels/textComponents/P';

function ModalInputToken({ setOpened, minPriceExam }) {
  const [listo, setListo] = useState(false);
  const [failToken, setFailToken] = useState(false);
  const { setDisableButton } = useStateData();
  // console.log('exam', dataExam);
  const { mutate, isLoading, isSuccess, isError } = mutateValidateToken();
  const onSubmit = async () => {
    const tokenData = document.getElementById('token').value;
    const newData = {
      token: tokenData,
    };
    await validateToken(newData).then((response) => {
      console.log(response.data);
      if (response.data === 1) {
        setOpened(false);
        setDisableButton(true);
      } else {
        console.log('aun no');
        setFailToken(true);
      }
    });
    /* setListo(true);
    setActivated(true);
    setDisableButton(false); */
  };
  return (
    <>
      <div className="w-fit">
        <article className="flex flex-col w-fit">
          <P text={`Precio mínimo de examen: S/ ${minPriceExam}`} />
          <div className="w-fit">
            <label
              htmlFor="token"
              className="flex flex-col  font-bold text-purpleT"
            >
              Introducir Token para continuar
              <input
                className="rounded-lg p-1.5  my-1 text-black/60 bg-white border border-indigo-500"
                type="text"
                id="token"
              />
            </label>
          </div>
          {failToken ? <P fail text="Token no Válido o Incorrecto" /> : null}
        </article>
        <div className="pt-2 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
          <PrimaryButton text="Verificar" handleClick={onSubmit} />
          <PrimaryButton
            text={listo ? 'Cerrar' : 'Cancelar'}
            modal
            handleClick={() => setOpened(false)}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="flex flex-row">
          <div className="animate-spin h-5 w-5 rounded-full border-4 border-l-azul" />
          <H4 text="Cargando" />
        </div>
      ) : (
        <>
          {isError ? <H4 text="error" /> : null}

          {listo ? <Check /> : null}
        </>
      )}
    </>
  );
}

export default ModalInputToken;
