import PropTypes from 'prop-types';
import H1 from '../../../_desingModels/textComponents/H1';
import DatePicker from '../../../_desingModels/layout/components/calendar/DatePicker';
import Search from '../../../_desingModels/layout/components/search/Search';
import classNames from '../../../_desingModels/utils/classNames';

function HeaderDashboard({ text, basic, children }) {
  return (
    <header
      className={classNames(
        'flex flex-col bg-white rounded-xl w-full p-4 items-center justify-center gap-5 md:flex-row md:justify-between md:items-start'
      )}
    >
      <section className="flex flex-col gap-3 justify-center items-center md:flex-row  md:items-end">
        <div className="flex flex-col gap-3">
          <H1 text={text} />
          {basic ? null : <DatePicker />}
        </div>
        {children}
      </section>
      <section className="flex flex-col md:flex-row justify-end items-end">
        <Search />
      </section>
    </header>
  );
}

HeaderDashboard.propTypes = {
  text: PropTypes.string.isRequired,
};

export default HeaderDashboard;
