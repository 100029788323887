const dataMock = [
  {
    number: 1,
    date: '2021-09-01',
    name: 'JUAN PEREZ',
    id: '12345678',
    dateComprobante: '2021-09-01',
    concept: 'CONCEPTO 1',
    responsable: 'JUAN PEREZ',
    support: true,
    import: -100.0,
    balance: 100.0,
  },
  {
    number: 2,
    date: '2021-09-01',
    name: 'JUAN PEREZ',
    id: '12345678',
    dateComprobante: '2021-09-01',
    concept: 'CONCEPTO 1',
    responsable: 'JUAN PEREZ',
    support: true,
    import: -100.0,
    balance: 100.0,
  },
  {
    number: 3,
    date: '2021-09-01',
    name: 'JUAN PEREZ',
    id: '12345678',
    dateComprobante: '2021-09-01',
    concept: 'CONCEPTO 1',
    responsable: 'JUAN PEREZ',
    support: true,
    import: 100.0,
    balance: 100.0,
  },
];

/* <tr>
<th>N°</th>
<th>Fecha</th>
<th>Nombre/Razón Social</th>
<th>ID</th>
<th>Fecha Comprobante</th>
<th>Concepto</th>
<th>Responsable</th>
<th>Sustento</th>
<th>Importe</th>
<th>Saldo</th>
</tr> */

export default dataMock;
