import React from 'react';
// import { Select } from '@mantine/core';
import { Notification } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment';
import Wraper from '../wrapper/Wrapper';
import InputContainers from './InputContainers';
import H1 from '../../../_desingModels/textComponents/H1';
import ErrorMessage from '../../../_desingModels/layout/components/form/Message/Message';
import Input from '../../../_desingModels/layout/components/form/Input/Input';
import Section from './Section';
import Select from '../../../_desingModels/layout/components/select/Select';
import Line from '../../../_desingModels/layout/components/Line';
import InputCalendar from '../../../_desingModels/layout/components/calendar/InputCalendar';
import Aside from './Aside';
import CheckCircleIcon from '../../../_desingModels/iconComponets/CheckCircle';
import XIconIconMobile from '../../../_desingModels/iconComponets/XIcon';
import { useMutatePatient } from '../../../hooks/patients';
import Radio from '../../../_desingModels/layout/components/radio/Radio';

const option = [
  { name: 'Tipo de Documento', id: 0 },
  { name: 'DNI', id: 1 },
  { name: 'Carné de Extranjeria', id: 2 },
  { name: 'Pasaporte', id: 3 },
];
const categoria = [
  { name: 'Categoria', id: 0 },
  { name: 'Soltera(o)', id: 1 },
  { name: 'Casada(o)', id: 2 },
  { name: 'Viuda(o)', id: 3 },
  { name: 'Divorciada(o)', id: 4 },
];
const sexo = [
  { name: 'Sexo', id: 0 },
  { name: 'Femenino', id: 1 },
  { name: 'Masculino', id: 2 },
  { name: 'otros', id: 3 },
];
const gradoInstruc = [
  { name: 'Primaria incompleta', id: 0 },
  { name: 'Primaria Completa', id: 1 },
  { name: 'Secundaria incompleta', id: 2 },
  { name: 'Secundaria Completa', id: 3 },
  { name: 'Técnico incompleto', id: 4 },
  { name: 'Técnico Completa', id: 5 },
  { name: 'Universitario incompleto', id: 6 },
  { name: 'Universitario Completa', id: 7 },
  { name: 'Maestría incompleta', id: 8 },
  { name: 'Maestría Completa', id: 9 },
  { name: 'Doctorado incompleto', id: 10 },
  { name: 'Doctorado completo', id: 11 },
  { name: 'Otros', id: 12 },
];
const estadoCivil = [
  { name: 'Estado Civil', id: 0 },
  { name: 'Soltera(o)', id: 1 },
  { name: 'Casada(o)', id: 2 },
  { name: 'Viuda(o)', id: 3 },
  { name: 'Divorciada(o)', id: 4 },
];
const pais = [
  { name: 'Pais', id: 0 },
  { name: 'Perú', id: 1 },
];

/* const empresa = [
  { name: 'Empresa', id: 0 },
  { name: 'Empresa1', id: 1 },
  { name: 'Empresa2', id: 2 },
  { name: 'Empresa3', id: 3 },
]; */
/* const sede = [
  { name: 'Sede', id: 0 },
  { name: 'Sede1', id: 1 },
  { name: 'Sede2', id: 2 },
  { name: 'Sede3', id: 3 },
  { name: 'Sede4', id: 4 },
];
const contratista = [
  { name: 'Constratista', id: 0 },
  { name: 'Contratista1', id: 1 },
  { name: 'Contratista2', id: 2 },
  { name: 'Contratista3', id: 3 },
  { name: 'Contratista4', id: 4 },
]; */
const tipoExamen = [
  { name: 'Tipo de Examen', id: 0 },
  { name: 'Pre ocupacional', id: 1 },
  { name: 'Periódico', id: 2 },
  { name: 'Preventivo', id: 3 },
  { name: 'Retiro', id: 4 },
  { name: 'Reubicación', id: 5 },
  { name: 'Otros', id: 6 },
  { name: 'Visita', id: 7 },
  { name: 'Reincorporación laboral', id: 8 },
];

const prioridad = [
  { name: 'Prioridad', id: 0 },
  { name: 'Prioridad 1', id: 1 },
  { name: 'Prioridad 2', id: 2 },
  { name: 'Prioridad 3', id: 3 },
];
const seguro = [
  { name: 'Seguro', id: 0 },
  { name: 'Essalud', id: 1 },
  { name: 'FFAA', id: 2 },
  { name: 'Policia', id: 3 },
  { name: 'Privado', id: 4 },
  { name: 'EPS', id: 5 },
  { name: 'Otro', id: 6 },
  { name: 'Ninguno', id: 7 },
];
const protocolo = [
  { name: 'Protocolo', id: 0 },
  { name: 'Pre ocupacional', id: 1 },
  { name: 'Periódico', id: 2 },
  { name: 'Preventivo', id: 3 },
  { name: 'Retiro', id: 4 },
  { name: 'Reubicación', id: 5 },
  { name: 'Otros', id: 6 },
  { name: 'Visita', id: 7 },
  { name: 'Reincorporación laboral', id: 8 },
];
const tipoContrato = [
  { name: 'tipoContrato', id: 0 },
  { name: 'Tipo de contrato', id: 1 },
  { name: 'Periódico', id: 2 },
  { name: 'Preventivo', id: 3 },
  { name: 'Retiro', id: 4 },
  { name: 'Reubicación', id: 5 },
  { name: 'Otros', id: 6 },
  { name: 'Visita', id: 7 },
  { name: 'Reincorporación laboral', id: 8 },
];
const residenciaLugarTrabajo = [
  { name: 'Residencia en  lugar de trabajo', id: 0 },
  { name: 'Si', id: 1 },
  { name: 'No', id: 2 },
];
const altitudLab = [
  { name: 'Altitud de labor', id: 0 },
  { name: '< 2500', id: 1 },
  { name: '2500-3000', id: 2 },
  { name: '3001-3500', id: 3 },
  { name: '3501-4000', id: 4 },
  { name: '4001-4500', id: 5 },
  { name: '> 4501', id: 6 },
];
const superficie = [
  { name: 'Superficie', id: 0 },
  { name: 'Concentradora', id: 1 },
  { name: 'Subsuelo', id: 2 },
  { name: 'Socavón', id: 3 },
];

export default function NewPatient({
  title,
  departamento,
  provincia,
  distrito,
  departamentoDomi,
  provinciaDomi,
  distritoDomi,
  comboCliente,
  comboSede,
  comboContratista,
}) {
  /* console.log('cliente', comboCliente);
  console.log('sede', comboSede);
  console.log('contratista', comboContratista); */
  const dataSchema = yup.object().shape({
    tipoDoc: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    numeroDoc: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    categoria: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    tipoContrato: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    pcd: yup.number('*obl').typeError('*obl').required('*obl').integer('*obl'),
    nombres: yup.string().required('*obl').max(50).min(3),
    apellidoPaterno: yup.string().required('*obl').max(50).min(3),
    apellidoMaterno: yup.string().required('*obl').max(50).min(3),
    sexo: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    gradoInstruc: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    estadoCivil: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    fechNacimiento: yup.date().default(() => new Date()),
    pais: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    departamento: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    provincia: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    distrito: yup.number('*obl').typeError('*obl').integer('*obl'),
    direccion: yup.string().required('*obl').max(50).min(3),
    numeroDir: yup.number('*obl').default(0).typeError('*obl'),
    urbanizacion: yup.string().required('*obl').max(50).min(3),
    departamentoDomi: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    provinciaDomi: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    distritoDomi: yup.number('*obl').typeError('*obl').integer('*obl'),
    telefono: yup.string().required('*obl'),
    celular: yup.string().required('*obl'),
    email: yup.string().required('*obl').email(),
    numHijosVivos: yup.number('*obl').typeError('*obl').required().integer(),
    numHijosFallecidos: yup
      .number('*obl')
      .typeError('*obl')
      .required()
      .integer(),
    numDependientes: yup.number('*obl').typeError('*obl').required().integer(),
    empresa: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    sede: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    contratista: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    protocolo: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    tipoExamen: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),

    fecProgramada: yup.date().default(() => new Date()),
    prioridad: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    seguro: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    cargoPostula: yup.string().required('*obl').max(50).min(3),
    cargoActual: yup.string().required('*obl').max(50).min(3),
    fecIngreso: yup.date().default(() => new Date()),
    area: yup.string().required('*obl').max(50).min(3),
    residenciaLugarTrabajo: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    altitudLab: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    superficie: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    costos: yup.string().required('*obl').max(50).min(3),
    enviarResultCorreo: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .integer('*obl'),
    comparteInfoMedica: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .integer('*obl'),
    entregarResultFisico: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .integer('*obl'),
    usarFirmaEscaneada: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .integer('*obl'),
    comentarios: yup.string(),
  });
  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(dataSchema),
  });

  const {
    mutate,
    isLoading,
    isSuccess,
    isError,
    reset: resetQuery,
  } = useMutatePatient();
  const onSubmit = (data) => {
    const dataPatient = {
      // altitudLab: data.altitudLab,
      apellidoMaterno: data.apellidoMaterno,
      apellidoPaterno: data.apellidoPaterno,
      categoria: data.categoria,
      celular: data.celular,
      comentarios: data.comentarios,
      departamento: data.departamento,
      departamentoDomi: data.departamentoDomi,
      direccion: data.direccion,
      distrito: data.distrito,
      distritoDomi: data.distritoDomi,
      email: data.email,
      empresa: data.empresa,
      estadoCivil: data.estadoCivil,
      fecProgramada: moment(data.fecProgramada).format('YYYY/MM/DD'),
      fechNacimiento: moment(data.fechNacimiento).format('YYYY/MM/DD'),
      gradoInstruc: data.gradoInstruc,
      nombres: data.nombres,
      numDependientes: data.numDependientes,
      numHijosFallecidos: data.numHijosFallecidos,
      numHijosVivos: data.numHijosVivos,
      numeroDir: data.numeroDir,
      numeroDoc: data.numeroDoc,
      pais: data.pais,
      protocolo: data.protocolo,
      provincia: data.provincia,
      provinciaDomi: data.provinciaDomi,
      sede: data.sede,
      sexo: data.sexo,
      telefono: data.telefono,
      tipoDoc: data.tipoDoc,
      tipoExamen: data.tipoExamen,
      urbanizacion: data.urbanizacion,
      tipoContrato: data.tipoContrato,
      pcd: data.pcd,
      contratista: data.contratista,
      prioridad: data.prioridad,
      seguro: data.seguro,
      cargoPostula: data.cargoPostula,
      cargoActual: data.cargoActual,
      fecIngreso: moment(data.fecIngreso).format('YYYY/MM/DD'),
      area: data.area,
      residenciaLugarTrabajo: data.residenciaLugarTrabajo,
      altitudLab: data.altitudLab,
      superficie: data.superficie,
      costos: data.costos,
      enviarResultCorreo: data.enviarResultCorreo,
      comparteInfoMedica: data.comparteInfoMedica,
      entregarResultFisico: data.entregarResultFisico,
      usarFirmaEscaneada: data.usarFirmaEscaneada,
    };
    mutate(dataPatient);
    // console.log(data, 'hh', dataPatient);
    reset();
  };

  return (
    <div className="flex flex-col w-full">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-full pt-2 bg-blanc1 gap-2 lg:flex-row"
      >
        <Wraper nofullScreen>
          <div className="flex flex-col w-full px-2 py-4 items-center gap-8 lg:items-start">
            <H1 text={title} />
            <Section title="Datos">
              <InputContainers>
                <div>
                  <Select
                    itemArray={option}
                    // modal
                    setValue={setValue}
                    idSelect="tipoDoc"
                    isSelect
                    registerValue={register('tipoDoc')}
                  />
                  {errors.tipoDoc && (
                    <ErrorMessage xs message={errors.tipoDoc.message} />
                  )}
                </div>
                <div className="w-full md:w-fit">
                  <Input
                    textLabel=""
                    type="text"
                    placeholder="Número"
                    registerValue={register('numeroDoc', { required: true })}
                  />
                  {errors.numeroDoc && (
                    <ErrorMessage xs message={errors.numeroDoc.message} />
                  )}
                </div>
                <div className="w-full bg-blanc1 rounded-lg mt-1 p-1.5 md:w-fit ">
                  COD:ABC-123
                </div>
                <div className="md:mt-2 lg:mt-0">
                  <Select
                    itemArray={categoria}
                    modal
                    setValue={setValue}
                    registerValue={register('categoria', { required: true })}
                    idSelect="categoria"
                    isSelect
                  />
                  {errors.categoria && (
                    <ErrorMessage xs message={errors.categoria.message} />
                  )}
                </div>
                <div className="md:mt-2 lg:mt-0">
                  <Select
                    itemArray={tipoContrato}
                    modal
                    setValue={setValue}
                    registerValue={register('tipoContrato', { required: true })}
                    idSelect="tipoContrato"
                    isSelect
                  />
                  {errors.tipoContrato && (
                    <ErrorMessage xs message={errors.tipoContrato.message} />
                  )}
                </div>
                <div className="w-full md:w-fit">
                  <div className="w-full flex gap-5 md:w-fit">
                    <h2 className="flex gap-2 justify-center items-center  font-bold text-blue-800">
                      PCD
                    </h2>
                    <Radio
                      name="pcd"
                      htmlFor="PCD"
                      textLabel="Si"
                      value={0}
                      registerValue={register('pcd', { required: true })}
                    />
                    <Radio
                      name="pcd"
                      htmlFor="PCD"
                      textLabel="No"
                      value={1}
                      registerValue={register('pcd', { required: true })}
                    />
                  </div>

                  {errors.pcd && (
                    <ErrorMessage xs message={errors.pcd.message} />
                  )}
                </div>
              </InputContainers>
              <InputContainers>
                <div className="w-full md:w-fit">
                  <Input
                    textLabel=""
                    type="text"
                    placeholder="Nombres"
                    registerValue={register('nombres', { required: true })}
                  />
                  {errors.nombres && (
                    <ErrorMessage xs message={errors.nombres.message} />
                  )}
                </div>
                <div className="w-full md:w-fit">
                  <Input
                    textLabel=""
                    type="text"
                    placeholder="Apellido Paterno"
                    registerValue={register('apellidoPaterno', {
                      required: true,
                    })}
                  />
                  {errors.apellidoPaterno && (
                    <ErrorMessage xs message={errors.apellidoPaterno.message} />
                  )}
                </div>
                <div className="w-full md:w-fit">
                  <Input
                    textLabel=""
                    type="text"
                    placeholder="Apellido Materno"
                    registerValue={register('apellidoMaterno', {
                      required: true,
                    })}
                  />
                  {errors.apellidoMaterno && (
                    <ErrorMessage xs message={errors.apellidoMaterno.message} />
                  )}
                </div>
              </InputContainers>
              <InputContainers>
                <div>
                  <Select
                    itemArray={sexo}
                    modal
                    setValue={setValue}
                    registerValue={register('sexo', { required: true })}
                    idSelect="sexo"
                    isSelect
                  />
                  {errors.sexo && (
                    <ErrorMessage xs message={errors.sexo.message} />
                  )}
                </div>
                <div>
                  <Select
                    itemArray={gradoInstruc}
                    modal
                    setValue={setValue}
                    registerValue={register('gradoInstruc', { required: true })}
                    idSelect="gradoInstruc"
                    isSelect
                  />
                  {errors.gradoInstruc && (
                    <ErrorMessage xs message={errors.gradoInstruc.message} />
                  )}
                </div>
                <div>
                  <Select
                    itemArray={estadoCivil}
                    modal
                    setValue={setValue}
                    registerValue={register('estadoCivil', { required: true })}
                    idSelect="estadoCivil"
                    isSelect
                  />
                  {errors.estadoCivil && (
                    <ErrorMessage xs message={errors.estadoCivil.message} />
                  )}
                </div>
              </InputContainers>
            </Section>
            <Line />
            <Section title="Origen">
              <InputContainers>
                <div className="w-full md:w-fit">
                  <Controller
                    control={control}
                    name="fechNacimiento"
                    render={({ field }) => (
                      // console.log(field.value);
                      // const fecha = moment(field.value).format('YYYY/MM/DD');
                      // console.log(fecha, 'gg');

                      <InputCalendar
                        placeholder="Fecha de nacimiento"
                        onChange={(date) =>
                          field.onChange(moment(date).format('YYYY/MM/DD'))
                        }
                        selected={moment(field.value).format('YYYY/MM/DD')}
                      />
                    )}
                  />
                  {errors.fechNacimiento && (
                    <ErrorMessage xs message={errors.fechNacimiento.message} />
                  )}
                </div>
                <div>
                  <Select
                    itemArray={pais}
                    modal
                    setValue={setValue}
                    registerValue={register('pais', { required: true })}
                    idSelect="pais"
                    isSelect
                  />
                  {errors.pais && (
                    <ErrorMessage xs message={errors.pais.message} />
                  )}
                </div>
                <div className="flex flex-col md:flex-row mt-5 gap-5">
                  <div>
                    <Select
                      itemArray={departamento}
                      modal
                      setValue={setValue}
                      registerValue={register('departamento', {
                        required: true,
                      })}
                      idSelect="departamento"
                      selectBd
                      defaultValue="Departamento"
                    />
                    {departamento.length > 0
                      ? errors.departamento && (
                          <ErrorMessage
                            xs
                            message={errors.departamento.message}
                          />
                        )
                      : null}
                  </div>
                  <div className="">
                    <Select
                      itemArray={provincia}
                      modal
                      setValue={setValue}
                      registerValue={register('provincia', { required: true })}
                      idSelect="provincia"
                      selectBd
                      defaultValue="Provincia"
                    />

                    {provincia.length > 0
                      ? errors.provincia && (
                          <ErrorMessage xs message={errors.provincia.message} />
                        )
                      : null}
                  </div>
                  <div>
                    <Select
                      itemArray={distrito}
                      modal
                      setValue={setValue}
                      registerValue={register('distrito', { required: true })}
                      idSelect="distrito"
                      selectBd
                      defaultValue="Distrito"
                    />

                    {distrito.length > 0
                      ? errors.distrito && (
                          <ErrorMessage xs message={errors.distrito.message} />
                        )
                      : null}
                  </div>
                </div>
              </InputContainers>
            </Section>
            <Line />
            <Section title="Domicilio">
              <InputContainers>
                <div className="w-full md:w-fit">
                  <Input
                    textLabel=""
                    type="text"
                    placeholder="Dirección"
                    registerValue={register('direccion', { required: true })}
                  />
                  {errors.direccion && (
                    <ErrorMessage xs message={errors.direccion.message} />
                  )}
                </div>
                <div className="w-full md:w-20 lg:w-24">
                  <Input
                    textLabel=""
                    type="number"
                    placeholder="Número"
                    registerValue={register('numeroDir', { required: false })}
                  />
                  {errors.numeroDir && (
                    <ErrorMessage xs message={errors.numeroDir.message} />
                  )}
                </div>
                <div className="w-full md:w-fit">
                  <Input
                    textLabel=""
                    type="text"
                    placeholder="Urbanización"
                    registerValue={register('urbanizacion', { required: true })}
                  />
                  {errors.urbanizacion && (
                    <ErrorMessage xs message={errors.urbanizacion.message} />
                  )}
                </div>
              </InputContainers>
              <InputContainers>
                <div>
                  <Select
                    itemArray={departamentoDomi}
                    modal
                    setValue={setValue}
                    registerValue={register('departamentoDomi', {
                      required: true,
                    })}
                    idSelect="departamentoDomi"
                    selectBd
                    defaultValue="Departamento"
                  />
                  {departamentoDomi.length > 0
                    ? errors.departamentoDomi && (
                        <ErrorMessage
                          xs
                          message={errors.departamentoDomi.message}
                        />
                      )
                    : null}
                </div>
                <div className="">
                  <Select
                    itemArray={provinciaDomi}
                    modal
                    setValue={setValue}
                    registerValue={register('provinciaDomi', {
                      required: true,
                    })}
                    idSelect="provinciaDomi"
                    selectBd
                    defaultValue="Provincia"
                  />

                  {provinciaDomi.length > 0
                    ? errors.provinciaDomi && (
                        <ErrorMessage
                          xs
                          message={errors.provinciaDomi.message}
                        />
                      )
                    : null}
                </div>
                <div>
                  <Select
                    itemArray={distritoDomi}
                    modal
                    setValue={setValue}
                    registerValue={register('distritoDomi', { required: true })}
                    idSelect="distritoDomi"
                    selectBd
                    defaultValue="Distrito"
                  />

                  {distritoDomi.length > 0
                    ? errors.distritoDomi && (
                        <ErrorMessage
                          xs
                          message={errors.distritoDomi.message}
                        />
                      )
                    : null}
                </div>
                {/* <div>
                  <Select
                    itemArray={departamento}
                    modal
                    setValue={setValue}
                    registerValue={register('departamentoDomi', {
                      required: true,
                    })}
                    idSelect="departamentoDomi"
                    isSelect
                  />
                  {errors.departamentoDomi && (
                    <ErrorMessage
                      xs
                      message={errors.departamentoDomi.message}
                    />
                  )}
                </div>
                <div className="">
                  <Select
                    itemArray={provincia}
                    modal
                    setValue={setValue}
                    registerValue={register('provinciaDomi', {
                      required: true,
                    })}
                    idSelect="provinciaDomi"
                    isSelect
                  />
                  {errors.provinciaDomi && (
                    <ErrorMessage xs message={errors.provinciaDomi.message} />
                  )}
                </div>
                <div>
                  <Select
                    itemArray={distrito}
                    modal
                    setValue={setValue}
                    registerValue={register('distritoDomi', { required: true })}
                    idSelect="distritoDomi"
                    isSelect
                  />
                  {errors.distritoDomi && (
                    <ErrorMessage xs message={errors.distritoDomi.message} />
                  )}
                </div> */}
              </InputContainers>
            </Section>
            <Line />
            <Section title="Contacto">
              <InputContainers>
                <div className="w-full md:w-fit">
                  <Input
                    textLabel=""
                    type="tel"
                    placeholder="Teléfono"
                    name="telefono"
                    registerValue={register('telefono', { required: true })}
                  />
                  {errors.telefono && (
                    <ErrorMessage xs message={errors.telefono.message} />
                  )}
                </div>
                <div className="w-full md:w-fit ">
                  <Input
                    textLabel=""
                    type="tel"
                    placeholder="Celular"
                    name="telefono"
                    registerValue={register('celular', { required: true })}
                  />
                  {errors.celular && (
                    <ErrorMessage xs message={errors.celular.message} />
                  )}
                </div>
                <div className="w-full md:w-fit">
                  <Input
                    textLabel=""
                    type="email"
                    placeholder="Correo"
                    registerValue={register('email', { required: true })}
                  />
                  {errors.email && (
                    <ErrorMessage xs message={errors.email.message} />
                  )}
                </div>
              </InputContainers>
            </Section>
            <Line />
            <Section title="Familia">
              <InputContainers>
                <div className="w-full flex flex-col md:gap-2 md:flex-row md:w-44 md:items-end">
                  <h6 className="text-negro font-poppins font-semibold text-sm">
                    Número de Hijos vivos
                  </h6>
                  <div className="w-1/2 p-1">
                    <Input
                      textLabel=""
                      type="number"
                      placeholder=""
                      registerValue={register('numHijosVivos', {
                        required: true,
                      })}
                    />
                    {errors.numHijosVivos && (
                      <ErrorMessage xs message={errors.numHijosVivos.message} />
                    )}
                  </div>
                </div>
                <div className="w-full flex flex-col md:gap-2 md:flex-row md:w-44 md:items-end">
                  <h6 className="text-negro font-poppins font-semibold text-sm">
                    Número de Hijos fallecidos
                  </h6>
                  <div className="w-1/3 p-1">
                    <Input
                      textLabel=""
                      type="number"
                      placeholder=""
                      registerValue={register('numHijosFallecidos', {
                        required: true,
                      })}
                    />
                    {errors.numHijosFallecidos && (
                      <ErrorMessage
                        xs
                        message={errors.numHijosFallecidos.message}
                      />
                    )}
                  </div>
                </div>
                <div className="w-full flex flex-col md:gap-2 md:flex-row md:w-44 md:items-end">
                  <h6 className="text-negro font-poppins font-semibold text-sm">
                    Número de dependientes
                  </h6>
                  <div className="w-1/3 p-1">
                    <Input
                      textLabel=""
                      type="number"
                      placeholder=""
                      registerValue={register('numDependientes', {
                        required: true,
                      })}
                    />
                    {errors.numDependientes && (
                      <ErrorMessage
                        xs
                        message={errors.numDependientes.message}
                      />
                    )}
                  </div>
                </div>
              </InputContainers>
            </Section>
            <Line />
            <Section title="Empresa">
              <InputContainers>
                <div>
                  <Select
                    itemArray={comboCliente}
                    modal
                    setValue={setValue}
                    registerValue={register('empresa', { required: true })}
                    idSelect="empresa"
                    selectBd
                    defaultValue="Empresa"
                  />
                  {errors.empresa && (
                    <ErrorMessage xs message={errors.empresa.message} />
                  )}
                </div>
                <div>
                  <Select
                    itemArray={comboSede}
                    modal
                    setValue={setValue}
                    registerValue={register('sede', { required: true })}
                    idSelect="sede"
                    selectBd
                    defaultValue="Sede"
                  />
                  {errors.sede && (
                    <ErrorMessage xs message={errors.sede.message} />
                  )}
                </div>
                <div>
                  <Select
                    itemArray={comboContratista}
                    modal
                    setValue={setValue}
                    registerValue={register('contratista', { required: true })}
                    idSelect="contratista"
                    selectBd
                    defaultValue="Contratista"
                  />
                  {errors.contratista && (
                    <ErrorMessage xs message={errors.contratista.message} />
                  )}
                </div>
                <div>
                  <Select
                    itemArray={protocolo}
                    modal
                    setValue={setValue}
                    registerValue={register('protocolo', { required: true })}
                    idSelect="protocolo"
                    isSelect
                  />
                  {errors.protocolo && (
                    <ErrorMessage xs message={errors.protocolo.message} />
                  )}
                </div>
                <div>
                  <Select
                    itemArray={tipoExamen}
                    modal
                    setValue={setValue}
                    registerValue={register('tipoExamen', { required: true })}
                    idSelect="tipoExamen"
                    isSelect
                  />
                  {errors.tipoExamen && (
                    <ErrorMessage xs message={errors.tipoExamen.message} />
                  )}
                </div>
              </InputContainers>
              <InputContainers>
                <div className="w-full md:w-fit mt-2 md:mt-0">
                  <Controller
                    control={control}
                    name="fecProgramada"
                    render={({ field }) => (
                      <InputCalendar
                        placeholder="Fecha Programada"
                        onChange={(date) =>
                          field.onChange(moment(date).format('YYYY/MM/DD'))
                        }
                        selected={moment(field.value).format('YYYY/MM/DD')}
                      />
                    )}
                  />
                  {errors.fecProgramada && (
                    <ErrorMessage xs message={errors.fecProgramada.message} />
                  )}
                </div>
                <div>
                  <Select
                    itemArray={prioridad}
                    modal
                    setValue={setValue}
                    registerValue={register('prioridad', { required: true })}
                    idSelect="prioridad"
                    isSelect
                  />
                  {errors.prioridad && (
                    <ErrorMessage xs message={errors.prioridad.message} />
                  )}
                </div>
                <div>
                  <Select
                    itemArray={seguro}
                    modal
                    setValue={setValue}
                    registerValue={register('seguro', { required: true })}
                    idSelect="seguro"
                    isSelect
                  />
                  {errors.seguro && (
                    <ErrorMessage xs message={errors.seguro.message} />
                  )}
                </div>
              </InputContainers>
              <InputContainers>
                <div className="w-full md:w-fit">
                  <Input
                    textLabel=""
                    type="text"
                    placeholder="Cargo al que Postula"
                    registerValue={register('cargoPostula', { required: true })}
                  />
                  {errors.cargoPostula && (
                    <ErrorMessage xs message={errors.cargoPostula.message} />
                  )}
                </div>
                <div className="w-full md:w-fit">
                  <Input
                    textLabel=""
                    type="text"
                    placeholder="Cargo Actual"
                    registerValue={register('cargoActual', { required: true })}
                  />
                  {errors.cargoActual && (
                    <ErrorMessage xs message={errors.cargoActual.message} />
                  )}
                </div>
                <div className="w-full md:w-fit mt-2 md:mt-0">
                  <Controller
                    control={control}
                    name="fecIngreso"
                    render={({ field }) => (
                      <InputCalendar
                        placeholder="Fecha Ingreso"
                        onChange={(date) =>
                          field.onChange(moment(date).format('YYYY/MM/DD'))
                        }
                        selected={moment(field.value).format('YYYY/MM/DD')}
                      />
                    )}
                  />
                  {errors.fecIngreso && (
                    <ErrorMessage xs message={errors.fecIngreso.message} />
                  )}
                </div>
                <div className="w-full md:w-fit">
                  <Input
                    textLabel=""
                    type="text"
                    placeholder="Área"
                    registerValue={register('area', { required: true })}
                  />
                  {errors.area && (
                    <ErrorMessage xs message={errors.area.message} />
                  )}
                </div>
              </InputContainers>
              <InputContainers>
                <div>
                  <Select
                    itemArray={residenciaLugarTrabajo}
                    modal
                    setValue={setValue}
                    registerValue={register('residenciaLugarTrabajo', {
                      required: true,
                    })}
                    idSelect="residenciaLugarTrabajo"
                    isSelect
                  />
                  {errors.residenciaLugarTrabajo && (
                    <ErrorMessage
                      xs
                      message={errors.residenciaLugarTrabajo.message}
                    />
                  )}
                </div>
                <div>
                  <Select
                    itemArray={altitudLab}
                    modal
                    setValue={setValue}
                    registerValue={register('altitudLab', { required: true })}
                    idSelect="altitudLab"
                    isSelect
                  />
                  {errors.altitudLab && (
                    <ErrorMessage xs message={errors.altitudLab.message} />
                  )}
                </div>
                <div>
                  <Select
                    itemArray={superficie}
                    modal
                    setValue={setValue}
                    registerValue={register('superficie', { required: true })}
                    idSelect="superficie"
                    isSelect
                  />
                  {errors.superficie && (
                    <ErrorMessage xs message={errors.superficie.message} />
                  )}
                </div>
                <div className="w-full md:w-fit">
                  <Input
                    textLabel=""
                    type="text"
                    placeholder="Costos"
                    registerValue={register('costos', { required: true })}
                  />
                  {errors.costos && (
                    <ErrorMessage xs message={errors.costos.message} />
                  )}
                </div>
              </InputContainers>
              <InputContainers>
                <div className="w-full md:w-fit">
                  <div className="w-full flex gap-5 md:w-fit">
                    <h2 className="flex gap-2 justify-center items-center text-negro">
                      Enviar resultados por correo
                    </h2>
                    <Radio
                      name="enviarResultCorreo"
                      htmlFor="enviarResultCorreo"
                      textLabel="Si"
                      value={0}
                      registerValue={register('enviarResultCorreo', {
                        required: true,
                      })}
                    />
                    <Radio
                      name="enviarResultCorreo"
                      htmlFor="enviarResultCorreo"
                      textLabel="No"
                      value={1}
                      registerValue={register('enviarResultCorreo', {
                        required: true,
                      })}
                    />
                  </div>

                  {errors.enviarResultCorreo && (
                    <ErrorMessage
                      xs
                      message={errors.enviarResultCorreo.message}
                    />
                  )}
                </div>
                <div className="w-full md:w-fit">
                  <div className="w-full flex gap-5 md:w-fit">
                    <h2 className="flex gap-2 justify-center items-center text-negro">
                      Comparte información médica
                    </h2>
                    <Radio
                      name="comparteInfoMedica"
                      htmlFor="comparteInfoMedica"
                      textLabel="Si"
                      value={0}
                      registerValue={register('comparteInfoMedica', {
                        required: true,
                      })}
                    />
                    <Radio
                      name="comparteInfoMedica"
                      htmlFor="comparteInfoMedica"
                      textLabel="No"
                      value={1}
                      registerValue={register('comparteInfoMedica', {
                        required: true,
                      })}
                    />
                  </div>

                  {errors.comparteInfoMedica && (
                    <ErrorMessage
                      xs
                      message={errors.comparteInfoMedica.message}
                    />
                  )}
                </div>
                <div className="w-full md:w-fit">
                  <div className="w-full flex gap-5 md:w-fit">
                    <h2 className="flex gap-2 justify-center items-center text-negro">
                      Entrega resultados en físico
                    </h2>
                    <Radio
                      name="entregarResultFisico"
                      htmlFor="entregarResultFisico"
                      textLabel="Si"
                      value={0}
                      registerValue={register('entregarResultFisico', {
                        required: true,
                      })}
                    />
                    <Radio
                      name="entregarResultFisico"
                      htmlFor="entregarResultFisico"
                      textLabel="No"
                      value={1}
                      registerValue={register('entregarResultFisico', {
                        required: true,
                      })}
                    />
                  </div>

                  {errors.entregarResultFisico && (
                    <ErrorMessage
                      xs
                      message={errors.entregarResultFisico.message}
                    />
                  )}
                </div>
                <div className="w-full md:w-fit">
                  <div className="w-full flex gap-5 md:w-fit">
                    <h2 className="flex gap-2 justify-center items-center text-negro">
                      Usar Firma escaneada en documentos
                    </h2>
                    <Radio
                      name="usarFirmaEscaneada"
                      htmlFor="usarFirmaEscaneada"
                      textLabel="Si"
                      value={0}
                      registerValue={register('usarFirmaEscaneada', {
                        required: true,
                      })}
                    />
                    <Radio
                      name="usarFirmaEscaneada"
                      htmlFor="usarFirmaEscaneada"
                      textLabel="No"
                      value={1}
                      registerValue={register('usarFirmaEscaneada', {
                        required: true,
                      })}
                    />
                  </div>

                  {errors.usarFirmaEscaneada && (
                    <ErrorMessage
                      xs
                      message={errors.usarFirmaEscaneada.message}
                    />
                  )}
                </div>
              </InputContainers>
            </Section>
          </div>
        </Wraper>
        <Aside
          errors={errors}
          registerValue={register('comentarios', { required: true })}
        />
      </form>
      {isLoading ? (
        <Notification
          loading
          title="Uploading data to the server"
          disallowClose
        >
          Espere hasta que se carguen los datos, no puede cerrar esta
          notificación aún
        </Notification>
      ) : (
        <>
          {isError ? (
            <Notification
              icon={<XIconIconMobile size={18} />}
              color="red"
              title="Error"
              onClose={resetQuery}
            >
              Ups! Hubo un error al guardar los datos
            </Notification>
          ) : null}

          {isSuccess ? (
            <Notification
              icon={<CheckCircleIcon size={18} />}
              color="teal"
              title="Correcto"
              onClose={resetQuery}
            >
              Se guardarón los datos correctamente
            </Notification>
          ) : null}
        </>
      )}
    </div>
  );
}
