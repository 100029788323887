/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Notification } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ErrorMessage from '../../../../_desingModels/layout/components/form/Message/Message';
import CheckCircleIcon from '../../../../_desingModels/iconComponets/CheckCircle';
import XIcon from '../../../../_desingModels/iconComponets/XIcon';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
// import Line from '../../../../_desingModels/layout/components/Line';
import H2 from '../../../../_desingModels/textComponents/H2';
import MultiSelectComponent from '../../../../_desingModels/layout/components/select/MultiSelect';
import { useAddRoleUser } from '../../../../hooks/users';

function ModalFormRole({ setOpened, userId, roleCombo = [], roleUser = [] }) {
  // console.log('roleComboUser', roleUser);
  // console.log('roleCombo', roleCombo);
  // put in a array the roles that the user does not have
  const newRoles = roleCombo.filter(
    (role) => !roleUser.some((item) => item.id === role.value)
  );
  // console.log('newRoles', newRoles);

  const dataSchema = yup.object().shape({
    roles: yup.array().min(1).required('*obl'),
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(dataSchema),
  });
  const {
    mutate,
    isLoading,
    isSuccess,
    isError,
    reset: resetQuery,
  } = useAddRoleUser();

  const onSubmit = (data) => {
    const newData = {
      id_usuario: userId,
      arrayRoles: data.roles,
    };
    console.log('roles', newData);
    mutate(newData);
    reset();
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pb-5 w-full flex flex-col gap-5 mt-4 justify-center items-center"
      >
        <div className="w-full lg:px-10">
          <H2 text="Agregar Módulos" blue />
          {newRoles.length > 0 && (
            <Controller
              control={control}
              name="roles"
              render={({ field }) => (
                <MultiSelectComponent
                  placeholder="Buscar Módulo"
                  clearable
                  data={newRoles}
                  onChange={(value) => field.onChange(value)}
                  selected={field.value}
                />
              )}
            />
          )}
          {newRoles.length > 0 ? (
            errors.roles && <ErrorMessage xs message={errors.roles.message} />
          ) : (
            <ErrorMessage
              xs
              message="No hay módulos disponibles para este rol, contactar con soporte"
            />
          )}
        </div>

        <div className="pt-8 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
          <PrimaryButton type="input" text="Guardar" blue />
          <PrimaryButton
            text="Cancelar"
            modal
            handleClick={() => setOpened(false)}
          />
        </div>
      </form>
      {isLoading ? (
        <Notification
          loading
          title="Uploading data to the server"
          disallowClose
        >
          Espere hasta que se carguen los datos, no puede cerrar esta
          notificación aún
        </Notification>
      ) : (
        <>
          {isError ? (
            <Notification
              icon={<XIcon size={18} />}
              color="red"
              title="Error"
              onClose={resetQuery}
            >
              Ups! Hubo un error al guardar los datos
            </Notification>
          ) : null}

          {isSuccess ? (
            <Notification
              icon={<CheckCircleIcon size={18} />}
              color="teal"
              title="Correcto"
              onClose={resetQuery}
            >
              Se guardarón los datos correctamente
            </Notification>
          ) : null}
        </>
      )}
    </>
  );
}

ModalFormRole.propTypes = {};

export default ModalFormRole;
