/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import { Text, Image, SimpleGrid, Notification } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import PrimaryButton from '../Buttons/PrimaryButton';
import { useUploadImage } from '../../../../hooks/users';
import { useUploadImagePatient } from '../../../../hooks/patients';
import XIcon from '../../../iconComponets/XIcon';
import CheckCircleIcon from '../../../iconComponets/CheckCircle';

function DropzoneImage({ userId, codPatient }) {
  // console.log(userId, 'userId');
  const [files, setFiles] = useState([]);
  const {
    mutate,
    isLoading,
    isSuccess,
    isError,
    reset: resetQuery,
  } = useUploadImage();
  const uploadImagePatient = useUploadImagePatient();

  const handleClickImage = (image) => {
    const formData = new FormData();
    if (codPatient) {
      formData.append('fileName', image[0]);
      formData.append('tipoDocumento', codPatient);
      formData.append('idPasiente', userId);
      uploadImagePatient.mutate(formData);
    } else {
      formData.append('fileName', image[0]);
      formData.append('tipoDocumento', 1);
      formData.append('idUsuario', userId);
      mutate(formData);
      // console.log('usuario');
    }
  };

  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Image
        key={index}
        src={imageUrl}
        imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
      />
    );
  });

  return (
    <>
      <Dropzone multiple={false} accept={IMAGE_MIME_TYPE} onDrop={setFiles}>
        <Text align="center">Coloque la imagen aquí</Text>
      </Dropzone>
      <SimpleGrid
        cols={3}
        breakpoints={[{ maxWidth: 'md', cols: 1 }]}
        mt={previews.length > 0 ? 'xl' : 0}
      >
        <div />
        {previews}
        <div />
      </SimpleGrid>
      <div className="flex flex-col justify-center items-center mt-6">
        <PrimaryButton
          type="button"
          text="Guardar"
          blue
          handleClick={() => handleClickImage(files)}
        />
      </div>
      {isLoading || uploadImagePatient.isLoading ? (
        <Notification
          loading
          title="Uploading data to the server"
          disallowClose
        >
          Espere hasta que se carguen los datos, no puede cerrar esta
          notificación aún
        </Notification>
      ) : (
        <>
          {isError || uploadImagePatient.isError ? (
            <Notification
              icon={<XIcon size={18} />}
              color="red"
              title="Error"
              onClose={resetQuery}
            >
              Ups! Hubo un error al guardar los datos
            </Notification>
          ) : null}

          {isSuccess || uploadImagePatient.isSuccess ? (
            <Notification
              icon={<CheckCircleIcon size={18} />}
              color="teal"
              title="Correcto"
              onClose={resetQuery}
            >
              Se guardarón los datos correctamente
            </Notification>
          ) : null}
        </>
      )}
    </>
  );
}

export default DropzoneImage;
