import React from 'react';
import { useNavigate } from 'react-router-dom';
import ThirdButton from '../../../../../_desingModels/layout/components/Buttons/ThirdButton';
import H2 from '../../../../../_desingModels/textComponents/H2';
import TableQuotes from '../componets/TableQuotes';
// import PropTypes from 'prop-types'
const data = [
  {
    name: 'MARSA SAC',
    number: '444444444',
    primerarevison: 'Primera Revsión',
    igv: '100 Soles+ IGV',
  },
  {
    name: 'Empresa 1',
    number: '444444444',
    primerarevison: 'Primera Revsión',
    igv: '100 Soles+ IGV',
  },
  {
    name: 'Empresa 1',
    number: '444444444',
    primerarevison: 'Primera Revsión',
    igv: '100 Soles+ IGV',
  },
  {
    name: 'Empresa 1',
    number: '444444444',
    primerarevison: 'Primera Revsión',
    igv: '100 Soles+ IGV',
  },
  {
    name: 'Empresa 1',
    number: '444444444',
    primerarevison: 'Primera Revsión',
    igv: '100 Soles+ IGV',
  },
  {
    name: 'Empresa 1',
    number: '444444444',
    primerarevison: 'Primera Revsión',
    igv: '100 Soles+ IGV',
  },
  {
    name: 'Empresa 1',
    number: '444444444',
    primerarevison: 'Primera Revsión',
    igv: '100 Soles+ IGV',
  },
  {
    name: 'Empresa 1',
    number: '444444444',
    primerarevison: 'Primera Revsión',
    igv: '100 Soles+ IGV',
  },
];
function Quotes() {
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    navigate('../cotizaciones');
  };
  return (
    <section className="flex flex-col bg-white rounded-xl w-full p-4 items-center justify-center gap-5 lg:w-1/2">
      <H2 text="Cotizaciones" />
      <TableQuotes data={data} />
      <ThirdButton text="Ver más" handleClick={handleClick} />
    </section>
  );
}

Quotes.propTypes = {};

export default Quotes;
