/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import classNames from '../../../../utils/classNames';

function Input({
  textLabel,
  type,
  placeholder,
  defaultValue,
  registerValue,
  modal,
  inputUseState,
  value,
  handleChange,
  disabled,
}) {
  if (inputUseState) {
    return (
      <label className={classNames('flex flex-col  font-bold text-purpleT')}>
        {textLabel}
        <input
          className={classNames(
            'border-0 rounded-lg p-1.5  mt-1 text-black/60 ',
            modal ? 'bg-white border border-purpleT' : 'bg-blanc1'
          )}
          // id={registerValue.name}
          type={type}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          disabled={disabled}
        />
      </label>
    );
  }
  return (
    <label
      htmlFor={registerValue.name}
      className={classNames('flex flex-col  font-bold text-purpleT')}
    >
      {textLabel}
      <input
        className={classNames(
          'border-0 rounded-lg p-1.5  mt-1 text-black/60 ',
          modal ? 'bg-white border border-purpleT' : 'bg-blanc1'
        )}
        id={registerValue.name}
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...registerValue}
        onChange={handleChange}
        disabled={disabled}
      />
    </label>
  );
}

Input.propTypes = {};

export default Input;
