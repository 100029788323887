/* eslint-disable dot-notation */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import H1 from '../../../../_desingModels/textComponents/H1';
import H2 from '../../../../_desingModels/textComponents/H2';
import ModalWithBtn from '../../../layaouts/modal/ModalWithBtn';
import Wraper from '../../../layaouts/wrapper/Wrapper';
import ModalFirma from '../../management/users/ModalFirma';
import sign from '../../../../_desingModels/assets/media/sign.jpg';
import finger from '../../../../_desingModels/assets/media/finger.png';
import P from '../../../../_desingModels/textComponents/P';
import { useExamsTemplate } from '../../../../hooks/exams';
import DinamicFormExams from '../dinamicForm/DinamicFormExams';
import { showExam, showRespuesta } from '../../../../api/requests';
import { useStateData } from '../../../../context/dataContext';

const codPatient = {
  photoPatient: 3,
  signPatient: 4,
  fingerprint: 5,
};
const examenes = ['Examen 1', 'Examen 2', 'Examen 3', 'Examen 4', 'Examen 5'];
function DetailPatient({ patient, imagePatient }) {
  // console.log('patient', imagePatient);
  const [patientImage, setPatientImage] = useState(false);
  const [patientSign, setPatientSign] = useState(false);
  const [patientFinger, setPatientFinger] = useState(false);
  const [jsonTemplate, setJsonTemplate] = useState();
  const [jsonResponse, setJsonResponse] = useState(false);
  // const [fillData, setFillData] = useState(false);
  const [idExam, setIdExam] = useState();
  const { dataExam } = useStateData();
  const image = `https://api.euro-salud.com/${imagePatient[5]?.ruta}`;
  const signUrl = `https://api.euro-salud.com/${imagePatient[6]?.ruta}`;
  const fingerUrl = `https://api.euro-salud.com/${imagePatient[7]?.ruta}`;
  const examstemplate = useExamsTemplate();
  const handleClickOpenModalImage = () => {
    setPatientImage(true);
  };
  const handleClickOpenModalSign = () => {
    setPatientSign(true);
  };
  const handleClickOpenModalFinger = () => {
    setPatientFinger(true);
  };
  const handleClickExam = async (exam) => {
    setIdExam(exam);
    const jsonExam = await showExam(exam);
    setJsonTemplate(JSON.parse(jsonExam?.[0]?.[0]?.json_template));
    console.log(JSON.parse(jsonExam?.[0]?.[0]?.json_template));
  };
  const handleClickRespuesta = async (exam) => {
    setIdExam(exam);
    const jsonRespuesta = await showRespuesta(exam, patient.id);
    // setJsonTemplate(JSON.parse(jsonRespuesta?.[0]?.[0]?.json_template));
    // console.log(jsonRespuesta);
    console.log(JSON.parse(jsonRespuesta?.[0]?.[0]?.respuestas));
    console.log(JSON.parse(jsonRespuesta?.[0]?.[0]?.template));
    setJsonTemplate(JSON.parse(jsonRespuesta?.[0]?.[0]?.template));
    setJsonResponse(JSON.parse(jsonRespuesta?.[0]?.[0]?.respuestas));
  };
  /* const addDataExam = () => {
    if (dataExam.length > 0) {
      setFillData(true);
      console.log('hay datos');
      console.log(dataExam);
      console.log(patient);
    } else {
      setFillData(false);
      console.log('no hay datos');
    }
  }; */

  return (
    <Wraper>
      <div className="w-full flex p-7 flex-col md:flex-row gap-5">
        <section className="w-1/5 flex flex-col">
          <H1 text="Examenes" />
          <div className="w-full flex flex-col gap-2 my-4">
            {examstemplate?.data?.data?.examenes.map((item) => (
              <div
                key={item.value}
                role="button"
                tabIndex={0}
                className="w-full border border-purpleTH rounded-md p-3 cursor-pointer"
                onClick={() => handleClickRespuesta(item.value)}
              >
                <P text={item.label} />
              </div>
            ))}
          </div>
          <div className="flex flex-wrap gap-2 w-full items-center justify-around">
            <article className="flex flex-col gap-1 items-center w-fit">
              <div className="border border-purpleTH p-0 rounded-md w-20 h-24">
                <img
                  className="rounded-md h-full object-cover object-center"
                  src={
                    imagePatient[5]?.ruta
                      ? image
                      : 'https://i.pinimg.com/564x/9b/47/a0/9b47a023caf29f113237d61170f34ad9.jpg'
                  }
                  alt="perfil"
                />
              </div>
              <P text="Paciente" />
              <div>
                <PrimaryButton
                  text="Agregar"
                  handleClick={handleClickOpenModalImage}
                  blue
                />
                <ModalWithBtn
                  opened={patientImage}
                  setOpened={setPatientImage}
                  title="Agregar foto paciente"
                >
                  <ModalFirma
                    userData={patient}
                    setOpened={setPatientImage}
                    patient={codPatient.photoPatient}
                  />
                </ModalWithBtn>
              </div>
            </article>
            <article className="flex flex-col gap-1 items-center w-fit">
              <div className="border border-purpleTH p-0 rounded-md w-20 h-24">
                <img
                  className="rounded-md h-full object-cover object-center"
                  src={imagePatient[6]?.ruta ? signUrl : sign}
                  alt="sign"
                />
              </div>
              <P text="Firma" />
              <div>
                <PrimaryButton
                  text="Agregar"
                  handleClick={handleClickOpenModalSign}
                  blue
                />
                <ModalWithBtn
                  opened={patientSign}
                  setOpened={setPatientSign}
                  title="Agregar Firma"
                >
                  <ModalFirma
                    userData={patient}
                    setOpened={setPatientSign}
                    patient={codPatient.signPatient}
                  />
                </ModalWithBtn>
              </div>
            </article>
            <article className="flex flex-col gap-1 items-center w-fit">
              <div className="border border-purpleTH p-0 rounded-md w-20 h-24">
                <img
                  className="rounded-md h-full object-cover object-center"
                  src={imagePatient[6]?.ruta ? fingerUrl : finger}
                  alt="finger"
                />
              </div>
              <P text="Huella" />
              <div>
                <PrimaryButton
                  text="Agregar"
                  handleClick={handleClickOpenModalFinger}
                  blue
                />
                <ModalWithBtn
                  opened={patientFinger}
                  setOpened={setPatientFinger}
                  title="Agregar Huella"
                >
                  <ModalFirma
                    userData={patient}
                    setOpened={setPatientFinger}
                    patient={codPatient.fingerprint}
                  />
                </ModalWithBtn>
              </div>
            </article>
          </div>
        </section>
        <section className="md:w-4/5 w-full flex flex-col h-full">
          <article className="w-full flex">
            <div className="w-1/4 bg-pinkT p-3 rounded-lg">
              <div className="w-full flex justify-between">
                <P blue text={patient?.nombres} />
                <P text="M" />
              </div>
              <div className="w-full flex justify-between">
                <P text="44444444" />
                <P text="12/12/1990 (31)" />
              </div>
            </div>
            {/* <div className="w-3/4">signos</div> */}
          </article>
          <article className="w-full mt-2 flex flex-col">
            {/* <PrimaryButton text="Buscar datos" handleClick={addDataExam} /> */}
            <DinamicFormExams
              idPaciente={patient.id}
              idExam={idExam}
              dataForm={jsonTemplate}
              dataResponse={jsonResponse}
            />
          </article>
        </section>
      </div>
    </Wraper>
  );
}

DetailPatient.propTypes = {};

export default DetailPatient;
