import React from 'react';
// import PropTypes from 'prop-types';
import { Tooltip } from '@mantine/core';

function H2({ text }) {
  return (
    <Tooltip label={text} color="dark" transition="fade" position="bottom">
      <h2 className="text-2xl text-purpleT font-poppins font-bold md:text-xl truncate">
        {text}
      </h2>
    </Tooltip>
  );
}

// H2.propTypes = {
//   text: PropTypes.string.isRequired,
// };

export default H2;
