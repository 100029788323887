/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { Table, Tooltip } from '@mantine/core';
import React from 'react';
import { TableIcon } from '@heroicons/react/solid';
import { CSVLink } from 'react-csv';
import classNames from '../../../utils/classNames';

function TableComponent({
  data,
  allData,
  handleClickDetailQuote,
  handleClickCopyQuote,
}) {
  const headers = [
    { label: 'N° Cotizacion', key: 'no_cotizacion' },
    { label: 'Empresa', key: 'razonSocial' },
    { label: 'Ruc', key: 'rucDni' },
    { label: 'Estado', key: 'estado' },
    { label: 'Fecha', key: 'fecha_cotizacion' },
  ];
  const ths = (
    <tr>
      <th>N° Cotizacion</th>
      <th>Empresa</th>
      <th>Ruc</th>
      <th>Estado</th>
      <th>Fecha</th>
      <th>SubTotal</th>
      <th>Total</th>
      <th>
        {data ? (
          <CSVLink
            data={allData}
            headers={headers}
            filename="Listado-Cotizaciones.csv"
          >
            <Tooltip label="Descargar todo">
              <TableIcon className="text-green-600 w-8 h-8" />
            </Tooltip>
          </CSVLink>
        ) : null}
      </th>
    </tr>
  );

  const rows = data?.map((element, index) => (
    <tr key={index}>
      <td>{element.no_cotizacion}</td>
      <td>{element.razonSocial}</td>
      <td>{element.rucDni}</td>
      <td>
        <div
          className={classNames(
            'rounded-md px-2 py-1 text-center text-gray-900 font-medium',
            element.state === 'Aceptada' && 'bg-verde1/30'
          )}
        >
          {element.estado}
        </div>
      </td>
      <td>{element.fecha_cotizacion}</td>
      <td>100 Soles</td>
      <td>118 Soles</td>
      <td>
        <div className="flex gap-1">
          <button
            className={classNames(
              'text-white py-1 px-2 rounded-md w-full',

              element.state === 'Aceptada'
                ? 'bg-teal-600 hover:bg-teal-700'
                : 'bg-purpleT hover:bg-indigo-700'
            )}
            type="button"
            onClick={(e) => {
              handleClickDetailQuote(e, element);
            }}
          >
            Ver
          </button>
          <button
            className={classNames(
              'text-white py-1 px-2 rounded-md w-full bg-teal-600 hover:bg-teal-700'
            )}
            type="button"
            onClick={(e) => {
              handleClickCopyQuote(e, element);
            }}
          >
            Copiar
          </button>
        </div>
      </td>
    </tr>
  ));

  return (
    <section className="w-full p-4 overflow-x-scroll">
      <Table highlightOnHover>
        <thead>{ths}</thead>
        <tbody>{rows}</tbody>
      </Table>
    </section>
  );
}

export default TableComponent;
