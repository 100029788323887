import React from 'react';
// import PropTypes from 'prop-types';

function HeaderCard({ classNames, children }) {
  return <header className={classNames}>{children}</header>;
}

// HeaderCard.propTypes = {
//   state: PropTypes.string.isRequired,
// };

export default HeaderCard;
