import React from 'react';
// import PropTypes from 'prop-types'

function InputContainers({ children }) {
  return (
    <div className="mt-6 lg:mt-1 py-2 flex flex-col gap-x-4 gap-y-7 md:flex-row md:flex-wrap md:items-end">
      {children}
    </div>
  );
}

InputContainers.propTypes = {};

export default InputContainers;
