/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  addPatient,
  showPatients,
  showPacient,
  patientImage,
} from '../api/requests';

const key = 'patients';

export const useMutatePatient = () => {
  const queryClient = useQueryClient();

  return useMutation(addPatient, {
    onSuccess: () => {
      queryClient.invalidateQueries(key);
    },
  });
};

export const patientById = (idPatient) =>
  useQuery(['patient', idPatient], () => showPacient(idPatient), {
    enabled: !!idPatient,
  });

export const useUploadImagePatient = () => {
  const queryClient = useQueryClient();

  return useMutation(patientImage, {
    onSuccess: () => {
      queryClient.invalidateQueries('patient');
    },
  });
};

export const usePatient = () => useQuery(key, showPatients);
