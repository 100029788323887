/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useParams } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { Notification } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import { useUpdateCotiPortada } from '../../../../hooks/quote';
import P from '../../../../_desingModels/textComponents/P';
import classNames from '../../../../_desingModels/utils/classNames';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import XIconIconMobile from '../../../../_desingModels/iconComponets/XIcon';
import SelectSearch from '../../../../_desingModels/layout/components/select/SelectSearch';
import Input from '../../../../_desingModels/layout/components/form/Input/Input';
import TextArea from '../../../../_desingModels/layout/components/form/textArea/TextArea';
import { useShowComboClient } from '../../../../hooks/selects';

function ModalEditPortada({ setOpened, dataPortada }) {
  const { register, reset, control, handleSubmit, setValue } = useForm();
  const updateCotiPortada = useUpdateCotiPortada();
  const clientParams = useParams();
  const nameQuote = clientParams['*'].split('/');
  const idQuote = nameQuote[1].split('-');
  const comboClient = useShowComboClient();
  const onSubmit = async (dataForm) => {
    let newData;
    if (dataForm.id_empresa === undefined) {
      newData = {
        id_cotizacion: idQuote.at(-1),
        id_empresa: dataPortada.id_empresa,
        comentarios: dataForm.comentarios,
        nombre: dataForm.nombre,
        subnombre: dataForm.subnombre,
      };
    } else {
      newData = {
        id_cotizacion: idQuote.at(-1),
        ...dataForm,
      };
    }
    updateCotiPortada.mutate(newData);
  };
  return (
    <div className="w-full px-3 pb-3 flex flex-col gap-3">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col gap-2 items-center"
      >
        {comboClient.isSuccess ? (
          <div className="flex flex-col gap-1 font-bold text-purpleT w-full">
            Empresa
            <Controller
              control={control}
              name="id_empresa"
              render={({ field }) => (
                <SelectSearch
                  dataClient={comboClient?.data?.data?.sede_combo}
                  onChange={(value) => field.onChange(value)}
                  defaultValue={dataPortada.id_empresa}
                />
              )}
            />
          </div>
        ) : null}
        <div className="w-full">
          <Input
            modal
            textLabel="Nombre"
            type="text"
            defaultValue={dataPortada.nombre}
            registerValue={register('nombre', { required: true })}
          />
        </div>
        <div className="w-full">
          <Input
            textLabel="Sub nombre"
            type="text"
            placeholder="0"
            defaultValue={dataPortada.subnombre}
            registerValue={register('subnombre', { required: true })}
            modal
          />
        </div>
        <div className="w-full">
          <TextArea
            modal
            textLabel="Comentarios"
            defaultValue={dataPortada.comentarios}
            registerValue={register('comentarios', {
              required: true,
            })}
          />
        </div>
        <section className="flex justify-center gap-2">
          <PrimaryButton type="submit" blue text="Guardar" />
          <PrimaryButton
            delet
            text="Cancelar"
            handleClick={() => setOpened(false)}
          />
        </section>
      </form>
      {updateCotiPortada.isLoading ? (
        <Notification
          loading
          title="Uploading data to the server"
          disallowClose
        >
          Espere hasta que se carguen los datos, no puede cerrar esta
          notificación aún
        </Notification>
      ) : (
        <>
          {updateCotiPortada.isError ? (
            <Notification
              icon={<XIconIconMobile size={18} />}
              color="red"
              title="Error"
              onClose={updateCotiPortada.reset}
            >
              Ups! Hubo un error al guardar los datos
            </Notification>
          ) : null}

          {updateCotiPortada.isSuccess ? (
            <Notification
              onClose={updateCotiPortada.reset}
              icon={<CheckCircleIcon size={18} />}
              color="teal"
              title="Correcto"
            >
              Se guardarón los datos correctamente
            </Notification>
          ) : null}
        </>
      )}
    </div>
  );
}

export default ModalEditPortada;
