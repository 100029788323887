import React from 'react';
// import PropTypes from 'prop-types';

function TableContainer({ children }) {
  return (
    <table className="w-full table-auto flex flex-col bg-white items-center gap-3 md:overflow-x-auto">
      {children}
    </table>
  );
}

TableContainer.propTypes = {};

export default TableContainer;
