import { ExclamationCircleIcon } from '@heroicons/react/solid';
import classNames from '../utils/classNames';

function ExclamationCircle({ xs }) {
  return (
    <ExclamationCircleIcon
      className={classNames('block ', xs ? 'h-3 w-3' : 'h-5 w-5')}
    />
  );
}

export default ExclamationCircle;
