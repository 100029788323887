import React from 'react';
import PropTypes from 'prop-types';
import MinusCircle from '../iconComponets/MinusCircle';
import DotsCircle from '../iconComponets/DotsCircle';
import ExclamationCircle from '../iconComponets/ExclamationCircle';
import CheckCircleIcon from '../iconComponets/CheckCircle';

function CardIcon({ state }) {
  if (state === 'Atendiendose') {
    return <DotsCircle />;
  }
  if (state === 'Completo' || state === 'Aceptada') {
    return <CheckCircleIcon />;
  }
  if (state === 'Observado') {
    return <ExclamationCircle />;
  }
  if (state === 'Ausente') {
    return <MinusCircle />;
  }
  return null;
}

CardIcon.propTypes = {
  state: PropTypes.string.isRequired,
};

export default CardIcon;
