import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider, QueryClient } from 'react-query';
import LogRocket from 'logrocket';
import reportWebVitals from './reportWebVitals';
import './_desingModels/assets/css/index.css';
import AppRoutes from './app/routing/AppRoutes';
import { DataProvider } from './context/dataContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});
LogRocket.init('kzfdzl/euro_salud');
LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
  name: 'Nayruth Calla',
  email: 'nayruth.calla@gmail.com',

  // Add your own custom user variables here, ie:
  subscriptionType: 'pro',
});
root.render(
  <QueryClientProvider client={queryClient}>
    <DataProvider>
      <AppRoutes />
    </DataProvider>
  </QueryClientProvider>
);

reportWebVitals();
// http://143.244.188.243/api
