import React from 'react';
// import PropTypes from 'prop-types';

function GenericButton({ classNames, children, handleClick }) {
  return (
    <button type="button" className={classNames} onClick={handleClick}>
      {children}
    </button>
  );
}

// GenericButton.propTypes = {
//   text: PropTypes.string.isRequired,
// };

export default GenericButton;
