/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Accordion, Notification, Tooltip } from '@mantine/core';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import H5 from '../../../../_desingModels/textComponents/H5';
import HeaderDashVertical from '../../../layaouts/headerDashboard/HeaderDashVertical';
import Wraper from '../../../layaouts/wrapper/Wrapper';
import HeaderDashHorizont from '../../../layaouts/headerDashboard/HeaderDashHorizont';
import H2 from '../../../../_desingModels/textComponents/H2';
import P from '../../../../_desingModels/textComponents/P';
import Card from '../../../../_desingModels/layout/components/card/Card';
import H4 from '../../../../_desingModels/textComponents/H4';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import classNames from '../../../../_desingModels/utils/classNames';
import PlusIconMobile from '../../../../_desingModels/iconComponets/PlusIcon';
import DocumentIcon from '../../../../_desingModels/iconComponets/DocumentIcon';
import GenericButton from '../../../../_desingModels/layout/components/Buttons/GenericButton';
import PresentationIcon from '../../../../_desingModels/iconComponets/PresentationIcon';
import XIcon from '../../../../_desingModels/iconComponets/XIcon';
import CheckCircleIcon from '../../../../_desingModels/iconComponets/CheckCircle';
import ModalWithBtn from '../../../layaouts/modal/ModalWithBtn';
import ModalAddExam from '../protocols/ModalAddExam';
import {
  useComboLimit,
  useDeleteQuoteExam,
  useGetConfigQuotes,
  useGetCotiPortada,
  useMutateApprove,
  useMutateCheck,
  useMutateReject,
  useMutateSend,
  useUpdateConfig,
  useUpdateCotiPortada,
} from '../../../../hooks/quote';
import ModalAddEditExam from './ModalEditExam';
import EditIcon from '../../../../_desingModels/iconComponets/EditIcon';
import CheckboxDefault from '../../../../_desingModels/layout/components/select/CheckboxDefault';
import ModalEditPortada from './ModalEditPortada';

const eliminarObjetosVacios = (examTree) =>
  examTree
    .map((item) => ({
      ...item,
      children: item.children.filter(
        (child) => child.value !== '' || child.label !== ''
      ),
    }))
    .filter((item) => item.children.length > 0);

function DetailQuote({ otrodato, examTree, quote }) {
  const userData = JSON.parse(window.localStorage.getItem('user'));
  const [modalExamC, setModalExamC] = useState(false);
  const [modalPortada, setModalPortada] = useState(false);
  const [dataQuote, setDataQuote] = useState();
  const [modalEdit, setModalEdit] = useState(false);
  const [editChange, setEditChange] = useState(false);
  const [exam, setExam] = useState();
  const clientParams = useParams();
  const nameQuote = clientParams['*'].split('/');
  const limitantes = useComboLimit();
  const configQuote = useGetConfigQuotes(nameQuote[1].split('-').at(-1));

  const idQuote = nameQuote[1].split('-');

  const {
    mutate,
    isLoading,
    isSuccess,
    isError,
    reset: resetQuery,
  } = useDeleteQuoteExam();

  const updateConfigQuote = useUpdateConfig();

  const checkQuote = useMutateCheck();
  const sendQuote = useMutateSend();
  const aproveQuote = useMutateApprove();
  const rejectQuote = useMutateReject();
  // const updateCotiPortada = useUpdateCotiPortada();
  const cotiPortada = useGetCotiPortada(idQuote[idQuote.length - 1]);

  const handleClickEdit = () => {
    setEditChange(!editChange);
  };

  /* const handleSubmit = (e) => {
    e.preventDefault();
    const dataPortada = {
      id_cotizacion: idQuote[idQuote.length - 1],
      nombre: e.target.nombre.value,
      subnombre: e.target.subnombre.value,
      comentarios: e.target.comentarios.value,
    };
    updateCotiPortada.mutate(dataPortada);
  }; */

  const handleClickAddExamC = () => {
    setModalExamC(true);
  };
  const handleClickDelete = (item) => {
    const data = {
      id_cotizacion: idQuote[idQuote.length - 1],
      id_exam: item,
    };
    // console.log(data);
    mutate(data);
  };

  const handleClickSaveConfig = (config, check) => {
    const tree = eliminarObjetosVacios(configQuote?.data?.contizacion);
    const checkNumber = check.map((str) => parseInt(str, 10));
    const arrayTree = tree.flatMap((item) =>
      item.children.map((child) => child.value)
    );
    const submitData = {
      id_cotizacion: parseInt(nameQuote[1].split('-').at(-1), 10),
      id_tipo_valuacion: config.id_valuacion,
      id_perfil: config.id_perfil,
      id_examen_si: checkNumber,
      id_examen_no: arrayTree.filter((item) => !checkNumber.includes(item)),
    };
    updateConfigQuote.mutate(submitData);
  };
  const handleClickEditExam = (e, dataExam) => {
    setExam(dataExam);
    setModalEdit(true);
  };
  const handleClickEnviar = () => {
    const data = {
      id_cotizacion: idQuote[idQuote.length - 1],
    };
    if (userData?.user?.id_nivel_espertis === 0) {
      checkQuote.mutate(data);
      // console.log('soy Junior');
    } else if (userData?.user?.id_nivel_espertis === 1) {
      sendQuote.mutate(data);
      // console.log('soy Senior');
    } else if (userData?.user?.id_nivel_espertis === 3) {
      console.log('soy Auditor');
    } else {
      console.log('no soy nada');
    }
  };
  const handleClickAceptar = () => {
    const data = {
      id_cotizacion: idQuote[idQuote.length - 1],
    };
    aproveQuote.mutate(data);
  };
  const handleClickRechazar = () => {
    const data = {
      id_cotizacion: idQuote[idQuote.length - 1],
    };
    rejectQuote.mutate(data);
  };
  const onClickDownloadQuote = () => {
    const data = idQuote[idQuote.length - 1];
    axios({
      url: `${process.env.REACT_APP_BACKEND_URL}/cotizaciones/pdf/generateCotizacion/${data}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf');
      document.body.appendChild(link);
      link.click();
    });
  };
  const onClickDownloadPresentation = () => {
    axios({
      url: `${process.env.REACT_APP_BACKEND_URL}/cotizaciones/pdf/eurocliniComercial`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'presentation.pdf');
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div className="flex w-full flex-col lg:flex-row gap-3">
      <HeaderDashVertical text="Cotizaciones">
        <div className="w-[250px] px-3 pb-3 flex flex-col gap-2">
          <H2 text={quote?.razonSocial} />
          <P blue text="Fecha:" />
          <P text={quote?.fecha_cotizacion} />
          <P blue text="N° Cotización:" />
          <P text={quote?.no_cotizacion} />
          <P blue text="Nombre:" />
          <P text={quote?.nombre} />
          <P blue text="SubNombre:" />
          <P text={quote?.subnombre} />
          <P blue text="Comentario:" />
          <P text={quote?.comentarios} />
          <section className="flex justify-center gap-2">
            <PrimaryButton
              blue
              text="Editar"
              handleClick={() => setModalPortada(true)}
            />
          </section>
        </div>
        <ModalWithBtn
          opened={modalPortada}
          setOpened={setModalPortada}
          title="Editar"
          tamanio="sm"
        >
          <ModalEditPortada
            setOpened={setModalPortada}
            dataPortada={quote}
            empresas
          />
        </ModalWithBtn>
      </HeaderDashVertical>
      <Wraper nofullScreen>
        <HeaderDashHorizont newButton={false} title={nameQuote[1]}>
          {userData?.user?.id_nivel_espertis !== 0 ? (
            <div className="w-full justify-center items-center flex flex-row gap-2 md:justify-start">
              <Tooltip label="Formulario de presentación">
                <div className="flex gap-2 items-center">
                  <GenericButton
                    classNames="w-fit bg-purpleT px-2 py-2 rounded-md text-white hover:bg-blue-800"
                    handleClick={onClickDownloadPresentation}
                  >
                    <PresentationIcon />
                  </GenericButton>
                </div>
              </Tooltip>
              <Tooltip label="Formulario de cotización">
                <div className="flex gap-2 items-center">
                  <GenericButton
                    classNames="w-fit bg-purpleT px-2 py-2 rounded-md text-white hover:bg-blue-800"
                    handleClick={onClickDownloadQuote}
                  >
                    <DocumentIcon />
                  </GenericButton>
                </div>
              </Tooltip>
            </div>
          ) : null}
        </HeaderDashHorizont>
        <div className="w-full pl-4">
          <div className="p-2" />
          <H5
            text={`Total: S/. ${otrodato?.costo?.[0]?.total}`}
            colorText="colorBlack400"
          />
        </div>
        <section className="w-full px-2 md:px-4 flex flex-col gap-4">
          <div className="w-full flex flex-wrap gap-3">
            {otrodato?.examenes?.map((item, index) => (
              <div key={`${item.value}-${index}`}>
                <Card
                  classNames={classNames(
                    'flex flex-col border-2 rounded-md w-56 border-purpleT'
                  )}
                >
                  <section className="relative">
                    {userData?.user?.id_nivel_espertis !== 0 ? (
                      <>
                        <button
                          disabled={userData?.user?.id_nivel_espertis === 0}
                          type="button"
                          className="absolute top-[-13px] right-[20px] bg-purpleT rounded-full flex justify-center items-center w-6 h-6 hover:bg-rojo1"
                          onClick={() => handleClickDelete(item.value)}
                        >
                          <XIcon />
                        </button>
                        <button
                          disabled={userData?.user?.id_nivel_espertis === 0}
                          type="button"
                          className="absolute top-[-13px] right-[50px] bg-purpleT rounded-full flex justify-center items-center w-6 h-6 hover:bg-verde2"
                          onClick={(e) => {
                            handleClickEditExam(e, item);
                          }}
                        >
                          <EditIcon />
                        </button>
                      </>
                    ) : null}
                    <div className="text-gray-800 p-4 flex flex-col gap-2">
                      <H4 text={item.label} blue />
                      <div className="w-full flex flex-row items-center gap-2">
                        <div className="w-24 p-2 border-[1px] border-purpleT rounded-lg text-black/60">
                          <H4 text={item.costo} />
                        </div>
                        <H4 text="soles" />
                      </div>
                      <div className="w-full p-2 border-[1px] border-purpleT rounded-lg text-black/60">
                        <H4
                          text={
                            limitantes?.data?.data?.roles?.filter(
                              (limit) => item?.limitante === limit.value
                            )?.[0].label
                          }
                        />
                      </div>
                    </div>
                  </section>
                </Card>
                <ModalWithBtn
                  opened={modalEdit}
                  setOpened={setModalEdit}
                  title="Editar exámen"
                  tamanio="auto"
                >
                  <ModalAddEditExam
                    total={otrodato?.costo?.[0]?.total}
                    limitantes={limitantes}
                    dataExam={exam}
                    setOpened={setModalEdit}
                  />
                </ModalWithBtn>
              </div>
            ))}
            {userData?.user?.id_nivel_espertis !== 0 ? (
              <Card
                classNames={classNames(
                  'flex flex-col rounded-md w-56 border-purpleT'
                )}
              >
                <section className="w-full h-[144px] p-2 rounded-md bg-azul3 flex flex-col justify-center items-center">
                  <div
                    role="button"
                    tabIndex={0}
                    className="bg-purpleT rounded-full flex justify-center items-center w-12 h-12 mb-1"
                    onClick={handleClickAddExamC}
                  >
                    <PlusIconMobile />
                  </div>
                  <ModalWithBtn
                    opened={modalExamC}
                    setOpened={setModalExamC}
                    title="Agregar Exámen - Cotización"
                  >
                    <ModalAddExam
                      setOpened={setModalExamC}
                      examTree={examTree}
                    />
                  </ModalWithBtn>
                </section>
              </Card>
            ) : null}
          </div>
        </section>
        <div className="w-full mt-4 flex justify-center items-center gap-2">
          {/* <PrimaryButton type="input" text="Guardar cambios" blue /> */}
          {userData?.user?.id_nivel_espertis === 3 ? (
            <>
              <PrimaryButton
                disabled={
                  !!(
                    (userData?.user?.id_nivel_espertis === 3 &&
                      otrodato?.estado?.[0]?.value === 5) ||
                    (userData?.user?.id_nivel_espertis === 3 &&
                      otrodato?.estado?.[0]?.value === 3)
                  )
                }
                type="input"
                text="Aceptar"
                blue
                handleClick={handleClickAceptar}
              />
              <PrimaryButton
                disabled={
                  !!(
                    (userData?.user?.id_nivel_espertis === 3 &&
                      otrodato?.estado?.[0]?.value === 5) ||
                    (userData?.user?.id_nivel_espertis === 3 &&
                      otrodato?.estado?.[0]?.value === 3)
                  )
                }
                type="input"
                text="Rechazar"
                blue
                handleClick={handleClickRechazar}
              />
            </>
          ) : (
            <PrimaryButton
              disabled={
                userData?.user?.id_nivel_espertis === 0
                  ? !!(
                      userData?.user?.id_nivel_espertis === 0 &&
                      otrodato?.estado?.[0]?.value === 6
                    )
                  : userData?.user?.id_nivel_espertis === 1
                  ? !!(
                      userData?.user?.id_nivel_espertis === 1 &&
                      otrodato?.estado?.[0]?.value === 4
                    )
                  : false
              }
              blue
              text={
                userData?.user?.id_nivel_espertis !== 0
                  ? 'Enviar cotización'
                  : 'Enviar a auditar'
              }
              handleClick={handleClickEnviar}
            />
          )}
        </div>
        <section className="w-full flex flex-col gap-5 p-5 border-2 border-purpleT rounded-md">
          <H2 text="Configuración" />
          {updateConfigQuote.isLoading ? (
            <Notification loading title="Enviando datos" disallowClose>
              Espere hasta que se carguen los datos, no puede cerrar esta
              notificación aún
            </Notification>
          ) : (
            <>
              {updateConfigQuote.isError ? (
                <Notification
                  icon={<XIcon size={18} />}
                  color="red"
                  title="Error"
                  onClose={updateConfigQuote.reset}
                >
                  Ups! Hubo un error al guardar los datos
                </Notification>
              ) : null}

              {updateConfigQuote.isSuccess ? (
                <Notification
                  onClose={updateConfigQuote.reset}
                  icon={<CheckCircleIcon size={18} />}
                  color="teal"
                  title="Correcto"
                >
                  Se guardarón los datos correctamente
                </Notification>
              ) : null}
            </>
          )}
          {configQuote.isLoading ? (
            <P text="Cargando" />
          ) : configQuote.isError ? (
            <P text="Error al cargar los datos" />
          ) : (
            <Accordion>
              {configQuote.data.selecciones.map((item, index) => {
                let checkedSi;
                const handleCheckedChange = (checked) => {
                  checkedSi = checked;
                };
                return (
                  <Accordion.Item key={index} value={`${index}`}>
                    <Accordion.Control>
                      <section className="w-full items-center flex gap-2">
                        <div className="border border-purpleT rounded-md p-1">
                          <P text={item.perfil} />
                        </div>
                        <div className="border border-purpleT rounded-md p-1">
                          <P text={item.valuacion} />
                        </div>
                      </section>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <section className="w-full flex-col gap-5">
                        <section className="flex justify-center gap-2">
                          <PrimaryButton
                            blue
                            text="Guardar"
                            handleClick={() =>
                              handleClickSaveConfig(item, checkedSi)
                            }
                          />
                        </section>
                        <div className="w-full flex flex-col gap-2">
                          <P modal text="Selección de examenes" />
                          <CheckboxDefault
                            key={index}
                            exam={item.examenes}
                            tree={eliminarObjetosVacios(
                              configQuote?.data?.contizacion
                            )}
                            checkSi={handleCheckedChange}
                          />
                        </div>
                      </section>
                    </Accordion.Panel>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          )}
        </section>
      </Wraper>
    </div>
  );
}

DetailQuote.propTypes = {};

export default DetailQuote;
