// import { useState } from 'react';
import { DatePicker } from '@mantine/dates';

function InputCalendar({ placeholder, onChange, selected }) {
  // const [value, setValueIn] = useState(new Date());

  // const handleChange = (e) => {
  //   setValue(idDate, { shouldValidate: true });
  //   setValueIn(e);
  //   console.log(e, 'fecha');
  // };
  return (
    <DatePicker
      placeholder={placeholder}
      required
      value={selected}
      onChange={onChange}
      classNames={{
        placeholder: 'text-black/60 text-base',
        input: 'border border-indigo-500',
      }}
    />
  );
}
export default InputCalendar;
