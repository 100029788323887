/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Notification, Pagination, Tooltip } from '@mantine/core';
import {
  CheckCircleIcon,
  PencilAltIcon,
  TableIcon,
  XIcon,
} from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import Wrapper from '../../../layaouts/wrapper/Wrapper';
import HeaderDashHorizont from '../../../layaouts/headerDashboard/HeaderDashHorizont';
import { useDeleteExam } from '../../../../hooks/catalogo';
import XIconIconMobile from '../../../../_desingModels/iconComponets/XIcon';
import ModalWithBtn from '../../../layaouts/modal/ModalWithBtn';
import ModalEditExam from './ModalEditExam';

function CatalogoExamen() {
  const [data, setData] = useState([]);
  const [allExam, setAllExam] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editCatExam, setEditCatExam] = useState(false);
  const [dataEdit, setDataEdit] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(data);
  const deleteExam = useDeleteExam();

  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      deleteExam.reset();
    }, 3000);
    return () => clearTimeout(timer);
  }, [deleteExam.isSuccess]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.euro-salud.com/api/examenes/show_examenes`
        );
        const jsonData = await response.json();
        setAllExam(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [deleteExam.isSuccess, editCatExam]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Simulando la obtención de datos
        const response = await fetch(
          `https://api.euro-salud.com/api/examenes/show_examenes_paginador?page=${currentPage}`
        );
        const jsonData = await response.json();
        setData(jsonData.data);
        setTotalPages(jsonData.last_page);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [currentPage, deleteExam.isSuccess, editCatExam]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleNewExtra = (event) => {
    event.preventDefault();
    navigate('/dashboard/generateExam');
  };
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = allExam.filter(
      (item) =>
        item.examen.toLowerCase().includes(value) ||
        item.descripcion.toLowerCase().includes(value) ||
        item.id.toString().includes(value) ||
        item.minimo.toString().includes(value) ||
        item.normal.toString().includes(value) ||
        item.alto.toString().includes(value)
    );
    setFilteredData(filtered);
  };
  const handleClickDelete = (idExamen) => {
    const dataDelete = {
      id_examen: idExamen,
    };
    deleteExam.mutate(dataDelete);
  };
  const handleClickEdit = (dataExamen) => {
    setDataEdit(dataExamen);
    setEditCatExam(true);
  };
  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'Examen', key: 'examen' },
    { label: 'P. Minimo', key: 'minimo' },
    { label: 'P. Normal', key: 'normal' },
    { label: 'P. Maximo', key: 'alto' },
  ];
  return (
    <div className="flex w-full flex-col lg:flex-row gap-3">
      <Wrapper nofullScreen>
        <HeaderDashHorizont
          title="Catálogo de examenes"
          handleClick={handleNewExtra}
        />
        <ModalWithBtn
          opened={editCatExam}
          setOpened={setEditCatExam}
          title="Editar examen"
          tamanio="md"
        >
          <ModalEditExam setOpened={setEditCatExam} edit={dataEdit} />
        </ModalWithBtn>
        <div className="w-full overflow-x-auto md:w-full p-5 flex flex-col items-center gap-5">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Buscar..."
            className="p-2 border rounded"
          />
          <table className="max-w-[1000px] table-fixed">
            <thead className="w-full border-b">
              <tr className="w-full">
                <th className="w-[5%] py-2">ID</th>
                <th className="w-[29%] py-2">Examen</th>
                <th className="w-[20%] py-2">Descripción</th>
                <th className="w-[13%] py-2">Mínimo</th>
                <th className="w-[13%] py-2">Normal</th>
                <th className="w-[13%] py-2">Alto</th>
                <th className="w-[7%] py-2">
                  {allExam ? (
                    <CSVLink data={allExam} headers={headers}>
                      <Tooltip label="Descargar todo">
                        <TableIcon className="text-green-600 w-8 h-8" />
                      </Tooltip>
                    </CSVLink>
                  ) : null}
                </th>
              </tr>
            </thead>
            {searchTerm.length === 0 ? (
              <tbody>
                {data.map((item) => (
                  <tr key={item.id} className="border-b">
                    <td className="text-center py-2">{item.id}</td>
                    <td className="py-2">{item.examen}</td>
                    <td className="py-2">{item.descripcion}</td>
                    <td className="text-center py-2">{item.minimo}</td>
                    <td className="text-center py-2">{item.normal}</td>
                    <td className="text-center py-2">{item.alto}</td>
                    <td className="text-center py-2 flex gap-1">
                      <Tooltip label="Editar">
                        <button
                          className="bg-purpleT p-1 rounded-md cursor-pointer"
                          type="button"
                          value="X"
                          onClick={() => handleClickEdit(item)}
                        >
                          <PencilAltIcon className="text-blanc1 w-6 h-6" />
                        </button>
                      </Tooltip>
                      <Tooltip label="Eliminar">
                        <button
                          className="bg-rojo1 p-1 rounded-md cursor-pointer"
                          type="button"
                          value="X"
                          onClick={() => handleClickDelete(item.id)}
                        >
                          <XIcon className="text-blanc1 w-6 h-6" />
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                {filteredData.map((item) => (
                  <tr key={item.id} className="border-b">
                    <td className="text-center py-2">{item.id}</td>
                    <td className="py-2">{item.examen}</td>
                    <td className="py-2">{item.descripcion}</td>
                    <td className="text-center py-2">{item.minimo}</td>
                    <td className="text-center py-2">{item.normal}</td>
                    <td className="text-center py-2">{item.alto}</td>
                    <td className="text-center py-2 flex gap-1">
                      <Tooltip label="Editar">
                        <button
                          className="bg-purpleT p-1 rounded-md cursor-pointer"
                          type="button"
                          value="X"
                          onClick={() => handleClickEdit(item)}
                        >
                          <PencilAltIcon className="text-blanc1 w-6 h-6" />
                        </button>
                      </Tooltip>
                      <Tooltip label="Eliminar">
                        <button
                          className="bg-rojo1 p-1 rounded-md cursor-pointer"
                          type="button"
                          value="X"
                          onClick={() => handleClickDelete(item.id)}
                        >
                          <XIcon className="text-blanc1 w-6 h-6" />
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {searchTerm === '' ? (
            <section className="w-full flex justify-center">
              <Pagination
                value={currentPage}
                onChange={handlePageChange}
                total={totalPages}
                sizes={[10, 20, 50]}
                withGoTo
                withEdges
              />
            </section>
          ) : null}
        </div>
      </Wrapper>
      <section className="fixed right-4 bottom-4 shadow-lg">
        {deleteExam.isLoading ? (
          <Notification loading title="Cargando datos" disallowClose>
            Espere hasta que se carguen los datos, no puede cerrar esta
            notificación aún
          </Notification>
        ) : (
          <>
            {deleteExam.isError ? (
              <Notification
                icon={<XIconIconMobile size={18} />}
                color="red"
                title="Error"
                onClose={deleteExam.reset}
              >
                Ups! Hubo un error al guardar los datos
              </Notification>
            ) : null}

            {deleteExam.isSuccess ? (
              <Notification
                icon={<CheckCircleIcon size={18} />}
                color="teal"
                title="Datos guardados"
                onClose={deleteExam.reset}
              >
                Se guardarón los datos correctamente
              </Notification>
            ) : null}
          </>
        )}
      </section>
    </div>
  );
}

CatalogoExamen.propTypes = {};

export default CatalogoExamen;
