import React from 'react';
// import PropTypes from 'prop-types';
import { Outlet, Route, Routes } from 'react-router-dom';
import FormLogin from './FormLogin';
import Image from '../../../_desingModels/layout/components/image/Image';
import logoinText from '../../../assets/logo/logoinText.svg';
import loginIcon1 from '../../../assets/login/loginIcon1.svg';
import loginIcon2 from '../../../assets/login/loginIcon2.svg';
import ForgotPassword from './ForgotPassword';

function AuthLayout() {
  return (
    <div className="flex flex-col p-10 justify-center items-center lg:flex-row lg:justify-between ">
      <figure className="flex flex-col justify-center items-center mt-10 lg:self-end w-52">
        <img
          className="hidden lg:block object-cover"
          src={loginIcon1}
          alt="logo"
        />
      </figure>
      <div className="lg:w-2/5 flex flex-col items-center">
        <figure className="mt-10 lg:mt-3">
          <Image url={logoinText} alt="logo" />
        </figure>
        <Outlet />
      </div>

      <figure className="flex flex-col justify-center items-center mt-10 lg:self-end w-52">
        <img className="object-cover " src={loginIcon2} alt="logo" />
      </figure>
    </div>
  );
}
function Login() {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="/" element={<FormLogin />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route index element={<FormLogin />} />
      </Route>
    </Routes>
  );
}

Login.propTypes = {};

export default Login;
