import React, { useEffect, useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

function TreeCheckGeneric({ tree, checkSi }) {
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    checkSi(checked);
  }, [checked, checkSi]);

  return (
    <div className="w-full flex flex-col gap-2">
      <div className="w-full max-h-52 overflow-scroll">
        <CheckboxTree
          nodes={tree}
          checked={checked}
          expanded={expanded}
          iconsClass="fa5"
          onCheck={(check) => setChecked(check)}
          onExpand={(expand) => setExpanded(expand)}
        />
      </div>
    </div>
  );
}

export default TreeCheckGeneric;
