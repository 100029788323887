// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mantine-MultiSelect-searchInput {
  margin: 0 !important;
  padding: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/commercial/quotes/addQuote.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,qBAAqB;AACvB","sourcesContent":[".mantine-MultiSelect-searchInput {\n  margin: 0 !important;\n  padding: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
