/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import classNames from '../../../utils/classNames';

function Radio({
  name,
  htmlFor,
  textLabel,
  checked,
  value,
  defaultValue,
  registerValue,
  modal,
}) {
  return (
    <label
      className={classNames(
        'flex gap-2 justify-center items-center text-negro'
      )}
      htmlFor={htmlFor}
    >
      {textLabel}
      <input
        className={classNames(
          'border-0 rounded-lg p-1.5  mt-1 text-azul2',
          modal ? 'bg-white border border-indigo-500' : 'bg-blanc1'
        )}
        type="radio"
        id={registerValue.name}
        name={name}
        value={value}
        checked={checked}
        defaultValue={defaultValue}
        {...registerValue}
      />
    </label>
  );
}

Radio.propTypes = {};

export default Radio;
