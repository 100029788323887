import { PencilAltIcon } from '@heroicons/react/outline';

function WritingIcon() {
  return (
    <div className="mr-1 p-1 w-auto h-7 rounded-md text-white bg-blue-800">
      <PencilAltIcon className="block h-5 w-5" aria-hidden="true" />
    </div>
  );
}

export default WritingIcon;
