import React from 'react';
// import PropTypes from 'prop-types';

function Card({ classNames, children }) {
  return <article className={classNames}>{children}</article>;
}

// Card.propTypes = {
//   state: PropTypes.string.isRequired,
// };

export default Card;
