// import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '../../../_desingModels/layout/components/form/Input/Input';
import ErrorMessage from '../../../_desingModels/layout/components/form/Message/Message';
import InputSend from '../../../_desingModels/layout/components/form/inputSend/InputSend';
import Image from '../../../_desingModels/layout/components/image/Image';
import logoinText from '../../../assets/logo/logoinText.svg';
import { login } from '../../../api/requests';
import Loading from '../../../_desingModels/iconComponets/Loading';
import { useStateData } from '../../../context/dataContext';

function FormLogin() {
  const navigate = useNavigate();
  const { setAuthenticatedUser } = useStateData();

  const SignupSchema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required().max(10),
  });
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });

  const loginUser = useMutation(login, {
    onSuccess: ({ data }) => {
      if (data.token) {
        window.localStorage.setItem('user', JSON.stringify(data));
        navigate('/dashboard/inicio');
        setAuthenticatedUser(data);
      }
      // console.log(data, 'data del login');
    },
  });
  const onSubmit = (data) => {
    loginUser.mutate(data);
  };

  return (
    <form
      className="w-full mt-20 md:border md:p-10 md:rounded-lg md:shadow-lg lg:mt-14"
      onSubmit={handleSubmit(onSubmit)}
    >
      <header className="flex gap-2 items-center justify-center mb-10">
        <h2 className="text-xl font-bold text-black/80">Sign In to</h2>
        <figure className="w-28">
          <Image url={logoinText} alt="logo" />
        </figure>
      </header>
      <div className="flex flex-col mt-5">
        <Input
          textLabel="Email"
          type="email"
          placeholder="Email address"
          registerValue={register('email', { required: true })}
        />
        {errors.email && <ErrorMessage message={errors.email.message} />}
      </div>
      <div className="flex flex-col mt-5">
        <Input
          textLabel="Password"
          type="password"
          placeholder="Password"
          registerValue={register('password', { required: true })}
        />
        {errors.password && <ErrorMessage message={errors.password.message} />}
      </div>
      <div className="flex flex-col mt-2 ">
        <button
          type="button"
          className="w-full text-purpleT text-base text-right"
          onClick={() => {
            navigate('/forgot-password');
          }}
        >
          Forgot Password?
        </button>
      </div>
      <div className="flex flex-col mt-10">
        {loginUser.isLoading ? (
          <div className="pb-4 flex justify-center items-center text-indigo-600">
            <Loading />
            Cargando...
          </div>
        ) : (
          <div className="pb-4 flex justify-center">
            {loginUser.isError ? (
              <ErrorMessage message="Correo o contraseña equivocada :(" />
            ) : null}

            {loginUser.isSuccess ? (
              <ErrorMessage message="Datos correctos :)" type="success" />
            ) : null}
          </div>
        )}
        <InputSend type="submit" text="Continue" />
      </div>
    </form>
  );
}

FormLogin.propTypes = {};

export default FormLogin;
