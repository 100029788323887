import React from 'react';
// import PropTypes from 'prop-types'
import H2 from '../../../_desingModels/textComponents/H2';
import PrimaryButton from '../../../_desingModels/layout/components/Buttons/PrimaryButton';

function HeaderDashHorizont({
  title,
  handleClick,
  children,
  newButton = true,
}) {
  return (
    <header className="flex flex-col w-full justify-center items-center p-4 gap-2 md:flex-row md:items-end lg:justify-between">
      <div className="w-full flex flex-col gap-2 lg:flex-row">
        <H2 text={title} />
        <div className="flex flex-col gap-2 justify-center items-center mx-10 md:mx-0 md:flex-row ">
          {children}
        </div>
      </div>
      {newButton ? (
        <PrimaryButton text="Nuevo" handleClick={handleClick} blue />
      ) : null}
    </header>
  );
}

HeaderDashHorizont.propTypes = {};

export default HeaderDashHorizont;
