import React from 'react';
// import PropTypes from 'prop-types'
import { Modal } from '@mantine/core';

function ModalComponent({ title, opened, setOpened, children, tamanio }) {
  return (
    <Modal
      overlayOpacity={0.2}
      size={tamanio || 'xl'}
      title={title}
      opened={opened}
      onClose={() => setOpened(false)}
      classNames={{
        modal: 'bg-gray-100',
        title:
          'text-2xl text-purpleT font-poppins font-bold md:text-3xl truncate flex justify-center',
        header: 'flex justify-center',
      }}
    >
      <div className="w-full flex justify-center items-center">{children}</div>
    </Modal>
  );
}

ModalComponent.propTypes = {};

export default ModalComponent;
