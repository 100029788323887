/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React from 'react';

// import PropTypes from 'prop-types'
import { Notification } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '../../../../_desingModels/layout/components/form/Input/Input';
import ErrorMessage from '../../../../_desingModels/layout/components/form/Message/Message';
import CheckCircleIcon from '../../../../_desingModels/iconComponets/CheckCircle';
import XIcon from '../../../../_desingModels/iconComponets/XIcon';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import {
  useMutateContact,
  useMutateContactUpdate,
} from '../../../../hooks/clients';

function AddContactModal({ setOpened, idClient, dataContact, editModal }) {
  const dataSchema = yup.object().shape({
    nombre: yup.string().required('*obl').max(50).min(3),
    ap_paterno: yup.string().required('*obl').max(50).min(3),
    ap_materno: yup.string().required('*obl').max(50).min(3),
    area: yup.string().required('*obl').max(50).min(3),
    email: yup.string().required('*obl').email(),
    telefono: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer(),
  });
  const {
    register,
    handleSubmit,
    // setValue,
    reset,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(dataSchema),
  });
  const {
    mutate,
    isLoading,
    isSuccess,
    isError,
    reset: resetQuery,
  } = useMutateContact();
  const updateContact = useMutateContactUpdate();

  const onSubmit = (data) => {
    if (editModal) {
      const editData = {
        ap_materno: data.ap_materno,
        ap_paterno: data.ap_paterno,
        area: data.area,
        mail: data.email,
        nombre: data.nombre,
        telefono: data.telefono,
      };
      console.log('editContacto', editData);
      updateContact.mutate({ id: dataContact.id, clientData: editData });
      // reset();
    } else {
      const newData = {
        ...data,
        id_cliente: idClient,
      };
      console.log('contacto', newData);
      mutate(newData);
      reset();
    }
  };

  // console.log(addSede);
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pb-5 w-full flex flex-col gap-5 mt-10"
      >
        <div className="w-full flex flex-col gap-5 items-end md:flex-row md:gap-2">
          <div className="w-full">
            <Input
              textLabel="Nombres"
              type="text"
              placeholder="Nombres"
              registerValue={register('nombre', { required: true })}
              modal
              defaultValue={dataContact?.nombre}
            />
            {errors.nombre && (
              <ErrorMessage xs message={errors.nombre.message} />
            )}
          </div>
          <div className="w-full">
            <Input
              textLabel="Apellido Paterno"
              type="text"
              placeholder="Apellido Paterno"
              registerValue={register('ap_paterno', { required: true })}
              modal
              defaultValue={dataContact?.apellido_paterno}
            />
            {errors.ap_paterno && (
              <ErrorMessage xs message={errors.ap_paterno.message} />
            )}
          </div>
          <div className="w-full">
            <Input
              textLabel="Apellido Materno"
              type="text"
              placeholder="Apellido Materno"
              registerValue={register('ap_materno', { required: true })}
              modal
              defaultValue={dataContact?.apellido_materno}
            />
            {errors.ap_materno && (
              <ErrorMessage xs message={errors.ap_materno.message} />
            )}
          </div>
        </div>
        <div className="w-full flex flex-col gap-5 items-end md:flex-row md:gap-2">
          <div className="w-full">
            <Input
              textLabel="Cargo"
              type="text"
              placeholder="Cargo"
              registerValue={register('area', { required: true })}
              modal
              defaultValue={dataContact?.area_pertenece}
            />
            {errors.area && <ErrorMessage xs message={errors.area.message} />}
          </div>
        </div>

        <div className="w-full flex flex-col gap-5 items-end md:flex-row md:gap-2">
          <div className="w-full">
            <Input
              textLabel="Email"
              type="email"
              placeholder="Correo"
              registerValue={register('email', { required: true })}
              modal
              defaultValue={dataContact?.email}
            />
            {errors.email && <ErrorMessage xs message={errors.email.message} />}
          </div>
          <div className="w-full">
            <Input
              textLabel="Teléfono"
              type="tel"
              placeholder="Teléfono"
              name="telefono"
              registerValue={register('telefono', { required: true })}
              modal
              defaultValue={dataContact?.telefono}
            />
            {errors.telefono && (
              <ErrorMessage xs message={errors.telefono.message} />
            )}
          </div>
        </div>

        <div className="pt-8 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
          <PrimaryButton type="input" text="Guardar" blue />
          <PrimaryButton
            text={isSuccess ? 'Cerrar' : 'Cancelar'}
            modal
            handleClick={() => setOpened(false)}
          />
        </div>
      </form>
      {isLoading || updateContact.isLoading ? (
        <Notification
          loading
          title="Uploading data to the server"
          disallowClose
        >
          Espere hasta que se carguen los datos, no puede cerrar esta
          notificación aún
        </Notification>
      ) : (
        <>
          {isError || updateContact.isError ? (
            <Notification
              icon={<XIcon size={18} />}
              color="red"
              title="Error"
              onClose={resetQuery}
            >
              Ups! Hubo un error al guardar los datos
            </Notification>
          ) : null}

          {isSuccess || updateContact.isSuccess ? (
            <Notification
              icon={<CheckCircleIcon size={18} />}
              color="teal"
              title="Correcto"
              onClose={resetQuery}
            >
              Se guardarón los datos correctamente
            </Notification>
          ) : null}
        </>
      )}
    </>
  );
}

AddContactModal.propTypes = {};

export default AddContactModal;
