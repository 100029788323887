/* eslint-disable react/no-unknown-property */
import React from 'react';
// import PropTypes from 'prop-types'
import { Disclosure } from '@headlessui/react';
import MenuIcon from '../../../iconComponets/MenuIcon';
import XIcon from '../../../iconComponets/XIcon';
import H1 from '../../../textComponents/H1';

function BurguerMenuVert({ text, open, children }) {
  return (
    <>
      <Disclosure.Button className="w-full flex text-indigo-500 items-center justify-center gap-5 p-2 rounded-md  hover:text-indigo-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500/20 font-medium">
        <div>
          <span className="sr-only">Open main menu</span>
          {open ? <XIcon colorIcon /> : <MenuIcon colorIcon="false" />}
        </div>
        <div className="flex">
          <H1 text={text} />
        </div>
      </Disclosure.Button>

      <Disclosure.Panel className="font-medium">
        <div as="nav" className="flex flex-col gap-3 px-2 pt-2 pb-3 space-y-1">
          {children}
        </div>
      </Disclosure.Panel>
    </>
  );
}

BurguerMenuVert.propTypes = {};

export default BurguerMenuVert;
