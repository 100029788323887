import React from 'react';
import H2 from '../../../../../_desingModels/textComponents/H2';
import TableSales from '../componets/TableSales';
import PrimaryButton from '../../../../../_desingModels/layout/components/Buttons/PrimaryButton';
// import cardStyles from '../../../../../_desingModels/utils/cardStyles';
// import PropTypes from 'prop-types'

function Sales() {
  return (
    <section className="flex flex-col bg-white rounded-xl w-full p-4 items-center justify-center gap-5">
      <H2 text="Ventas" />
      <div className="h-80 m-3 bg-gray-200 w-full">Statistic</div>
      <article className="w-full flex flex-col items-center px-5 gap-5 md:items-end lg:px-0 ">
        <TableSales />
        <PrimaryButton text="Detalle" />
      </article>
    </section>
  );
}

Sales.propTypes = {};

export default Sales;
