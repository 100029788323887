/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  addClient,
  showClients,
  showClient,
  addContact,
  showContactByClient,
  deleteContact,
  addContratista,
  showContratistaByClient,
  deleteContratista,
  addSede,
  showSedeByClient,
  deleteSede,
  updateClient,
  updateContact,
  updateContratista,
  updateSede,
} from '../api/requests';

const key = 'clients';

export const useMutateClient = () => {
  const queryClient = useQueryClient();

  return useMutation(addClient, {
    onSuccess: () => {
      queryClient.invalidateQueries(key);
    },
  });
};

export const useClient = () => useQuery(key, showClients);

export const clientById = (idClient) =>
  useQuery(['client', idClient], () => showClient(idClient), {
    enabled: !!idClient,
  });

export const useMutateContact = () => {
  const queryClient = useQueryClient();

  return useMutation(addContact, {
    onSuccess: () => {
      queryClient.invalidateQueries('contacto');
    },
  });
};

export const useMutateClientUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (idClient) => updateClient(idClient.id, idClient.clientData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('client');
      },
    }
  );
};

export const useContactByClient = (idClient) =>
  useQuery(['contacto', idClient], () => showContactByClient(idClient), {
    enabled: !!idClient,
  });
export const useDeleteContact = () => {
  const queryClient = useQueryClient();
  return useMutation((idContact) => deleteContact(idContact.data), {
    onSuccess: () => {
      queryClient.invalidateQueries('contacto');
    },
  });
};
export const useMutateContactUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (idClient) => updateContact(idClient.id, idClient.clientData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contacto');
      },
    }
  );
};

export const useMutateContratista = () => {
  const queryClient = useQueryClient();

  return useMutation(addContratista, {
    onSuccess: () => {
      queryClient.invalidateQueries('contratista');
    },
  });
};
export const useContratistaByClient = (idClient) =>
  useQuery(['contratista', idClient], () => showContratistaByClient(idClient), {
    enabled: !!idClient,
  });

export const useDeleteContratista = () => {
  const queryClient = useQueryClient();
  return useMutation((idContratista) => deleteContratista(idContratista.data), {
    onSuccess: () => {
      queryClient.invalidateQueries('contratista');
    },
  });
};
export const useMutateContratistaUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (idContr) => updateContratista(idContr.id, idContr.contData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contratista');
      },
    }
  );
};

export const useMutateSede = () => {
  const queryClient = useQueryClient();

  return useMutation(addSede, {
    onSuccess: () => {
      queryClient.invalidateQueries('sede');
    },
  });
};
export const useSedeByClient = (idClient) =>
  useQuery(['sede', idClient], () => showSedeByClient(idClient), {
    enabled: !!idClient,
  });

export const useDeleteSede = () => {
  const queryClient = useQueryClient();
  return useMutation((idSede) => deleteSede(idSede.data), {
    onSuccess: () => {
      queryClient.invalidateQueries('sede');
    },
  });
};
export const useMutateSedeUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation((idSede) => updateSede(idSede.id, idSede.sedeData), {
    onSuccess: () => {
      queryClient.invalidateQueries('sede');
    },
  });
};
