/* eslint-disable no-return-await */
/* eslint-disable import/prefer-default-export */
import axios, { axiosRuc } from '../config/axios';

/* users */
export const login = (data) => axios.post('/auth/login', data);
export const addUser = (data) => axios.post('/auth/register', data);
export const showUsers = (data) => axios.get('/users/show_users', data);
export const showUser = async (id) => {
  const { data } = await axios.get(`/users/show_users_by_usuario/${id}`);
  return data;
};
// updateUser by id
export const updateUser = (id, data) =>
  axios.put(`/users/update_usuario/${id}`, data);

export const showSpecialties = (data) =>
  axios.get('/catalogos/show_especialidades_combo', data);

export const postImage = (data) => axios.post('/examenes/upload-image', data);
export const deleteRoleUser = (data) =>
  axios.post('/roles/quit_rol_user', data);
export const addRoleUser = (data) =>
  axios.post('/roles/set_roles_usuarios', data);
export const updateUserExamPermission = (data) =>
  axios.post('/users/cambiar_permiso', data);
export const deleteUserExam = (data) =>
  axios.post('/users/quit_examen_usuario', data);

/* Clients */
export const addClient = (data) => axios.post('/clients/registerClient', data);
export const showClients = (data) => axios.get('/clients/show_clients', data);
export const showClient = async (id) => {
  const { data } = await axios.get(`/clients/show_client_by_id/${id}`);
  return data;
};
export const updateClient = (id, data) =>
  axios.put(`/clients/update/${id}`, data);

export const addContact = (data) => axios.post('/contacto/store', data);
export const showContactByClient = async (id) => {
  const { data } = await axios.get(`/contacto/showbycliente/${id}`);
  return data;
};
export const deleteContact = async (id) =>
  await axios.put(`/contacto/destroy/${id}`);
export const updateContact = (id, data) =>
  axios.put(`/contacto/update/${id}`, data);

export const addContratista = (data) => axios.post('/contratistas/store', data);
export const showContratistaByClient = async (id) => {
  const { data } = await axios.get(`/contratistas/showByClientes/${id}`);
  return data;
};
export const deleteContratista = async (id) =>
  await axios.put(`/contratistas/destroy/${id}`);
export const updateContratista = (id, data) =>
  axios.put(`/contratistas/update/${id}`, data);

export const addSede = (data) => axios.post('/sedes/store', data);
export const showSedeByClient = async (id) => {
  const { data } = await axios.get(`/sedes/showSedeByCliente/${id}`);
  return data;
};
export const deleteSede = async (id) => await axios.put(`/sedes/destroy/${id}`);
export const updateSede = (id, data) => axios.put(`/sedes/update/${id}`, data);
/* Patients */
export const addPatient = (data) =>
  axios.post('/patients/register_patient', data);
export const showPatients = (data) =>
  axios.get('/patients/show_patients', data);
export const showPacient = async (id) => {
  const { data } = await axios.get(`/patients/showPatientsById/${id}`);
  return data;
};
export const patientImage = (data) =>
  axios.post('/files/create_upload_file', data);

/* Roles */
export const addRole = (data) => axios.post('/roles/register_rol', data);
export const showRoles = (data) => axios.get('/roles/show_roles', data);
export const showRolesCombo = (data) =>
  axios.get('/roles/show_roles_combo', data);
export const editedRoles = (data) => axios.post('/roles/update_rol', data);

/* Modules */
export const showModules = (data) =>
  axios.get('/modulos/show_modules_combo', data);

/* Select activifad tamño, antiguedad */
export const showActividad = (data) =>
  axios.get('/catalogos/actividad/show_actividad_combo', data);
export const showAntiguedad = (data) =>
  axios.get('/catalogos/antiguedad/show_antiguedad_combo', data);
export const showTamanio = (data) =>
  axios.get('/catalogos/tamanio/show_tamanio_combo', data);

/* Select Departamento Provincia Distrito */

export const showDepartamento = (data) =>
  axios.get('/catalogos/departamentos/show_departamentos_combo', data);
export const showProvincia = async (idDepartamento) => {
  const { data } = await axios.get(
    `/catalogos/provincias/show_provincias_combo/${idDepartamento}`
  );
  return data;
};
export const showDistrito = async (idDepartamento, idProvincia) => {
  const { data } = await axios.get(
    `/catalogos/distritos/show_distritos_combo/${idDepartamento}/${idProvincia}`
  );
  return data;
};

/* Select Client Sede Contratista */
export const showComboClient = (data) =>
  axios.get('/clients/show_clients_combo', data);
export const showComboSede = async (idClient) => {
  const { data } = await axios.get(`/sedes/showSedeByClienteCombo/${idClient}`);
  return data;
};
export const showComboContratista = async (idClient) => {
  const { data } = await axios.get(
    `/contratistas/showContratistaByClienteCombo/${idClient}`
  );
  return data;
};

/* Protocols */

export const addProtocol = (data) => axios.post('/protocolos/store', data);

/* Cotizaciones */

export const addQuote = (data) => axios.post('/cotizaciones/store', data);
export const copyQuote = (data) =>
  axios.post('/cotizaciones/copyCotizacion', data);

export const addConfigQuote = (data) =>
  axios.post('/cotizaciones/storeConfiguracion', data);

export const showQuotes = async (data) =>
  axios.get('/cotizaciones/show_full_cotizaciones', data);

export const showQuote = async (id) => {
  const { data } = await axios.get(`/cotizaciones/show_cotizacion/${id}`);
  return data;
};

export const showQuotesJr = async (data) =>
  axios.get('/cotizaciones/show_full_cotizaciones_jr', data);

export const showQuotesSr = async (data) =>
  axios.get('/cotizaciones/show_full_cotizaciones_sr', data);

export const showQuotesPaginadorSr = async (page) => {
  const { data } = await axios.get(
    `/cotizaciones/show_full_cotizaciones_paginador_sr?page=${page}`
  );
  return data;
};

export const updateQuoteExam = (data) =>
  axios.post('/cotizaciones/update_exams_cost_limitante', data);

export const showComboLimit = async (data) =>
  axios.get('/limitantes/show_limitantes_combo', data);

export const deleteQuoteExam = async (data) =>
  axios.post('/cotizaciones/delete_exam', data);

export const addExamQuote = (data) =>
  axios.post('/cotizaciones/add_examen_cotizacion', data);

export const showExamsTree = async (data) =>
  axios.get('/examenes/show_examenes_tree', data);

export const showExtraTree = async (data) =>
  axios.get('/cotizaciones/show_servicios_extras', data);

export const toCheck = (data) =>
  axios.post('/cotizaciones/para_revision', data);

export const getCotiPortada = async (id) => {
  const { data } = await axios.get(`/cotizaciones/getCotizacionPortada/${id}`);
  return data;
};
export const updateCotiPortada = (data) => {
  axios.put('/cotizaciones/updateCotizacionPortada', data);
};

export const sendQuote = (data) =>
  axios.post('/cotizaciones/enviar_cotizacion', data);

export const aproveQuote = (data) =>
  axios.post('/cotizaciones/aprovada_cotizacion', data);

export const rejectQuote = (data) =>
  axios.post('/cotizaciones/rechazada_cotizacion', data);

export const downloadQuote = async (data) =>
  axios.get('/cotizaciones/pdf/generateCotizacion', data);

export const generateToken = async (data) =>
  axios.post('/cotizaciones/generate_acces_token', data);

export const activateToken = (data) =>
  axios.post('/cotizaciones/activar_acces_token', data);

export const validateToken = (data) =>
  axios.post('/cotizaciones/validar_token', data);

export const minPrice = async (data) =>
  await axios.post('/cotizaciones/get_min_exam_price', data);

export const getCotiPred = async (data) =>
  axios.get('/cotizaciones/getCotiPred', data);

export const getCotiPredExam = async (id) => {
  const { data } = await axios.get(`/cotizaciones/getCotiPredExam/${id}`);
  return data;
};

export const getShowProfiles = async (data) =>
  axios.get('/catalogos/show_perfiles_examen', data);

export const getShowEvaluacion = async (data) =>
  axios.get('/catalogos/show_tipos_valuaciones', data);

export const getConfigQuotes = async (id) => {
  const { data } = await axios.get(
    `/cotizaciones/get_config_by_id_cotizacion/${id}`
  );
  return data;
};
export const updateConfig = (data) =>
  axios.post('/cotizaciones/update_configuracion_cotizacion', data);

/* Catalogos */

export const getAllExam = async (data) =>
  axios.get('/examenes/show_examenes', data);
export const getAllExtras = async (data) =>
  axios.get('/serviciosExtra/getServiviosExtra', data);
export const addExtraExam = (data) => axios.post('/serviciosExtra/store', data);
export const updateExtraExam = (data) =>
  axios.put('/serviciosExtra/update', data);
export const deleteExtraExam = async (data) =>
  axios.post('/serviciosExtra/destroy', data);
export const updateExam = (data) => axios.post('/examenes/updateExamen', data);
export const deleteExam = async (data) =>
  axios.post('/examenes/deleteExamen', data);

/* Exams */
export const showExams = async (data) =>
  axios.get('/examenes/show_examenes_combo', data);

export const addExam = (data) =>
  axios.post('/users/register_usuario_examen', data);

export const showExamsUser = async (idUser) => {
  const { data } = await axios.get(
    `/users/show_users_examenes_by_usuario/${idUser}`
  );
  return data;
};
export const showExamsCombo = async (data) =>
  axios.get('/examenes/show_tipo_exam_combo', data);

export const showGroupExam = async (data) =>
  axios.get('/examenes/show_examen_grupos_combo', data);

export const addGroup = (data) =>
  axios.post('/examenes/create_grupo_examen', data);

export const createExam = (data) => axios.post('/examenes/create_examen', data);

export const createResponse = (data) =>
  axios.post('/respuestas/create_respuestas', data);

export const showExam = async (idExam) => {
  const { data } = await axios.get(`/examenes/get_exam_template/${idExam}`);
  return data;
};
export const showRespuesta = async (idExam, idPaciente) => {
  const { data } = await axios.get(
    `/respuestas/get_exam_respuestas/${idPaciente}/${idExam}/1`
  );
  return data;
};

export const showExamsTemplate = async (data) =>
  axios.get('/examenes/show_examenes_combo_no_pathern', data);

// permisos
export const showPermissions = async (data) =>
  axios.get('/catalogos/show_permisos_combo', data);

// get ruc from api
export const showRuc = async (rucNumber) => {
  axiosRuc.get(`ruc?numero=${rucNumber}`);
};
