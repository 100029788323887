/* eslint-disable no-unused-vars */
import React from 'react';

// import PropTypes from 'prop-types'
import { Notification } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '../../../../_desingModels/layout/components/form/Input/Input';
import ErrorMessage from '../../../../_desingModels/layout/components/form/Message/Message';
import CheckCircleIcon from '../../../../_desingModels/iconComponets/CheckCircle';
import XIcon from '../../../../_desingModels/iconComponets/XIcon';
// import InputSend from '../../../../_desingModels/layout/components/form/inputSend/InputSend';
// import Select from '../../../../_desingModels/layout/components/select/Select';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
// import Line from '../../../../_desingModels/layout/components/Line';

import { useMutateRole } from '../../../../hooks/roles';
import H2 from '../../../../_desingModels/textComponents/H2';
import MultiSelectComponent from '../../../../_desingModels/layout/components/select/MultiSelect';

function ModalForm({ modulesName = [], setOpened }) {
  const dataSchema = yup.object().shape({
    rol: yup.string().required('*obl').max(50).min(3),
    arrayModulo: yup.array().min(1).required('*obl'),
  });
  const {
    control,
    register,
    handleSubmit,
    // setValue,
    reset,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(dataSchema),
  });
  const {
    mutate,
    isLoading,
    isSuccess,
    isError,
    reset: resetQuery,
  } = useMutateRole();

  const onSubmit = (data) => {
    // console.log(data);
    mutate(data);
    reset();
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pb-5 w-full flex flex-col gap-5 mt-10 justify-center items-center"
      >
        <div className="w-full lg:px-10">
          <Input
            textLabel="Nombre"
            type="text"
            placeholder="Nombre de rol"
            registerValue={register('rol', { required: true })}
            modal
          />
          {errors.rol && <ErrorMessage xs message={errors.rol.message} />}
        </div>

        <div className="w-full lg:px-10">
          <H2 text="Módulos" blue />
          {modulesName.length > 0 && (
            <Controller
              control={control}
              name="arrayModulo"
              render={({ field }) => (
                <MultiSelectComponent
                  placeholder="Buscar Módulo"
                  data={modulesName}
                  onChange={(value) => field.onChange(value)}
                  selected={field.value}
                />
              )}
            />
          )}
          {modulesName.length > 0 ? (
            errors.arrayModulo && (
              <ErrorMessage xs message={errors.arrayModulo.message} />
            )
          ) : (
            <ErrorMessage
              xs
              message="No hay módulos disponibles para este rol, contactar con soporte"
            />
          )}
        </div>

        <div className="pt-8 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
          <PrimaryButton type="input" text="Guardar" blue />
          <PrimaryButton
            text="Cancelar"
            modal
            handleClick={() => setOpened(false)}
          />
        </div>
      </form>
      {isLoading ? (
        <Notification
          loading
          title="Uploading data to the server"
          disallowClose
        >
          Espere hasta que se carguen los datos, no puede cerrar esta
          notificación aún
        </Notification>
      ) : (
        <>
          {isError ? (
            <Notification
              icon={<XIcon size={18} />}
              color="red"
              title="Error"
              onClose={resetQuery}
            >
              Ups! Hubo un error al guardar los datos
            </Notification>
          ) : null}

          {isSuccess ? (
            <Notification
              icon={<CheckCircleIcon size={18} />}
              color="teal"
              title="Correcto"
              onClose={resetQuery}
            >
              Se guardarón los datos correctamente
            </Notification>
          ) : null}
        </>
      )}
    </>
  );
}

ModalForm.propTypes = {};

export default ModalForm;
