/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useStateData } from '../../../../context/dataContext';
import Wraper from '../../../layaouts/wrapper/Wrapper';
import H2 from '../../../../_desingModels/textComponents/H2';
import P from '../../../../_desingModels/textComponents/P';
import Card from '../../../../_desingModels/layout/components/card/Card';
import H4 from '../../../../_desingModels/textComponents/H4';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import classNames from '../../../../_desingModels/utils/classNames';
import Select from '../../../../_desingModels/layout/components/select/Select';
import PlusIconMobile from '../../../../_desingModels/iconComponets/PlusIcon';
import H3 from '../../../../_desingModels/textComponents/H3';
import ModalWithBtn from '../../../layaouts/modal/ModalWithBtn';
import ModalAddExam from './ModalAddExam';

const option2 = [
  { name: 'Por Examén', id: 1 },
  { name: 'Preocupacional', id: 2 },
  { name: 'Periódico', id: 3 },
  { name: 'Preventivo', id: 4 },
  { name: 'Retiro', id: 5 },
  { name: 'Reubicación', id: 6 },
  { name: 'Otros', id: 7 },
  { name: 'Visita', id: 8 },
  { name: 'Reincorporación laboral', id: 9 },
];

const option3 = [
  { name: 'Aptitud', id: 1 },
  { name: 'Apto', id: 2 },
  { name: 'No apto', id: 3 },
  { name: 'Observado', id: 4 },
  { name: 'Apto con restricción', id: 5 },
  { name: 'Evaluado', id: 6 },
  { name: 'Pendiente', id: 7 },
];
function DetailProtocol({ protocol }) {
  // console.log('cotiza', quote);
  const [modalExam, setModalExam] = useState(false);
  const { examData, setExamData } = useStateData();
  const handleClickAddExam = (event) => {
    event.preventDefault();
    setModalExam(true);
    console.log('añadiendo');
  };
  const handleClickDelete = (item) => {
    const deleted = examData.filter((exam) => exam !== item);
    setExamData(deleted);
  };
  return (
    <Wraper>
      <div className="flex w-full flex-col lg:flex-row gap-3">
        <section className="p-4 md:w-1/4 w-full flex flex-col">
          <H2 text="Costo" />
          <p className="font-bold">Costo Total</p>
          <P text="40 Soles" />
          <p className="font-bold">Sub Total</p>
          <P text="100 soles" />
          <p className="font-bold">Total</p>
          <P text="118 soles" />
        </section>
        <section className="w-full h-full md:w-3/4 flex flex-col">
          <header className="flex flex-col w-full justify-center items-center p-4 gap-2 md:flex-row md:items-start lg:justify-start">
            <div className="w-full flex flex-col gap-2 lg:flex-row">
              <H2 text="Nombre Protocolo" />
              <div className="md:h-fit">
                <PrimaryButton text="Editar" blue />
              </div>

              <Select itemArray={option2} />
              <Select itemArray={option3} />
            </div>
          </header>
          <div className="w-full p-4 flex flex-wrap gap-2">
            {examData.map((e, index) => (
              <Card
                key={index}
                classNames={classNames(
                  'flex flex-col border-2 rounded-md w-56 border-azul2'
                )}
              >
                <section>
                  <div className="text-gray-800 p-4 flex flex-col">
                    <H4 text={e} blue />
                    <div className="p-1" />
                    <p className="font-bold">Precio mínimo</p>
                    <p className="font-bold">20 soles</p>
                  </div>
                  <div className="p-2 flex justify-center">
                    <PrimaryButton
                      handleClick={() => handleClickDelete(e)}
                      text="Eliminar"
                      delet
                    />
                  </div>
                </section>
              </Card>
            ))}
            <Card
              classNames={classNames(
                'flex flex-col rounded-md w-56 border-azul2'
              )}
            >
              <section className="w-full h-full p-2 rounded-md bg-azul3 flex flex-col justify-center items-center">
                <div
                  role="button"
                  tabIndex={0}
                  onClick={handleClickAddExam}
                  className="bg-indigo-500 rounded-full flex justify-center items-center w-12 h-12 mb-1"
                >
                  <PlusIconMobile />
                </div>
                <H3 blue text="Agregar" />
                <ModalWithBtn
                  opened={modalExam}
                  setOpened={setModalExam}
                  title="Agregar Exámen"
                >
                  <ModalAddExam setOpened={setModalExam} />
                </ModalWithBtn>
              </section>
            </Card>
          </div>
        </section>
      </div>
    </Wraper>
  );
}

DetailProtocol.propTypes = {};

export default DetailProtocol;
