/* eslint-disable no-nested-ternary */
import React from 'react';
// import PropTypes from 'prop-types'
import { Tooltip } from '@mantine/core';
import Card from '../../../_desingModels/layout/components/card/Card';
import HeaderCard from '../../../_desingModels/layout/components/card/HeaderCard';
import H3 from '../../../_desingModels/textComponents/H3';
import P from '../../../_desingModels/textComponents/P';
import H4 from '../../../_desingModels/textComponents/H4';
import H5 from '../../../_desingModels/textComponents/H5';
import CardIcon from '../../../_desingModels/utils/CardIcon';
import classNames from '../../../_desingModels/utils/classNames';

function PatientCard({ patient, moduleType, handleClickDetailPatient }) {
  return (
    <Card
      classNames={classNames(
        'flex flex-col border-2 rounded-md w-56',
        patient?.state === 'Ausente' && 'border-negro2',
        patient?.state === 'Completo' || patient?.state === 'Aceptada'
          ? 'border-verde1'
          : 'border-indigo-500',
        patient?.state === 'Observado' && 'border-orange-700/80',
        patient?.state === 'Atendiendose' || patient?.state === 'NoAceptada'
          ? 'border-indigo-500'
          : 'border-indigo-500'
      )}
    >
      <HeaderCard
        classNames={classNames(
          'text-blanc1 p-2',
          patient?.state === 'Ausente' && 'bg-negro2',
          patient?.state === 'Completo' && 'bg-verde1',
          patient?.state === 'Observado' && 'bg-orange-700/80',
          patient?.state === 'Atendiendose' && 'bg-indigo-500',
          patient?.state === 'Aceptada' ? 'bg-verde1' : 'bg-indigo-500',
          patient?.state === 'NoAceptada' ? 'bg-indigo-500' : 'bg-indigo-500'
        )}
      >
        <div className="flex justify-between">
          <P text={patient.folio ? patient.folio : '123-abcd'} />
          <Tooltip
            label={patient.state ? patient.state : 'Atendiendose'}
            color="dark"
            transition="fade"
          >
            <CardIcon state={patient.state ? patient.state : 'Atendiendose'} />
          </Tooltip>
        </div>
        <div className="flex flex-col">
          <H3
            text={`${patient?.apellidoPaterno} ${patient?.apellidoMaterno}`}
          />
          <H3 text={patient?.nombres} />
        </div>
      </HeaderCard>

      <section>
        <div className="flex flex-col text-gray-800 p-2 ">
          {/* <H4 text={patient?.protocolo} />
          <P text={patient?.sexo} />
          <P text={patient?.numeroDoc} />
          <P text={patient?.empresa} /> */}
          <H4 text="Nombre Protocolo" />
          <P text="Sexo" />
          <P text={patient?.numeroDoc} />
          <P text="Casagrande SAC" />
          {moduleType === 'patient' && (
            <div className="pt-2 ">
              <H5 text="(6/10)" colorText="colorGray800" />
              <H5 text="En evaluación" colorText="colorGray800" />
            </div>
          )}
        </div>
        <div
          className={classNames(
            'flex p-2',
            moduleType === 'patient' ? 'justify-around' : 'justify-end'
          )}
        >
          {moduleType === 'patient' && (
            <button
              className={classNames(
                'text-white py-1 px-2 rounded-md ',
                patient?.state === 'Ausente' &&
                  'bg-neutral-500/80 hover:bg-neutral-500',
                patient?.state === 'Completo' && 'bg-verde1 hover:bg-teal-700',
                patient?.state === 'Observado' &&
                  'bg-orange-700/80 hover:bg-orange-700',
                patient?.state === 'Atendiendose'
                  ? 'bg-indigo-500 hover:bg-indigo-700'
                  : 'bg-indigo-500 hover:bg-indigo-700'
              )}
              type="button"
            >
              Resultados
            </button>
          )}
          <button
            onClick={(e) => {
              handleClickDetailPatient(e, patient);
            }}
            className={classNames(
              'text-white py-1 px-2 rounded-md w-5/12',
              patient?.state === 'Ausente' &&
                'bg-neutral-500/80 hover:bg-neutral-500',
              patient?.state === 'Completo' && 'bg-verde1 hover:bg-teal-700',
              patient?.state === 'Observado' &&
                'bg-orange-700/80 hover:bg-orange-700',
              patient?.state === 'Atendiendose'
                ? 'bg-indigo-500 hover:bg-indigo-700'
                : 'bg-indigo-500 hover:bg-indigo-700'
            )}
            type="button"
          >
            {moduleType === 'patient'
              ? patient?.state === 'Atendiendose'
                ? 'Atender'
                : 'Ver'
              : patient?.state === 'Ausente'
              ? 'Atender'
              : 'Ver'}
          </button>
        </div>
      </section>
    </Card>
  );
}

// PatientCard.propTypes = {}

export default PatientCard;
