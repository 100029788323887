/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Listbox } from '@headlessui/react';
import { SelectIcon, CheckSelect } from '../../../iconComponets/SelectIcon';
import classNames from '../../../utils/classNames';
import { useStateData } from '../../../../context/dataContext';

function Select({
  itemArray = [],
  modal,
  setValue,
  registerValue,
  idSelect,
  isSelectP,
  isSelect,
  selectBd,
  defaultValue,
  handleChange,
  defValue,
}) {
  const [selected, setSelected] = useState(itemArray[0]);

  const {
    setProvincia,
    setDepartamento,
    setDepartamentoDomi,
    setProvinciaDomi,
    setCliente,
  } = useStateData();

  const handleChangeOption = (e) => {
    handleChange(e);
    setSelected(e);
  };
  const handleChangeSelect = (e) => {
    setValue(idSelect, e.target.value, { shouldValidate: true });
    // console.log('idSelect', idSelect);
    if (idSelect === 'departamento') {
      setDepartamento(e.target.value);
    }
    if (idSelect === 'provincia') {
      setProvincia(e.target.value);
    }
    if (idSelect === 'departamentoDomi') {
      setDepartamentoDomi(e.target.value);
    }
    if (idSelect === 'provinciaDomi') {
      setProvinciaDomi(e.target.value);
    }
    if (idSelect === 'empresa') {
      setCliente(e.target.value);
    }
  };

  if (selectBd) {
    if (
      idSelect === 'provincia' ||
      idSelect === 'distrito' ||
      idSelect === 'departamento' ||
      idSelect === 'provinciaDomi' ||
      idSelect === 'distritoDomi' ||
      idSelect === 'departamentoDomi' ||
      idSelect === 'empresa' ||
      idSelect === 'sede' ||
      idSelect === 'contratista'
    ) {
      return (
        <div className="relative ">
          <select
            {...registerValue}
            onChange={handleChangeSelect}
            className={classNames(
              'md:w-[200px] truncate font-poppins text-purpleT font-bold rounded-md border border-purpleT pr-6 text-sm flex'
            )}
          >
            <option value={defaultValue && 0}>{defaultValue}</option>
            {itemArray?.map((e, index) => (
              <option key={index} value={e?.value} className="">
                {e?.label}
              </option>
            ))}
          </select>
        </div>
      );
    }
    return itemArray.length === 0 ? null : (
      <div className="relative ">
        <select
          {...registerValue}
          onChange={handleChangeSelect}
          className={classNames(
            'w-40 lg:w-fit truncate font-poppins text-purpleT font-bold rounded-md border border-purpleT pr-6 text-sm flex'
          )}
          defaultValue={defValue}
        >
          <option value={defaultValue && 0}>{defaultValue}</option>
          {itemArray?.map((e, index) => (
            <option key={index} value={e?.value} className="">
              {e?.label}
            </option>
          ))}
        </select>
      </div>
    );
  }
  if (isSelect) {
    return (
      <div className="relative ">
        <select
          {...registerValue}
          onChange={
            ((e) =>
              setValue(idSelect, e.target.value, { shouldValidate: true }),
            handleChangeOption)
          }
          className={classNames(
            'w-40 truncate font-poppins text-purpleT font-bold rounded-md border border-purpleT pr-6 text-sm flex'
          )}
        >
          {itemArray?.map((e, index) => (
            <option key={index} value={e.id} className="">
              {e.name}
            </option>
          ))}
        </select>
      </div>
    );
  }
  if (isSelectP) {
    return (
      <div className="relative ">
        <select
          onChange={
            ((e) =>
              setValue(idSelect, e.target.value, { shouldValidate: true }),
            handleChangeOption)
          }
          className={classNames(
            'w-40 truncate font-poppins text-purpleT font-bold rounded-md border border-purpleT pr-6 text-sm flex'
          )}
        >
          <option value="0" className="">
            Seleccione
          </option>
          {itemArray?.map((e, index) => (
            <option key={index} value={e.value} className="">
              {e.label}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <div className={classNames('relative w-full md:w-fit appearance-none')}>
      <Listbox value={selected} onChange={handleChangeOption}>
        <Listbox.Button
          className={classNames(
            'max-w-xs font-poppins w-full flex justify-center items-center p-2 font-bold rounded-md md:h-fit md:text-sm md:w-fit ',
            modal
              ? 'text-purpleT bg-white hover:bg-purpleTH hover:text-white border border-purpleT'
              : 'bg-purpleT text-white hover:bg-purpleTH'
          )}
        >
          <span className="truncate">{selected.name}</span>
          <SelectIcon modal={modal} />
        </Listbox.Button>
        <Listbox.Options className="max-h-72 overflow-y-auto font-poppins w-full absolute z-10 bg-white md:text-sm border border-purpleTHT mt-1 text-purpleT font-semibold md:w-fit">
          {itemArray?.map((item, index) => (
            <Listbox.Option
              key={index}
              value={item}
              disabled={item.unavailable}
              className="font-poppins flex pr-2 cursor-pointer hover:bg-purpleTHT"
            >
              {({ selected }) => (
                <>
                  {selected && <CheckSelect />}
                  <span className={`truncate ${selected ? '' : 'pl-6'}`}>
                    {item.name}
                  </span>
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
}

Select.propTypes = {
  itemArray: PropTypes.array,
};
Select.defaultProps = {
  itemArray: [],
};

export default Select;
