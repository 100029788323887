/* eslint-disable react/no-array-index-key */
import React from 'react';
// import PropTypes from 'prop-types'
import Td from '../../../../../_desingModels/layout/components/table/Td';
import TableContainer from '../../../../../_desingModels/layout/components/table/TableContainer';
import TBody from '../../../../../_desingModels/layout/components/table/TBody';
// import Tr from '../../../../../_desingModels/layout/components/table/Tr';

function TableVentas() {
  return (
    <TableContainer>
      {[1, 2, 3, 4].map((i, index) => (
        <TBody key={index}>
          <tr className="flex flex-col gap-2 justify-center items-center md:flex-row md:gap-3">
            <Td td="td3" mainText="1/5/2022" />
            <Td td="td1" mainText="MARSA SAC" secondaryText="ABC-123" />
            <Td
              td="td2"
              mainText="1234567890124"
              secondaryText="Empresa principal"
            />
            <Td td="td1" mainText="15 Atendidos" secondaryText="3 Protocolos" />
            <Td td="td4" mainText="Efectivo" />
            <Td td="td5" mainText="Contado" />

            <Td td="td6" mainText="Importe" secondaryText="S/. 2,000" />
          </tr>
        </TBody>
      ))}
    </TableContainer>
  );
}

TableVentas.propTypes = {};

export default TableVentas;
