/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import { Notification } from '@mantine/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { useStateData } from '../../../../context/dataContext';
import {
  useMutateConfigQuote,
  useMutateQuote,
  useGetShowProfile,
  useGetShowEva,
  useExtraTree,
} from '../../../../hooks/quote';
import { useShowComboClient } from '../../../../hooks/selects';
import CheckCircleIcon from '../../../../_desingModels/iconComponets/CheckCircle';
import XIcon from '../../../../_desingModels/iconComponets/XIcon';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import Input from '../../../../_desingModels/layout/components/form/Input/Input';
import TextArea from '../../../../_desingModels/layout/components/form/textArea/TextArea';
import Radio from '../../../../_desingModels/layout/components/radio/Radio';
import Checkbox from '../../../../_desingModels/layout/components/select/Checkbox';
import SelectSearch from '../../../../_desingModels/layout/components/select/SelectSearch';
import './addQuote.css';
import P from '../../../../_desingModels/textComponents/P';
import SelectDefault from '../../../../_desingModels/layout/components/select/SelectDefault';
import TreeCheckSearch from '../../../../_desingModels/layout/components/select/TreeCheckSearch';
import TreeCheckGeneric from '../../../../_desingModels/layout/components/select/TreeCheckGeneric';
import Select from '../../../../_desingModels/layout/components/select/Select';

const estado = [{ value: 1, label: 'Creada' }];

function InputContainers({ children }) {
  return (
    <div className="lg:px-10 w-full flex flex-col gap-5 md:flex-row md:gap-2">
      {children}
    </div>
  );
}

const eliminarObjetosVacios = (examTree) =>
  examTree
    .map((item) => ({
      ...item,
      children: item.children.filter(
        (child) => child.value !== '' || child.label !== ''
      ),
    }))
    .filter((item) => item.children.length > 0);

const convertirCadenasANumeros = (arr) =>
  arr.map((numero) => parseInt(numero, 10));

const modalidad = [
  {
    value: 1,
    label: 'Examen médico ocupacional',
  },
  {
    value: 2,
    label: 'Cotización de Vigilancia',
  },
];

function ModalAddQuote({ setOpened, examTree }) {
  const { register, reset, control, handleSubmit, setValue } = useForm();
  const secondForm = useForm();
  const { examSecondData, setChecked, setCheckedSecond } = useStateData();
  const [examData, setExamData] = useState([]);
  const [extraData, setExtraData] = useState([]);
  const [extra, setExtra] = useState(false);
  const [nameQuote, setNameQuote] = useState('');
  const [idQuote, setIdQuote] = useState();
  const [idQuoteExam, setIdQuoteExam] = useState([]);
  const [responseExam, setResponseExam] = useState([]);
  const [showConfig, setShowConfig] = useState(false);
  const [tableQuoteConfig, setTableQuoteConfig] = useState([]);
  const [selectedValue, setSelectedValue] = useState(0);
  const comboClient = useShowComboClient();
  const userData = JSON.parse(window.localStorage.getItem('user'));
  const nowDate = moment(new Date().toDateString()).format('YYYYMMDD');
  const {
    mutateAsync,
    isLoading,
    isSuccess,
    isError,
    reset: resetQuery,
  } = useMutateQuote();
  const addConfigQuote = useMutateConfigQuote();
  const showProfile = useGetShowProfile();
  const showEvaluacion = useGetShowEva();
  const getExtraExam = useExtraTree();

  const handleSelectChange = (event) => {
    setSelectedValue(parseInt(event.target.value, 10));
  };

  const handleCheckedChange = (checked) => {
    setExamData(checked);
  };

  const handleCheckedExtra = (checkedExtra) => {
    setExtraData(checkedExtra);
  };

  useEffect(() => {
    const nickUser = `${userData.user.name.charAt(
      0
    )}${userData.user.apellidos.charAt(0)}-${nowDate}-ADMIN`;
    setNameQuote(nickUser);
  }, []);

  const onSubmit = async (dataForm) => {
    let newData = {};

    if (selectedValue === 1) {
      if (extra) {
        newData = {
          ...dataForm,
          cotizacion: nameQuote,
          fecha: moment(new Date().toDateString()).format('YYYY/MM/DD'),
          expertis_captura: userData?.user?.id_nivel_espertis,
          id_modalidad: selectedValue,
          exam: examData,
          array_servicios_extras: extraData,
        }
      } else {
        newData = {
          ...dataForm,
          cotizacion: nameQuote,
          fecha: moment(new Date().toDateString()).format('YYYY/MM/DD'),
          expertis_captura: userData?.user?.id_nivel_espertis,
          id_modalidad: selectedValue,
          exam: examData,
          array_servicios_extras: [],
        }
      }
    } else if (selectedValue === 2) {
      if (extra) {
        newData = {
          ...dataForm,
          cotizacion: nameQuote,
          fecha: moment(new Date().toDateString()).format('YYYY/MM/DD'),
          expertis_captura: userData?.user?.id_nivel_espertis,
          id_modalidad: selectedValue,
          array_servicios_extras: extraData,
        }
      } else {
        newData = {
          ...dataForm,
          cotizacion: nameQuote,
          fecha: moment(new Date().toDateString()).format('YYYY/MM/DD'),
          expertis_captura: userData?.user?.id_nivel_espertis,
          id_modalidad: selectedValue,
        }
      }
    }

    try {
      const submitData = await mutateAsync(newData);
      const examTreeResponse = submitData.data.examenes;
      if (selectedValue === 1) {
        setResponseExam(eliminarObjetosVacios(examTreeResponse));
        setIdQuote(submitData.data.cotizacion.id);
        setIdQuoteExam(submitData.data.arrayExamenes);
        setShowConfig(true);
      } else {
        setShowConfig(false);
      }
      setChecked([]);
    } catch (error) {
      // console.log(error);
    }
  };
  const onSubmitSecond = async (dataConfig) => {
    const examSi = convertirCadenasANumeros(examSecondData);
    const examNo = idQuoteExam.filter((item) => !examSi.includes(item));
    const newData = {
      ...dataConfig,
      id_examen_si: examSi,
      id_examen_no: examNo,
      id_cotizacion: idQuote,
    };
    try {
      const tableQuote = await addConfigQuote.mutateAsync(newData);
      setTableQuoteConfig(tableQuote.data.configuracion);
      setChecked([]);
      setCheckedSecond([]);
    } catch (error) {
      // console.log(error?.response?.data);
    }
  };
  const onClickDownloadQuote = () => {
    axios({
      url: `${process.env.REACT_APP_BACKEND_URL}/cotizaciones/pdf/generateCotizacion/${idQuote}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf');
      document.body.appendChild(link);
      link.click();
    });
  };
  const onClickExtra = (event) => {
    setExtra(event.target.checked);
  };
  return (
    <>
      <form
        id="firstForm"
        onSubmit={handleSubmit(onSubmit)}
        className={`${
          isSuccess && selectedValue === 1 ? 'hidden' : 'pb-5 w-full flex flex-col gap-5 mt-10'
        }`}
      >
        <InputContainers>
          <div className="flex flex-col gap-1 font-bold text-purpleT w-1/4">
            Empresa
            <Controller
              control={control}
              name="empresa"
              render={({ field }) => (
                <SelectSearch
                  dataClient={comboClient?.data?.data?.sede_combo}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </div>
          <div className="w-1/4">
            <Input
              modal
              inputUseState
              textLabel="Fecha"
              type="text"
              defaultValue={moment(new Date().toDateString()).format(
                'YYYY/MM/DD'
              )}
              disabled
            />
          </div>
          <div className="w-1/4">
            <Input
              textLabel="N° Pacientes"
              type="number"
              placeholder="0"
              registerValue={register('no_pacientes', { required: true })}
              modal
            />
          </div>
          <div className="w-1/4 flex flex-col gap-2 font-bold text-purpleT">
            Estado
            <div className="flex flex-col items-start">
              {estado.map((e) => (
                <Radio
                  key={e.value}
                  modal
                  checked
                  name={e.label}
                  htmlFor={e.label}
                  textLabel={e.label}
                  value={e.value}
                  registerValue={register('estado', {
                    required: true,
                  })}
                />
              ))}
            </div>
          </div>
        </InputContainers>
        <InputContainers>
          <div className="w-full md:w-1/3">
            <Input
              modal
              inputUseState
              textLabel="Cotización"
              type="text"
              value={nameQuote}
              disabled
            />
          </div>
          <article className="flex gap-4 w-2/3 items-center ">
            <div className="w-full">
              <TextArea
                modal
                textLabel=""
                placeholder="Comentarios"
                registerValue={register('comentario', {
                  required: true,
                })}
              />
            </div>
          </article>
        </InputContainers>
        <InputContainers>
          <div className="w-full md:w-1/3">
            <Input
              modal
              textLabel="Nombre"
              type="text"
              registerValue={register('nombre', {
                required: true,
              })}
            />
          </div>
          <div className="w-full md:w-1/3">
            <Input
              modal
              textLabel="Sub Nombre"
              type="text"
              registerValue={register('subnombre', {
                required: true,
              })}
            />
          </div>
          <div className="w-full flex flex-col justify-between md:w-1/3">
            <p className="text-base font-bold truncate text-purpleT">
              Tipo de cotización
            </p>
            <select
              value={selectedValue}
              onChange={handleSelectChange}
              className="p-2 border rounded"
            >
              <option value={0}>Seleccionar...</option>
              {modalidad.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </InputContainers>
        {selectedValue === 1 ? (
          <section className="lg:px-10 w-full flex flex-col gap-2">
            <P blue modal text="Selección de examenes" />
            <TreeCheckSearch
              id="firstFormTree"
              tree={examTree}
              checkSi={handleCheckedChange}
            />
          </section>
        ) : null}
        {selectedValue === 1 || selectedValue === 2 ? (
          <section className="lg:px-10 w-full flex flex-col gap-2">
            <section className='w-full'>
              <label
                className="w-fit cursor-pointer flex gap-2 items-center text-sm font-poppins text-purpleT"
                htmlFor="cbox1"
              >
                <input
                  className="rounded-md"
                  type="checkbox"
                  id="cbox1"
                  value="servicioExtra"
                  checked={extra}
                  onChange={onClickExtra}
                />
                Añadir servicios extras?
              </label>
            </section>
            <section
              className={`${
                extra ? null : 'hidden'
              } w-full flex flex-col gap-2 p-2 rounded-md border border-purpleT`}
            >
              <P modal text="Selección de Servicios extras" />
              {getExtraExam.isLoading ? (
                <div>Cargando ...</div>
              ) : getExtraExam.isError ? (
                <div>No hay datos</div>
              ) : getExtraExam.isSuccess ? (
                <TreeCheckGeneric
                  id="secondFormTree"
                  tree={getExtraExam?.data?.data[0]}
                  checkSi={handleCheckedExtra}
                />
              ) : null}
            </section>
          </section>
        ) : null}
        <div className="mt-4 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
          <PrimaryButton
            disabled={!!(selectedValue === 0)}
            type="input"
            text="Guardar"
            blue
          />
          <PrimaryButton
            text={isSuccess ? 'Cerrar' : 'Cancelar'}
            modal
            handleClick={() => setOpened(false)}
          />
        </div>
      </form>
      {isLoading ? (
        <Notification loading title="Cargando datos" disallowClose>
          Espere hasta que se carguen los datos, no puede cerrar esta
          notificación aún
        </Notification>
      ) : (
        <>
          {isError ? (
            <Notification
              icon={<XIcon size={18} />}
              color="red"
              title="Error"
              onClose={resetQuery}
            >
              Ups! Hubo un error al guardar los datos
            </Notification>
          ) : null}

          {isSuccess ? (
            <Notification
              icon={<CheckCircleIcon size={18} />}
              color="teal"
              title="Se actualizo correctamente"
              onClose={resetQuery}
            >
              Se guardarón los datos correctamente
            </Notification>
          ) : null}
        </>
      )}
      <form
        id="secondForm"
        onSubmit={secondForm.handleSubmit(onSubmitSecond)}
        className={`${showConfig ? null : 'hidden'} pb-5 w-full flex flex-col`}
      >
        <section className="flex flex-col gap-5 mt-2 md:flex-row">
          <section className="w-full md:w-1/2">
            <div className="lg:pl-10 w-full flex flex-col gap-5 md:flex-row md:gap-2">
              <div className="w-full md:w-1/2">
                <div className="flex flex-col gap-1 font-bold text-purpleT w-full">
                  Perfil
                  <SelectDefault
                    itemArray={showProfile?.data?.data?.perfiles_examenes}
                    setValue={secondForm.setValue}
                    registerValue={secondForm.register(`id_perfil`, {
                      required: true,
                    })}
                    idSelect="id_perfil"
                    defaultValue={[{ label: 'Elegir una opcion', value: 0 }]}
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2">
                <div className="flex flex-col gap-1 font-bold text-purpleT w-full">
                  Tipo de evaluación
                  <SelectDefault
                    itemArray={showEvaluacion?.data?.data}
                    setValue={secondForm.setValue}
                    registerValue={secondForm.register(`id_tipo_valuacion`, {
                      required: true,
                    })}
                    idSelect="id_tipo_valuacion"
                    defaultValue={[{ label: 'Elegir una opcion', value: 0 }]}
                  />
                </div>
              </div>
            </div>
            <div className="lg:pl-10 mt-2 w-full flex flex-col gap-5 md:flex-row md:gap-2 max-h-[250px] overflow-y-auto">
              <table className="w-full table-fixed border-collapse border border-purpleT">
                <thead className="sticky top-0 bg-white">
                  <tr>
                    <th className="border border-purpleT w-[25%]">Perfil</th>
                    <th className="border border-purpleT w-[25%]">Tipo</th>
                    <th className="border border-purpleT w-[32%]">Examen</th>
                    <th className="border border-purpleT w-[18%]">Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {tableQuoteConfig.map((quote, id) => (
                    <tr key={id}>
                      <td className="pl-1 border border-purpleT">
                        {quote.perfil}
                      </td>
                      <td className="pl-1 border border-purpleT">
                        {quote.categoria}
                      </td>
                      <td className="pl-1 border border-purpleT">
                        {quote.examen}
                      </td>
                      <td className="border border-purpleT text-center">
                        {quote.is_activo === 1 ? '✔️' : '❌'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
          <div className="w-1/2">
            <div className="lg:pr-10 w-full flex flex-col gap-5 md:flex-row md:gap-2">
              <div className="w-full flex flex-col gap-2">
                <P modal text="Selección de examenes" />
                <Checkbox
                  key="secondFormTree"
                  secondModalProtocol
                  modalProtocol
                  tree={responseExam}
                />
              </div>
            </div>
          </div>
        </section>
        <div className="mt-4 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
          <PrimaryButton type="input" text="Guardar configuración" blue />

          {tableQuoteConfig.length > 0 ? (
            <PrimaryButton
              text="Imprimir"
              modal
              handleClick={() => onClickDownloadQuote()}
            />
          ) : null}

          <PrimaryButton
            text="Cerrar"
            modal
            handleClick={() => {
              setOpened(false);
              setTableQuoteConfig([]);
            }}
          />
        </div>
      </form>
      {addConfigQuote.isLoading ? (
        <Notification loading title="Guardando datos" disallowClose>
          Espere hasta que se carguen los datos, no puede cerrar esta
          notificación aún
        </Notification>
      ) : (
        <>
          {addConfigQuote.isError ? (
            <Notification
              icon={<XIcon size={18} />}
              color="red"
              title="Error"
              onClose={addConfigQuote.reset}
            >
              Ups! Hubo un error al guardar los datos
            </Notification>
          ) : null}

          {addConfigQuote.isSuccess ? (
            <Notification
              onClose={addConfigQuote.reset}
              icon={<CheckCircleIcon size={18} />}
              color="teal"
              title="Correcto"
            >
              Se guardarón los datos correctamente
            </Notification>
          ) : null}
        </>
      )}
    </>
  );
}

export default ModalAddQuote;
