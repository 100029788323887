import React from 'react';

// import PropTypes from 'prop-types'
import { Notification, PasswordInput } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '../../../../_desingModels/layout/components/form/Input/Input';
import ErrorMessage from '../../../../_desingModels/layout/components/form/Message/Message';
import CheckCircleIcon from '../../../../_desingModels/iconComponets/CheckCircle';
import XIcon from '../../../../_desingModels/iconComponets/XIcon';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import { useMutateUser } from '../../../../hooks/users';
import MultiSelectComponent from '../../../../_desingModels/layout/components/select/MultiSelect';
import Select from '../../../../_desingModels/layout/components/select/Select';

function InputContainers({ children }) {
  return (
    <div className="lg:px-16 w-full flex flex-col gap-5 items-end justify-between md:flex-row md:gap-2">
      {children}
    </div>
  );
}

function ModalForm({ roles = [], specialties, setOpened }) {
  const dataSchema = yup.object().shape({
    firstName: yup.string().required('*obl').max(50).min(3),
    lastName: yup.string().required('*obl').max(50).min(3),
    dni: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer(),
    phoneNumber: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer(),
    email: yup.string().required('*obl').email(),
    roles: yup.array().min(1).required('*obl'),
    id_especialidad: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    name: yup.string().required('*obl').max(50).min(4),
    password: yup.string().required('*obl').max(50).min(8),
  });
  const {
    control,
    setValue,
    register,
    handleSubmit,
    // setValue,
    reset,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(dataSchema),
  });
  const {
    mutate,
    isLoading,
    isSuccess,
    isError,
    reset: resetQuery,
  } = useMutateUser();

  const onSubmit = (data) => {
    // console.log(data, 'Data user');
    mutate(data);
    reset();
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pb-5 w-full flex flex-col gap-5 mt-10"
      >
        <InputContainers>
          <div className="w-full">
            <Input
              textLabel="Nombres"
              type="text"
              placeholder="Nombres"
              registerValue={register('firstName', { required: true })}
              modal
            />
            {errors.firstName && (
              <ErrorMessage xs message={errors.firstName.message} />
            )}
          </div>
          <div className="w-full">
            <Input
              textLabel="Apellidos"
              type="text"
              placeholder="Apellidos"
              registerValue={register('lastName', { required: true })}
              modal
            />
            {errors.lastName && (
              <ErrorMessage xs message={errors.lastName.message} />
            )}
          </div>
        </InputContainers>
        <InputContainers>
          <div className="w-full">
            <Input
              textLabel="Nombre de usuario"
              type="text"
              placeholder="Nombre de usuario"
              registerValue={register('name', { required: true })}
              modal
            />
            {errors.name && <ErrorMessage xs message={errors.name.message} />}
          </div>
          <div className="w-full">
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <PasswordInput
                  placeholder="Contraseña"
                  label="Contraseña"
                  onChange={(value) => field.onChange(value)}
                  classNames={{
                    label: 'lex flex-col  font-bold text-blue-800 text-base',
                    input:
                      'border-0 rounded-lg p-1.5  mt-1 text-black/60 bg-white border border-indigo-500',
                  }}
                />
              )}
            />

            {errors.password && (
              <ErrorMessage xs message={errors.password.message} />
            )}
          </div>
        </InputContainers>
        <InputContainers>
          <div className="w-full">
            <Input
              textLabel="DNI"
              type="number"
              placeholder="DNI"
              registerValue={register('dni', { required: true })}
              modal
            />
            {errors.dni && <ErrorMessage xs message={errors.dni.message} />}
          </div>
          <div className="w-full">
            <Input
              textLabel="Teléfono"
              type="tel"
              placeholder="Teléfono"
              name="phoneNumber"
              registerValue={register('phoneNumber', { required: true })}
              modal
            />
            {errors.phoneNumber && (
              <ErrorMessage xs message={errors.phoneNumber.message} />
            )}
          </div>
        </InputContainers>
        <InputContainers>
          <div className="w-full">
            <Input
              textLabel="Email"
              type="email"
              placeholder="Correo"
              registerValue={register('email', { required: true })}
              modal
            />
            {errors.email && <ErrorMessage xs message={errors.email.message} />}
          </div>
          <div className="w-full">
            {roles.length > 0 && (
              <Controller
                control={control}
                name="roles"
                render={({ field }) => (
                  <MultiSelectComponent
                    data={roles}
                    placeholder="Buscar Rol"
                    onChange={(value) => field.onChange(value)}
                    selected={field.value}
                  />
                )}
              />
            )}
            {roles.length > 0 ? (
              errors.roles && <ErrorMessage xs message={errors.roles.message} />
            ) : (
              <ErrorMessage
                xs
                message="No hay roles disponibles para asignar, Ingrese al módulo rol para agregar uno nuevo"
              />
            )}
          </div>
        </InputContainers>
        <InputContainers>
          <div className="w-full">
            <Input
              textLabel="N° de colegiatura"
              type="text"
              placeholder="N° de colegiatura"
              registerValue={register('no_colegiatura', { required: true })}
              modal
            />
            {errors.firstName && (
              <ErrorMessage xs message={errors.firstName.message} />
            )}
          </div>
          <div className="w-full">
            <Select
              itemArray={specialties}
              modal
              setValue={setValue}
              registerValue={register('id_especialidad', { required: true })}
              idSelect="id_especialidad"
              selectBd
              defaultValue="Especialidades"
            />
            {specialties.length > 0 ? (
              errors.id_especialidad && (
                <ErrorMessage xs message={errors.id_especialidad.message} />
              )
            ) : (
              <ErrorMessage xs message="No hay especialidades registradas" />
            )}
          </div>
        </InputContainers>

        <div className="pt-8 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
          <PrimaryButton type="input" text="Guardar" blue />
          <PrimaryButton
            text={isSuccess ? 'Cerrar' : 'Cancelar'}
            modal
            handleClick={() => setOpened(false)}
          />
        </div>
      </form>
      {isLoading ? (
        <Notification
          loading
          title="Uploading data to the server"
          disallowClose
        >
          Espere hasta que se carguen los datos, no puede cerrar esta
          notificación aún
        </Notification>
      ) : (
        <>
          {isError ? (
            <Notification
              icon={<XIcon size={18} />}
              color="red"
              title="Error"
              onClose={resetQuery}
            >
              Ups! Hubo un error al guardar los datos
            </Notification>
          ) : null}

          {isSuccess ? (
            <Notification
              icon={<CheckCircleIcon size={18} />}
              color="teal"
              title="Correcto"
              onClose={resetQuery}
            >
              Se guardarón los datos correctamente
            </Notification>
          ) : null}
        </>
      )}
    </>
  );
}

ModalForm.propTypes = {};

export default ModalForm;
