/* eslint-disable no-unused-vars */
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getAllExam,
  getAllExtras,
  addExtraExam,
  updateExtraExam,
  deleteExtraExam,
  updateExam,
  deleteExam,
} from '../api/requests';

export const useGetAllExam = () => useQuery('allExam', getAllExam);
export const useGetAllExtras = () => useQuery('allExtras', getAllExtras);

export const useMutateExtraExam = () => {
  const queryClient = useQueryClient();
  return useMutation(addExtraExam, {
    onSuccess: () => {
      queryClient.invalidateQueries('hola');
    },
  });
};

export const useUpdateExtraExam = () => {
  const queryClient = useQueryClient();
  return useMutation(updateExtraExam, {
    onSuccess: () => {
      queryClient.invalidateQueries('hola');
    },
  });
};

export const useDeleteExtraExam = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteExtraExam, {
    onSuccess: () => {
      queryClient.invalidateQueries('hola');
    },
  });
};

export const useUpdateExam = () => {
  const queryClient = useQueryClient();
  return useMutation(updateExam, {
    onSuccess: () => {
      queryClient.invalidateQueries('hola');
    },
  });
};

export const useDeleteExam = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteExam, {
    onSuccess: () => {
      queryClient.invalidateQueries('hola');
    },
  });
};
