/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

function Search({ handleSearch }) {
  return (
    <div>
      <input
        className="rounded-lg p-1.5  mt-1 text-azul2 bg-blanc1 border border-grayT"
        type="text"
        placeholder="Buscar"
        onChange={(e) => handleSearch(e.target.value)}
      />
    </div>
  );
}

export default Search;
