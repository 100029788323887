/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid';
import { useMutateExamQuote } from '../../../../hooks/quote';
import CheckCircle from '../../../../_desingModels/iconComponets/CheckCircle';
import XIconIconMobile from '../../../../_desingModels/iconComponets/XIcon';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import Notification from '../../../../_desingModels/layout/components/notifications/Notification';
import TreeCheckSearch from '../../../../_desingModels/layout/components/select/TreeCheckSearch';

function ModalAddExam({ setOpened, examTree }) {
  const [examData, setExamData] = useState();
  const clientParams = useParams();
  const {
    mutate,
    isLoading,
    isSuccess,
    isError,
    reset: resetQuery,
  } = useMutateExamQuote();
  const nameQuote = clientParams['*'].split('/');
  const addExamQuote = (exam) => {
    const idQuote = nameQuote[1].split('-');
    const newData = {
      id_cotizacion: idQuote[idQuote.length - 1],
      id_exam: examData,
    };
    mutate(newData);
    // console.log(newData);
  };
  const handleCheckedChange = (checked) => {
    setExamData(checked);
  };
  return (
    <>
      <TreeCheckSearch tree={examTree} checkSi={handleCheckedChange} />
      <div className="pt-0 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
        <PrimaryButton text="Guardar" modal handleClick={addExamQuote} />
        <PrimaryButton
          text={isSuccess ? 'Cerrar' : 'Cancelar'}
          modal
          handleClick={() => setOpened(false)}
        />
      </div>
    </>
  );
}

export default ModalAddExam;
