import React from 'react';
// import PropTypes from 'prop-types'

function RoleCard({ children }) {
  return (
    <div className="rounded-md text-blanc1 bg-azul2 px-2 py-1 font-bold flex justify-center items-center gap-2">
      {children}
    </div>
  );
}

RoleCard.propTypes = {};

export default RoleCard;
