import React from 'react';
// import PropTypes from 'prop-types'

function DeclaredJurisdictions() {
  return <div>Declaraciones juradas</div>;
}

DeclaredJurisdictions.propTypes = {};

export default DeclaredJurisdictions;
