/* eslint-disable no-unused-vars */
import { Notification } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '../../../../_desingModels/layout/components/form/Input/Input';
import ErrorMessage from '../../../../_desingModels/layout/components/form/Message/Message';
import CheckCircleIcon from '../../../../_desingModels/iconComponets/CheckCircle';
import XIcon from '../../../../_desingModels/iconComponets/XIcon';
import Select from '../../../../_desingModels/layout/components/select/Select';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import { useMutateSede, useMutateSedeUpdate } from '../../../../hooks/clients';
import P from '../../../../_desingModels/textComponents/P';
// import { useRuc } from '../../../../hooks/ruc';

function AddSedeModal({
  departamento = [],
  provincia = [{ label: '...', value: 0 }],
  distrito = [{ label: '...', value: 0 }],
  idClient,
  setOpened,
  dataSede,
  editModal,
}) {
  /*  const [ruc, setRuc] = useState('');
  const useRucClient = useRuc('10724670593');
  console.log('ruc', useRucClient); */

  const dataSchema = yup.object().shape({
    cede: yup.string().required('*obl').max(50).min(3),
    ubigeo: yup.string().required('*obl').max(50).min(3),
    numero: yup
      .number('*obl')
      .typeError('*obl')
      .required()
      .positive('*>0')
      .integer(),
    departamento: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    provincia: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    distrito: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
  });
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(dataSchema),
  });
  const {
    mutate,
    isLoading,
    isSuccess,
    isError,
    reset: resetQuery,
  } = useMutateSede();
  const updateSede = useMutateSedeUpdate();

  const onSubmit = (data) => {
    const newData = {
      id_departamento: data.departamento,
      id_provincia: data.provincia,
      id_distrito: data.distrito,
      numero: data.numero,
      cede: data.cede,
      ubiego: data.ubigeo,
    };
    if (editModal) {
      // console.log('editSede', newData);
      updateSede.mutate({ id: dataSede.id, sedeData: newData });
      // reset();
    } else {
      // console.log('sede', newData);
      mutate({ ...newData, id_cliente: idClient });
      reset();
    }
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pb-5 w-full flex flex-col gap-5 mt-10"
      >
        <div className="w-full flex flex-col gap-5 items-end md:flex-row md:gap-2">
          <div className="w-full md:w-fit">
            <Input
              textLabel="Nombre sede"
              type="text"
              placeholder="sede"
              registerValue={register('cede', { required: true })}
              modal
              defaultValue={dataSede?.cede}
            />
            {errors.cede && <ErrorMessage xs message={errors.cede.message} />}
          </div>
          <div className="w-full md:w-fit ">
            <Input
              textLabel="Domicilio"
              type="text"
              placeholder="Calle/Av."
              registerValue={register('ubigeo', { required: true })}
              modal
              defaultValue={dataSede?.ubiego}
            />
            {errors.ubigeo && (
              <ErrorMessage xs message={errors.ubigeo.message} />
            )}
          </div>
          <div className="w-full md:w-20 lg:w-24">
            <Input
              textLabel=""
              type="number"
              placeholder="Número"
              registerValue={register('numero', { required: true })}
              modal
              defaultValue={dataSede?.numero}
            />
            {errors.numero && (
              <ErrorMessage xs message={errors.numero.message} />
            )}
          </div>
        </div>
        <div className="w-full flex flex-col gap-5 items-end md:flex-row md:gap-2">
          <div className="flex w-full flex-col gap-5 md:w-fit md:flex-row md:flex-wrap md:gap-2 ">
            <div className="flex flex-col gap-1">
              <P blue text={dataSede?.departamento} />
              <Select
                itemArray={departamento}
                modal
                setValue={setValue}
                registerValue={register('departamento', {
                  required: true,
                })}
                idSelect="departamento"
                selectBd
                defaultValue="Departamento"
              />
              {departamento.length > 0 ? (
                errors.departamento && (
                  <ErrorMessage xs message={errors.departamento.message} />
                )
              ) : (
                <ErrorMessage xs message=" No" />
              )}
            </div>
            <div className="flex flex-col gap-1">
              <P blue text={dataSede?.provincia} />
              <Select
                itemArray={provincia}
                modal
                setValue={setValue}
                registerValue={register('provincia', { required: true })}
                idSelect="provincia"
                selectBd
                defaultValue="Provincia"
              />

              {provincia.length > 0
                ? errors.provincia && (
                    <ErrorMessage xs message={errors.provincia.message} />
                  )
                : null}
            </div>
            <div className="flex flex-col gap-1">
              <P blue text={dataSede?.distrito} />
              <Select
                itemArray={distrito}
                modal
                setValue={setValue}
                registerValue={register('distrito', { required: true })}
                idSelect="distrito"
                selectBd
                defaultValue="Distrito"
              />

              {distrito.length > 0
                ? errors.distrito && (
                    <ErrorMessage xs message={errors.distrito.message} />
                  )
                : null}
            </div>
          </div>
        </div>
        <div className="pt-8 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
          <PrimaryButton type="input" text="Guardar" blue />
          <PrimaryButton
            text={isSuccess ? 'Cerrar' : 'Cancelar'}
            modal
            handleClick={() => setOpened(false)}
          />
        </div>
      </form>
      {isLoading || updateSede.isLoading ? (
        <Notification
          loading
          title="Uploading data to the server"
          disallowClose
        >
          Espere hasta que se carguen los datos, no puede cerrar esta
          notificación aún
        </Notification>
      ) : (
        <>
          {isError || updateSede.isError ? (
            <Notification
              icon={<XIcon size={18} />}
              color="red"
              title="Error"
              onClose={resetQuery}
            >
              Ups! Hubo un error al guardar los datos
            </Notification>
          ) : null}

          {isSuccess || updateSede.isSuccess ? (
            <Notification
              icon={<CheckCircleIcon size={18} />}
              color="teal"
              title="Correcto"
              onClose={resetQuery}
            >
              Se guardarón los datos correctamente
            </Notification>
          ) : null}
        </>
      )}
    </>
  );
}

AddSedeModal.propTypes = {};

export default AddSedeModal;
