import React from 'react';
import loginIcon1 from '../../../assets/login/loginIcon1.svg';
import loginIcon2 from '../../../assets/login/loginIcon2.svg';
// import PropTypes from 'prop-types'

function Page404({ message }) {
  return (
    <div className="flex flex-col p-10 justify-center items-center lg:flex-row lg:justify-between ">
      <figure className="flex flex-col justify-center items-center mt-10 lg:self-end w-52">
        <img
          className="hidden lg:block object-cover"
          src={loginIcon1}
          alt="logo"
        />
      </figure>
      <div className="lg:w-2/5 flex flex-col items-center flex-1">
        <h1 className="text-4xl lg:text-9xl text-negro">404</h1>
        <h1 className="lg:text-3xl text-negro">{message}</h1>
      </div>

      <figure className="flex flex-col justify-center items-center mt-10 lg:self-end w-52">
        <img className="object-cover " src={loginIcon2} alt="logo" />
      </figure>
    </div>
  );
}

Page404.propTypes = {};

export default Page404;
