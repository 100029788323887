import React from 'react';
import PropTypes from 'prop-types';
import ExclamationCircle from '../../../../iconComponets/ExclamationCircle';
import classNames from '../../../../utils/classNames';
import CheckCircle from '../../../../iconComponets/CheckCircle';

function ErrorMessage({ message, type = 'error', xs }) {
  return (
    <div
      className={classNames(
        'flex gap-1 mt-1 text-xs',
        type === 'success' ? 'text-green-500' : 'text-red-500',
        xs && 'absolute'
      )}
    >
      {type === 'success' ? <CheckCircle xs /> : <ExclamationCircle xs />}
      <span>{message}</span>
    </div>
  );
}

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string,
};
ErrorMessage.defaultProps = { type: 'error' };

export default ErrorMessage;
