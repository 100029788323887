/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
// import { useForm } from 'react-hook-form';
// import PropTypes from 'prop-types'
import ContMenuVertical from '../../../_desingModels/layout/components/burguerMenu/ContMenuVertical';
import BurguerMenuVert from '../../../_desingModels/layout/components/burguerMenu/BurguerMenuVert';
import H1 from '../../../_desingModels/textComponents/H1';
import DatePicker from '../../../_desingModels/layout/components/calendar/DatePicker';
import Search from '../../../_desingModels/layout/components/search/Search';
import classNames from '../../../_desingModels/utils/classNames';
import Select from '../../../_desingModels/layout/components/select/Select';
import { useStateData } from '../../../context/dataContext';
import P from '../../../_desingModels/textComponents/P';

function HeaderDashVertical({ text, select, data, children }) {
  const { setCount } = useStateData();
  const [isOpen, setIsOpen] = useState(false);
  const [optionS, setOptionS] = useState(1);
  const { setQuoteDataMain } = useStateData();
  const [notFound, setNotFound] = useState(false);
  const [notFoundDate, setNotFoundDate] = useState(false);
  let results = [];
  useEffect(() => {
    setQuoteDataMain(data);
  }, [notFound, notFoundDate]);

  const handleSearch = (e) => {
    setCount(e);
    if (e.length !== 0) {
      results = data.filter((dataP) => {
        if (optionS === 1) {
          return dataP.no_cotizacion
            .toLowerCase()
            .includes(e.toLocaleLowerCase());
        }
        if (optionS === 2) {
          return dataP.rucDni.toLowerCase().includes(e.toLocaleLowerCase());
        }
        if (optionS === 3) {
          return dataP.razonSocial
            .toLowerCase()
            .includes(e.toLocaleLowerCase());
        }
        return null;
      });
      setQuoteDataMain(results);
      if (results.length === 0) {
        setNotFound(true);
      } else {
        setNotFound(false);
      }
    } else {
      setQuoteDataMain(data);
      setNotFound(false);
    }
  };

  const handleSearchDate = (e) => {
    const formatDate = (dateString) => dateString.replace(/\//g, '-');
    const formattedDate = formatDate(e);
    setCount(formattedDate);

    if (formattedDate !== 'Invalid date') {
      const date = data.filter((dataP) =>
        dataP.fecha_cotizacion
          .toLowerCase()
          .includes(formattedDate.toLowerCase())
      );
      setQuoteDataMain(date);
      if (date.length === 0) {
        setNotFoundDate(true);
      } else {
        setNotFoundDate(false);
      }
    } else {
      setQuoteDataMain(data);
      setNotFoundDate(false);
    }
  };
  const handleChange = (e) => {
    setOptionS(e.id);
  };

  return (
    <>
      <header
        className={classNames(
          'hidden lg:flex flex-col bg-white rounded-xl p-4 gap-5'
        )}
      >
        <section className="flex flex-col gap-5">
          <div className="">
            <H1 text={text} />
            {select ? (
              <DatePicker
                search
                handleSearchDate={(date) => handleSearchDate(date)}
              />
            ) : null}
            {notFoundDate ? <P text="No hay datos" /> : null}
          </div>
          {select ? (
            <Select
              itemArray={select}
              handleChange={(option) => handleChange(option)}
            />
          ) : null}
        </section>
        {select ? (
          <section className="">
            <Search handleSearch={(word) => handleSearch(word)} />
            {notFound ? <P text="No hay datos" /> : null}
          </section>
        ) : null}
        {children}
      </header>
      <ContMenuVertical isOpen={isOpen}>
        {({ open }) => (
          <BurguerMenuVert text={text} open={open}>
            {open === true && setIsOpen(true)}
            {select ? (
              <section className="flex flex-col gap-3 justify-center items-center">
                <div className="flex flex-col gap-3 justify-center items-center">
                  <DatePicker
                    search
                    inputFormat="YYYY-MM-DD"
                    labelFormat="YYYY-MM-DD"
                    handleSearchDate={(date) => handleSearchDate(date)}
                  />
                  <Select
                    itemArray={select}
                    handleChange={(option) => handleChange(option)}
                  />
                </div>
              </section>
            ) : null}
            {select ? (
              <section className="flex flex-col justify-center items-center">
                <section className="">
                  <Search handleSearch={(word) => handleSearch(word)} />
                  {notFound ? <P text="No hay datos" /> : null}
                </section>
              </section>
            ) : null}
            {children}
          </BurguerMenuVert>
        )}
      </ContMenuVertical>
    </>
  );
}

HeaderDashVertical.propTypes = {};

export default HeaderDashVertical;
