import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  addQuote,
  copyQuote,
  addConfigQuote,
  showQuotes,
  showQuotesJr,
  showQuotesSr,
  showQuotesPaginadorSr,
  showQuote,
  updateQuoteExam,
  updateConfig,
  updateCotiPortada,
  showComboLimit,
  deleteQuoteExam,
  addExamQuote,
  showExamsTree,
  showExtraTree,
  toCheck,
  sendQuote,
  aproveQuote,
  rejectQuote,
  downloadQuote,
  generateToken,
  activateToken,
  minPrice,
  validateToken,
  getCotiPred,
  getCotiPredExam,
  getShowProfiles,
  getShowEvaluacion,
  getConfigQuotes,
  getCotiPortada,
} from '../api/requests';

const key = 'quotes';

export const useMutateQuote = () => {
  const queryClient = useQueryClient();
  return useMutation(addQuote, {
    onSuccess: () => {
      queryClient.invalidateQueries('junior');
      queryClient.invalidateQueries('senior');
      queryClient.invalidateQueries('seniorPaginador');
      queryClient.invalidateQueries(key);
    },
  });
};
export const useCopyQuote = () => {
  const queryClient = useQueryClient();
  return useMutation(copyQuote, {
    onSuccess: () => {
      queryClient.invalidateQueries('junior');
      queryClient.invalidateQueries('senior');
      queryClient.invalidateQueries('seniorPaginador');
      queryClient.invalidateQueries(key);
    },
  });
};

export const useMutateConfigQuote = () => {
  const queryClient = useQueryClient();
  return useMutation(addConfigQuote, {
    onSuccess: () => {
      queryClient.invalidateQueries('junior');
      queryClient.invalidateQueries('senior');
      queryClient.invalidateQueries('seniorPaginador');
      queryClient.invalidateQueries(key);
    },
  });
};

export const useQuote = () => useQuery(key, showQuotes);
export const useQuoteJr = () => useQuery('junior', showQuotesJr);
export const useQuoteSr = () => useQuery('senior', showQuotesSr);
export const useQuotePaginadorSr = (id) =>
  useQuery(['seniorPaginador', id], () => showQuotesPaginadorSr(id), true);
export const useComboLimit = () => useQuery('comboQuote', showComboLimit);
export const useExamTree = () => useQuery('exam', showExamsTree);
export const useExtraTree = () => useQuery('examExtra', showExtraTree);
export const usePdfQuote = () => useQuery('pdfQuote', downloadQuote);
export const useGetCotiPred = () => useQuery('predet', getCotiPred);
export const usegetCotiPredExam = (idPred) =>
  useQuery(['predetids', idPred], () => getCotiPredExam(idPred), {
    enabled: !!idPred,
  });
export const useGetShowProfile = () => useQuery('profile', getShowProfiles);
export const useGetShowEva = () => useQuery('evaluation', getShowEvaluacion);
export const useGetConfigQuotes = (idPred) =>
  useQuery(['configquote', idPred], () => getConfigQuotes(idPred), {
    enabled: !!idPred,
  });

export const useGetCotiPortada = (id) =>
  useQuery(['aaaaaaaaa', id], () => getCotiPortada(id));

export const useUpdateCotiPortada = () => {
  const queryClient = useQueryClient();
  return useMutation(updateCotiPortada, {
    onSuccess: () => {
      queryClient.invalidateQueries('aaaaaaaaa');
    },
  });
};

export const mutateGenerateToken = () => {
  const queryClient = useQueryClient();
  return useMutation(generateToken, {
    onSuccess: () => {
      queryClient.invalidateQueries('token');
    },
  });
};

export const mutateActivateToken = () => {
  const queryClient = useQueryClient();
  return useMutation(activateToken, {
    onSuccess: () => {
      queryClient.invalidateQueries('token');
    },
  });
};

export const mutateValidateToken = () => {
  const queryClient = useQueryClient();
  return useMutation(validateToken, {
    onSuccess: () => {
      queryClient.invalidateQueries('token');
    },
  });
};

export const mutateMinPrice = () => {
  const queryClient = useQueryClient();
  return useMutation(minPrice, {
    onSuccess: () => {
      queryClient.invalidateQueries('mini');
    },
  });
};

export const quoteById = (idQuote) =>
  useQuery(['quote', idQuote], () => showQuote(idQuote), {
    enabled: !!idQuote,
  });

export const useUpdateQuoteExam = () => {
  const queryClient = useQueryClient();
  return useMutation(updateQuoteExam, {
    onSuccess: () => {
      queryClient.invalidateQueries('quote');
    },
  });
};

export const useUpdateConfig = () => {
  const queryClient = useQueryClient();
  return useMutation(updateConfig, {
    onSuccess: () => {
      queryClient.invalidateQueries('configquote');
    },
  });
};

export const useDeleteQuoteExam = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteQuoteExam, {
    onSuccess: () => {
      queryClient.invalidateQueries('quote');
      queryClient.invalidateQueries('configquote');
    },
  });
};

export const useMutateExamQuote = () => {
  const queryClient = useQueryClient();
  return useMutation(addExamQuote, {
    onSuccess: () => {
      queryClient.invalidateQueries('quote');
      queryClient.invalidateQueries('configquote');
    },
  });
};

export const useMutateCheck = () => {
  const queryClient = useQueryClient();
  return useMutation(toCheck, {
    onSuccess: () => {
      queryClient.invalidateQueries('quote');
    },
  });
};

export const useMutateSend = () => {
  const queryClient = useQueryClient();
  return useMutation(sendQuote, {
    onSuccess: () => {
      queryClient.invalidateQueries('quote');
    },
  });
};

export const useMutateApprove = () => {
  const queryClient = useQueryClient();
  return useMutation(aproveQuote, {
    onSuccess: () => {
      queryClient.invalidateQueries('quote');
    },
  });
};

export const useMutateReject = () => {
  const queryClient = useQueryClient();
  return useMutation(rejectQuote, {
    onSuccess: () => {
      queryClient.invalidateQueries('quote');
    },
  });
};
