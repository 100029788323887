import React from 'react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';

export function SelectIcon() {
  return <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />;
}

export function CheckSelect() {
  return <CheckIcon className="h-5 w-5" aria-hidden="true" />;
}
