/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
// import PropTypes from 'prop-types'
import { Link, NavLink } from 'react-router-dom';
import { useState } from 'react';
import ConatinerMenu from '../../../_desingModels/layout/components/burguerMenu/ConatinerMenu';
import classNames from '../../../_desingModels/utils/classNames';
import BurguerMenu from '../../../_desingModels/layout/components/burguerMenu/BurguerMenu';
import Menu from '../../../_desingModels/layout/components/Menu/Menu';
import BellIcon from '../../../_desingModels/iconComponets/BellIcon';
import logoinTextWhite from '../../../assets/logo/logoInTextWhite.svg';
import SubMenu from '../../../_desingModels/layout/components/Menu/SubMenu';
import PrimaryButton from '../../../_desingModels/layout/components/Buttons/PrimaryButton';
import { useStateData } from '../../../context/dataContext';
import ModalToken from './ModalToken';
import ModalWithBtn from '../modal/ModalWithBtn';
import { generateToken } from '../../../api/requests';

function Header({
  navigationMenu,
  navigation,
  navigatioSubMenu,
  navigationCatalogo,
}) {
  const { setTokenData } = useStateData();
  const [disableButton, setDisableButton] = useState(true);
  const [modalActivate, setModalActivate] = useState(false);
  const userData = JSON.parse(window.localStorage.getItem('user'));
  const nickUser = `${userData.user.name.charAt(
    0
  )}${userData.user.apellidos.charAt(0)}`;
  const handleClickGenerate = async () => {
    setDisableButton(false);
    await generateToken().then((response) => {
      /* console.log('GENERAMDO TOKEN');
      console.log(response.data); */
      setTokenData(response.data);
      setModalActivate(true);
      setDisableButton(true);
    });
  };
  return (
    <ConatinerMenu>
      {({ open }) => (
        <nav className="flex justify-between ">
          <div className="lg:hidden">
            <BurguerMenu
              open={open}
              navigation={navigation}
              navigatioSubMenu={navigatioSubMenu}
            />
          </div>
          <div className="hidden lg:flex lg:gap-5">
            <Link to="inicio" className="w-32 cursor-pointer">
              <img src={logoinTextWhite} alt="logo" />
            </Link>
            <div className="hidden lg:flex lg:gap-2 justify-center items-center">
              {navigation.map((item, index) =>
                item.name === 'Cotizaciones' ? (
                  <SubMenu
                    key={index}
                    text={item.name}
                    navigation={navigatioSubMenu}
                  />
                ) : (
                  <NavLink
                    key={index}
                    to={item.link_href}
                    className={({ isActive }) =>
                      classNames(
                        isActive && 'bg-indigo-400 font-bold',
                        'text-white font-semibold hover:bg-indigo-400 px-2 py-1 rounded-md lg:px-1 lg:text-sm 2xl:text-base active:bg-blue-600 font-poppins truncate lg:w-14 xl:w-fit'
                      )
                    }
                  >
                    {item.name}
                  </NavLink>
                )
              )}
              <SubMenu text="Catálogos" navigation={navigationCatalogo} />
              <NavLink
                to="generateExam"
                className={({ isActive }) =>
                  classNames(
                    isActive && 'bg-indigo-400 font-bold',
                    'text-white font-semibold hover:bg-indigo-400 px-2 py-1 rounded-md lg:px-1 lg:text-sm 2xl:text-base active:bg-blue-600 font-poppins truncate lg:w-14 xl:w-fit'
                  )
                }
              >
                Generar Examen
              </NavLink>
            </div>
          </div>
          <ModalWithBtn
            opened={modalActivate}
            setOpened={setModalActivate}
            title="Activar TOKEN"
            tamanio="auto"
          >
            <ModalToken setOpened={setModalActivate} />
          </ModalWithBtn>

          <div className="flex gap-2">
            {userData.es_gerente === 1 ? (
              <PrimaryButton
                disabled={!disableButton}
                generate
                text="Generar token"
                handleClick={handleClickGenerate}
              />
            ) : null}
            <Link
              to="/notifications"
              className="h-8 w-8 rounded-full bg-white flex items-center justify-center relative focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-500 focus:ring-white"
            >
              <BellIcon />
              <div className="bg-rose-700 h-6 w-6 p-1 rounded-full absolute flex items-center justify-center -right-2 -top-2">
                <p className="text-white font-semibold text-xs truncate">22</p>
              </div>
            </Link>
            {/* Profile dropdown */}
            <Menu
              url="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              text={nickUser}
              navigation={navigationMenu}
            />
          </div>
        </nav>
      )}
    </ConatinerMenu>
  );
}

Header.propTypes = {};

export default Header;
