/* eslint-disable no-unused-vars */
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import DropzoneImage from '../../../../_desingModels/layout/components/image/DropzoneImage';
import H2 from '../../../../_desingModels/textComponents/H2';

function ModalFirma({ userData, setOpened, patient }) {
  // console.log(userData.id, 'userData');
  return (
    <>
      {/* <H2 text="Firma Digital" /> */}
      <DropzoneImage userId={userData.id} codPatient={patient} />
      <div className="pt-8 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
        <PrimaryButton
          text="Cancelar"
          modal
          handleClick={() => setOpened(false)}
        />
      </div>
    </>
  );
}

export default ModalFirma;
