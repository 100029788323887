/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Notification } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '../../../../_desingModels/layout/components/form/Input/Input';
import ErrorMessage from '../../../../_desingModels/layout/components/form/Message/Message';
import CheckCircleIcon from '../../../../_desingModels/iconComponets/CheckCircle';
import XIcon from '../../../../_desingModels/iconComponets/XIcon';
import Select from '../../../../_desingModels/layout/components/select/Select';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import { useMutateClient } from '../../../../hooks/clients';
import CheckBox from '../../../../_desingModels/layout/components/radio/CheckBox';
// import { useRuc } from '../../../../hooks/ruc';

function ModalForm({
  departamento = [],
  provincia = [{ label: '...', value: 0 }],
  distrito = [{ label: '...', value: 0 }],
  actividad = [],
  antiguedad = [],
  tamano = [],
  tipoPago = [],
  setOpened,
}) {
  /*  const [ruc, setRuc] = useState('');
  const useRucClient = useRuc('10724670593');
  console.log('ruc', useRucClient); */
  const [isCompany, setIsCompany] = useState(0);

  const dataSchema = yup.object().shape({
    razonSocial: yup.string().required('*obl').max(50).min(3),
    nombre: yup.string().required('*obl').max(50).min(3),
    ap_paterno: yup.string().required('*obl').max(50).min(3),
    ap_materno: yup.string().required('*obl').max(50).min(3),
    cargo: yup.string().required('*obl').max(50).min(3),
    correo: yup.string().email().required('*obl').max(50).min(3),
    telefono: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer(),
    rucDni: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer(),
    ubigeo: yup.string().required('*obl').max(50).min(3),
    numero: yup
      .number('*obl')
      .typeError('*obl')
      .required()
      .positive('*>0')
      .integer(),
    tipoPago: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    departamento: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    provincia: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    distrito: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    actividad: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    antiguedad: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
    tamano: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
  });
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(dataSchema),
  });
  const {
    mutate,
    isLoading,
    isSuccess,
    isError,
    reset: resetQuery,
  } = useMutateClient();

  const handleChangeEsEmpresa = (e) => {
    if (e.target.checked === true) {
      setIsCompany(1);
    } else {
      setIsCompany(0);
    }
  };

  const onSubmit = (data) => {
    console.log(isCompany);
    console.log({
      ...data,
      isEmpresa: isCompany,
    });
    mutate({
      ...data,
      isEmpresa: isCompany,
    });
    reset();
  };
  // console.log(addSede);

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pb-5 w-full flex flex-col gap-5 mt-4"
      >
        <div className="w-full flex flex-col gap-5 items-end md:flex-row md:gap-2">
          <div className="w-full md:w-fit">
            <Input
              textLabel="Nombre/Razón Social"
              type="text"
              placeholder="Nombre/Razón Social"
              registerValue={register('razonSocial', { required: true })}
              modal
            />
            {errors.razonSocial && (
              <ErrorMessage xs message={errors.razonSocial.message} />
            )}
          </div>
          <div className="w-full md:w-fit">
            <Input
              textLabel="RUC/DNI"
              type="number"
              placeholder="RUC/DNI"
              registerValue={register('rucDni', { required: true })}
              modal
              /* handleChange={(ev) =>
                ev.target.value.length === 10
                  ? setRuc(ev.target.value)
                  : setRuc('')
              } */
            />
            {errors.rucDni && (
              <ErrorMessage xs message={errors.rucDni.message} />
            )}
          </div>
          <div className="w-full md:w-fit">
            <Select
              itemArray={tipoPago}
              modal
              setValue={setValue}
              registerValue={register('tipoPago', { required: true })}
              idSelect="tipoPago"
              selectBd
              defaultValue="Tipo de Pago"
            />
            {tipoPago.length > 0 ? (
              errors.tipoPago && (
                <ErrorMessage xs message={errors.tipoPago.message} />
              )
            ) : (
              <ErrorMessage xs message="No hay tipos de pago registrados" />
            )}
          </div>
        </div>
        <div className="w-full flex flex-col gap-5 items-end md:flex-row md:gap-2">
          <div className="w-full md:w-fit ">
            <CheckBox
              modal
              textLabel="Es empresa?"
              value="esEmpresa"
              handleChange={handleChangeEsEmpresa}
            />
          </div>
        </div>
        <div className="w-full flex flex-col gap-5 items-end md:flex-row md:gap-2">
          <div className="w-full md:w-fit ">
            <Input
              textLabel="Domicilio"
              type="text"
              placeholder="Calle/Av."
              registerValue={register('ubigeo', { required: true })}
              modal
            />
            {errors.ubigeo && (
              <ErrorMessage xs message={errors.ubigeo.message} />
            )}
          </div>
          <div className="w-full md:w-20 lg:w-24">
            <Input
              textLabel=""
              type="number"
              placeholder="Número"
              registerValue={register('numero', { required: true })}
              modal
            />
            {errors.numero && (
              <ErrorMessage xs message={errors.numero.message} />
            )}
          </div>
        </div>
        <div className="w-full flex flex-col gap-5 items-end md:flex-row md:gap-2">
          <div className="flex w-full flex-col gap-5 md:w-fit md:flex-row md:flex-wrap md:gap-2 ">
            <div>
              <Select
                itemArray={departamento}
                modal
                setValue={setValue}
                registerValue={register('departamento', {
                  required: true,
                })}
                idSelect="departamento"
                selectBd
                defaultValue="Departamento"
              />
              {departamento.length > 0 ? (
                errors.departamento && (
                  <ErrorMessage xs message={errors.departamento.message} />
                )
              ) : (
                <ErrorMessage xs message=" No" />
              )}
            </div>
            <div className="">
              <Select
                itemArray={provincia}
                modal
                setValue={setValue}
                registerValue={register('provincia', { required: true })}
                idSelect="provincia"
                selectBd
                defaultValue="Provincia"
              />

              {provincia.length > 0
                ? errors.provincia && (
                    <ErrorMessage xs message={errors.provincia.message} />
                  )
                : null}
            </div>
            <div>
              <Select
                itemArray={distrito}
                modal
                setValue={setValue}
                registerValue={register('distrito', { required: true })}
                idSelect="distrito"
                selectBd
                defaultValue="Distrito"
              />

              {distrito.length > 0
                ? errors.distrito && (
                    <ErrorMessage xs message={errors.distrito.message} />
                  )
                : null}
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-5 md:flex-row md:flex-wrap md:gap-2">
          <div>
            <Select
              itemArray={actividad}
              modal
              setValue={setValue}
              registerValue={register('actividad', { required: true })}
              idSelect="actividad"
              selectBd
              defaultValue="Actividad"
            />
            {errors.actividad && (
              <ErrorMessage xs message={errors.actividad.message} />
            )}
          </div>
          <div>
            <Select
              itemArray={antiguedad}
              modal
              setValue={setValue}
              registerValue={register('antiguedad', { required: true })}
              idSelect="antiguedad"
              selectBd
              defaultValue="Antiguedad"
            />
            {errors.antiguedad && (
              <ErrorMessage xs message={errors.antiguedad.message} />
            )}
          </div>
          <div>
            <Select
              itemArray={tamano}
              modal
              setValue={setValue}
              registerValue={register('tamano', { required: true })}
              idSelect="tamano"
              selectBd
              defaultValue="Tamaño"
            />
            {errors.tamano && (
              <ErrorMessage xs message={errors.tamano.message} />
            )}
          </div>
        </div>
        <div className="w-full flex flex-wrap gap-5 items-end md:flex-row md:gap-2">
          <div className="w-full md:w-fit">
            <Input
              textLabel="Contacto"
              type="text"
              placeholder="Nombre de contacto"
              registerValue={register('nombre', { required: true })}
              modal
            />
            {errors.nombre && (
              <ErrorMessage xs message={errors.nombre.message} />
            )}
          </div>
          <div className="w-full md:w-fit">
            <Input
              type="text"
              placeholder="Apellido Paterno"
              registerValue={register('ap_paterno', { required: true })}
              modal
            />
            {errors.ap_paterno && (
              <ErrorMessage xs message={errors.ap_paterno.message} />
            )}
          </div>
          <div className="w-full md:w-fit">
            <Input
              type="text"
              placeholder="Apellido Materno"
              registerValue={register('ap_materno', { required: true })}
              modal
            />
            {errors.ap_materno && (
              <ErrorMessage xs message={errors.ap_materno.message} />
            )}
          </div>
          <div className="w-full md:w-fit">
            <Input
              type="text"
              placeholder="Cargo"
              registerValue={register('cargo', { required: true })}
              modal
            />
            {errors.cargo && <ErrorMessage xs message={errors.cargo.message} />}
          </div>
          <div className="w-full md:w-fit">
            <Input
              type="email"
              placeholder="@mail.com"
              registerValue={register('correo', { required: true })}
              modal
            />
            {errors.correo && (
              <ErrorMessage xs message={errors.correo.message} />
            )}
          </div>
          <div className="w-full md:w-fit">
            <Input
              type="number"
              placeholder="Teléfono"
              registerValue={register('telefono', { required: true })}
              modal
            />
            {errors.telefono && (
              <ErrorMessage xs message={errors.telefono.message} />
            )}
          </div>
        </div>
        <div className="pt-8 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
          <PrimaryButton type="input" text="Guardar" blue />
          <PrimaryButton
            text={isSuccess ? 'Cerrar' : 'Cancelar'}
            modal
            handleClick={() => setOpened(false)}
          />
        </div>
      </form>
      {isLoading ? (
        <Notification
          loading
          title="Uploading data to the server"
          disallowClose
        >
          Espere hasta que se carguen los datos, no puede cerrar esta
          notificación aún
        </Notification>
      ) : (
        <>
          {isError ? (
            <Notification
              icon={<XIcon size={18} />}
              color="red"
              title="Error"
              onClose={resetQuery}
            >
              Ups! Hubo un error al guardar los datos
            </Notification>
          ) : null}

          {isSuccess ? (
            <Notification
              icon={<CheckCircleIcon size={18} />}
              color="teal"
              title="Correcto"
              onClose={resetQuery}
            >
              Se guardarón los datos correctamente
            </Notification>
          ) : null}
        </>
      )}
    </>
  );
}

ModalForm.propTypes = {};

export default ModalForm;
