import React from 'react';
// import PropTypes from 'prop-types'
import { Disclosure } from '@headlessui/react';

function ConatinerMenu({ children }) {
  return (
    <Disclosure
      as="header"
      className="bg-purpleT py-3 px-3 lg:px-6 fixed w-full z-20 top-0"
    >
      {children}
    </Disclosure>
  );
}

ConatinerMenu.propTypes = {};

export default ConatinerMenu;
