/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Notification, Pagination } from '@mantine/core';
import HeaderDashVertical from '../../../layaouts/headerDashboard/HeaderDashVertical';
import HeaderDashHorizont from '../../../layaouts/headerDashboard/HeaderDashHorizont';
import Wraper from '../../../layaouts/wrapper/Wrapper';
import CardContainer from '../../../layaouts/container/CardContainer';
import QuotesCard from '../../../layaouts/cards/QuotesCard';
import TableComponent from '../../../../_desingModels/layout/components/table/Table';
import ModalWithBtn from '../../../layaouts/modal/ModalWithBtn';
import ModalAddQuote from './ModalAddQuote';
import {
  useExamTree,
  useQuoteJr,
  useQuoteSr,
  useCopyQuote,
  useQuotePaginadorSr,
} from '../../../../hooks/quote';
import XIcon from '../../../../_desingModels/iconComponets/XIcon';
import CheckCircleIcon from '../../../../_desingModels/iconComponets/CheckCircle';
import { useStateData } from '../../../../context/dataContext';

const option = [
  { name: 'N° Cotización', id: 1 },
  { name: 'RUC', id: 2 },
  { name: 'Empresa', id: 3 },
];

const quotes = [
  {
    state: 'Primera Revisión',
    date: '2022/08/12',
    quoteName: 'JM-06102022-ADMN-T-01',
    protocolo: 'Preocupacional',
    company: 'CASAGRANDE SAC',
    ruc: '1234567890',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    state: 'Aceptada',
    date: '2022/08/20',
    quoteName: 'JM-06102022-ADMN-T-02',
    protocolo: 'Preocupacional',
    company: 'CASAGRANDE SAC',
    ruc: '1234567890',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    state: 'Primera Revisión',
    date: '2022/08/27',
    quoteName: 'JM-06102022-ADMN-T-03',
    protocolo: 'Preocupacional',
    company: 'LASLOMAS SAC',
    ruc: '2078945658',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    state: 'Aceptada',
    date: '2022/09/11',
    quoteName: 'JM-06102022-ADMN-T-04',
    protocolo: 'Preocupacional',
    company: 'LASLOMAS SAC',
    ruc: '2078945658',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    state: 'Primera Revisión',
    date: '2022/09/11',
    quoteName: 'JM-06102022-ADMN-T-10',
    protocolo: 'Preocupacional',
    company: 'PERU SAC',
    ruc: '2045612389',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    state: 'Aceptada',
    date: '2022/09/11',
    quoteName: 'JM-06102022-ADMN-T-12',
    protocolo: 'Preocupacional',
    company: 'PERU SAC',
    ruc: '2045612389',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    state: 'Primera Revisión',
    date: '2022/09/24',
    quoteName: 'JM-06102022-ADMN-T-13',
    protocolo: 'Preocupacional',
    company: 'PERU SAC',
    ruc: '2045612389',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    state: 'Aceptada',
    date: '2022/09/24',
    quoteName: 'JM-06102022-ADMN-T-14',
    protocolo: 'Preocupacional',
    company: 'CARGOS SAC',
    ruc: '2023568945',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    state: 'Primera Revisión',
    date: '2022/10/11',
    quoteName: 'JM-06102022-ADMN-T-15',
    protocolo: 'Preocupacional',
    company: 'CARGOS SAC',
    ruc: '2023568945',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    state: 'Aceptada',
    date: '2022/10/11',
    quoteName: 'JM-06102022-ADMN-T-16',
    protocolo: 'Preocupacional',
    company: 'CASABLANCA SAC',
    ruc: '2012457856',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
];

function MainQuotes({ handleClickDetailQuote, quoteData }) {
  const [newQuote, setNewQuote] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const { quoteDataMain, count } = useStateData();
  const checkTree = useExamTree();

  const copyQuote = useCopyQuote();
  const quoteDataPaginador = useQuotePaginadorSr(currentPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleNewQuote = (event) => {
    event.preventDefault();
    setNewQuote(true);
  };
  const handleClickCopyQuote = (event, qCopy) => {
    event.preventDefault();
    const copyQ = {
      id_cotizacion: qCopy.id,
    };
    copyQuote.mutate(copyQ);
  };

  return (
    <div className="flex w-full flex-col lg:flex-row gap-3">
      <HeaderDashVertical text="Cotizaciones" select={option} data={quoteData}>
        {null}
      </HeaderDashVertical>
      <Wraper nofullScreen>
        <HeaderDashHorizont
          title="Cotizaciones recientes"
          handleClick={handleNewQuote}
        >
          <ModalWithBtn
            opened={newQuote}
            setOpened={setNewQuote}
            title="Agregar Cotización"
            tamanio="xl"
          >
            <ModalAddQuote
              setOpened={setNewQuote}
              examTree={checkTree?.data?.data?.examenes}
            />
          </ModalWithBtn>
        </HeaderDashHorizont>
        <div className="md:hidden">
          <CardContainer>
            {quoteData?.map((quote, index) => (
              <QuotesCard
                key={index}
                quote={quote}
                handleClickDetailQuote={handleClickDetailQuote}
              />
            ))}
          </CardContainer>
        </div>
        <div className="hidden md:flex md:flex-col w-full">
          {copyQuote.isLoading ? (
            <Notification loading title="Enviando datos" disallowClose>
              Espere hasta que se carguen los datos, no puede cerrar esta
              notificación aún
            </Notification>
          ) : (
            <>
              {copyQuote.isError ? (
                <Notification
                  icon={<XIcon size={18} />}
                  color="red"
                  title="Error"
                  onClose={copyQuote.reset}
                >
                  Ups! Hubo un error al guardar los datos
                </Notification>
              ) : null}

              {copyQuote.isSuccess ? (
                <Notification
                  onClose={copyQuote.reset}
                  icon={<CheckCircleIcon size={18} />}
                  color="teal"
                  title="Correcto"
                >
                  Se guardarón los datos correctamente
                </Notification>
              ) : null}
            </>
          )}
          {count.toString().length === 0 || count === 'Invalid date' ? (
            quoteDataPaginador.isLoading ? (
              <div>Cargando...</div>
            ) : quoteDataPaginador.isError ? (
              <div>Error</div>
            ) : quoteDataPaginador.isSuccess ? (
              <>
                <TableComponent
                  allData={quoteData}
                  data={quoteDataPaginador.data.cotizaciones.data}
                  handleClickDetailQuote={handleClickDetailQuote}
                  handleClickCopyQuote={handleClickCopyQuote}
                />
                <section className="w-full flex justify-center">
                  <Pagination
                    value={quoteDataPaginador.data.cotizaciones.current_page}
                    onChange={handlePageChange}
                    total={quoteDataPaginador.data.cotizaciones.last_page}
                    withEdges
                  />
                </section>
              </>
            ) : (
              <div>Sin datos</div>
            )
          ) : (
            <TableComponent
              allData={quoteDataMain}
              data={quoteDataMain}
              handleClickDetailQuote={handleClickDetailQuote}
              handleClickCopyQuote={handleClickCopyQuote}
            />
          )}
        </div>
      </Wraper>
    </div>
  );
}

MainQuotes.propTypes = {};

export default MainQuotes;
