import React from 'react';
// import PropTypes from 'prop-types';

function Thead({ children }) {
  return (
    <thead className="w-full hidden md:flex flex-col gap-1 md:flex-row">
      {children}
    </thead>
  );
}

Thead.propTypes = {};

export default Thead;
