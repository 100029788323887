/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import PropTypes from 'prop-types'
import PrimaryButton from '../../../_desingModels/layout/components/Buttons/PrimaryButton';
import ExclamationCircle from '../../../_desingModels/iconComponets/ExclamationCircle';
import UploadIconMobile from '../../../_desingModels/iconComponets/UploadIcon';
import CheckCircle from '../../../_desingModels/iconComponets/CheckCircle';
import TextArea from '../../../_desingModels/layout/components/form/textArea/TextArea';
import Line from '../../../_desingModels/layout/components/Line';
import H6 from '../../../_desingModels/textComponents/H6';
import ErrorMessage from '../../../_desingModels/layout/components/form/Message/Message';
import ModalWithBtn from '../modal/ModalWithBtn';
import ModalFirma from '../../pages/management/users/ModalFirma';

function Aside({ errors, registerValue }) {
  /* const [patientImage, setPatientImage] = useState(false);
  const handleClickOpenModal = () => {
    setPatientImage(true);
  }; */
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-2 bg-white rounded-xl">
      {/* <section className="flex flex-col bg-white rounded-xl w-full items-center justify-center gap-5 px-4 py-8 md:flex-row">
        <article className="flex flex-col gap-2 w-full items-center">
          <div className="bg-negro2 p-0 rounded-md w-full h-32">
            <img
              className="h-full object-cover object-center"
              src="https://i.pinimg.com/564x/9b/47/a0/9b47a023caf29f113237d61170f34ad9.jpg"
              alt="perfil"
            />
          </div>
          <div className="flex gap-2 ">
            <PrimaryButton
              text="Agregar"
              handleClick={handleClickOpenModal}
              blue
            />
            <ModalWithBtn
              opened={patientImage}
              setOpened={setPatientImage}
              title="Agregar firma digital"
            >
              <ModalFirma userData={1} setOpened={setPatientImage} />
            </ModalWithBtn>
          </div>
        </article>
        <article className="flex flex-col gap-2 w-full items-center">
          <div className="bg-negro2 p-0 rounded-md w-full h-32">
            <img
              className="h-full object-cover object-center"
              src="https://i.pinimg.com/564x/9b/47/a0/9b47a023caf29f113237d61170f34ad9.jpg"
              alt="perfil"
            />
          </div>
          <div className="flex gap-2 ">
            <PrimaryButton text="Agregar" blue />
          </div>
        </article>
        <article className="flex flex-col gap-2 w-full items-center">
          <div className="bg-negro2 p-2 rounded-md w-full h-32">Huella</div>
          <div className="flex gap-2 ">
            <PrimaryButton text="Agregar" blue />
          </div>
        </article>
      </section>
      <Line /> */}
      <section className="flex flex-col bg-white rounded-xl w-full gap-5 px-4 py-8 ">
        <H6>Declaraciones juradas</H6>
        <article className="flex gap-4 w-full items-center ">
          <div className="flex items-center justify-center">
            <div className="text-verde1">
              <CheckCircle />
            </div>
            Declaraciones jurada 2
          </div>
        </article>
        <article className="flex gap-4 w-full items-center ">
          <div className="flex items-center justify-center">
            <div className="text-rojo1">
              <ExclamationCircle />
            </div>
            Declaraciones jurada 2
          </div>
          <div className="text-blanc1 bg-azul2 rounded p-1">
            <UploadIconMobile />
          </div>
        </article>
        <article className="flex gap-4 w-full items-center ">
          <div className="flex items-center justify-center">
            <div className="text-verde1">
              <CheckCircle />
            </div>
            Declaraciones jurada 2
          </div>
        </article>
      </section>
      <Line />
      <section className="flex flex-col bg-white rounded-xl w-full gap-5 p-4 ">
        <H6>Comentarios</H6>
        <article className="flex gap-4 w-full items-center ">
          <div className="w-full md:w-fit">
            <TextArea
              textLabel=""
              placeholder="Comentarios"
              registerValue={registerValue}
            />
            {errors.comentarios && (
              <ErrorMessage xs message={errors.comentarios.message} />
            )}
          </div>
        </article>
      </section>
      <Line />
      <section className="flex flex-col bg-white rounded-xl w-full gap-5 px-10 py-8 md:flex-row lg:flex-col">
        <PrimaryButton
          text="Cancelar"
          handleClick={() => {
            navigate(-1);
          }}
        />
        <PrimaryButton type="input" text="Guardar" />
        {/* <PrimaryButton text="Guardar e imprimir" /> */}
      </section>
    </div>
  );
}

Aside.propTypes = {};

export default Aside;
