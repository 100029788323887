import { Notification } from '@mantine/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import Input from '../../../../_desingModels/layout/components/form/Input/Input';
import ErrorMessage from '../../../../_desingModels/layout/components/form/Message/Message';
import XIcon from '../../../../_desingModels/iconComponets/XIcon';
import CheckCircleIcon from '../../../../_desingModels/iconComponets/CheckCircle';
import Select from '../../../../_desingModels/layout/components/select/Select';
import { useMutateUserUpdate, useSpecialties } from '../../../../hooks/users';

function InputContainers({ children }) {
  return (
    <div className="lg:px-16 w-full flex flex-col gap-5 items-end justify-between md:flex-row md:gap-2">
      {children}
    </div>
  );
}
function ModalFormUser({ setOpened, userData }) {
  const fetchSpecialties = useSpecialties();
  const dataSchema = yup.object().shape({
    nombres: yup.string().required('*obl').max(50).min(3),
    apellidos: yup.string().required('*obl').max(50).min(3),
    dni: yup.string().required('*obl').max(50).min(4),
    telefono: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer(),
    email: yup.string().required('*obl').email(),
    name: yup.string().required('*obl').max(50).min(4),
    no_colegiatura: yup.string().required('*obl').max(50).min(3),
  });
  const {
    setValue,
    register,
    handleSubmit,
    // reset,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(dataSchema),
  });
  const {
    mutate,
    isLoading,
    isSuccess,
    isError,
    reset: resetQuery,
  } = useMutateUserUpdate();

  const onSubmit = (data) => {
    if (data.id_especialidad === '0') {
      const newData = {
        apellidos: data.apellidos,
        nombres: data.nombres,
        dni: data.dni,
        telefono: data.telefono,
        email: data.email,
        name: data.name,
        no_colegiatura: data.no_colegiatura,
      };
      mutate({ id: userData.id, userData: newData });
    } else {
      mutate({ id: userData.id, userData: data });
    }
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pb-5 w-full flex flex-col gap-5 mt-10"
      >
        <InputContainers>
          <div className="w-full">
            <Input
              textLabel="Nombres"
              type="text"
              placeholder="Nombres"
              registerValue={register('nombres', { required: true })}
              modal
              defaultValue={userData.nombres}
            />
            {errors.nombres && (
              <ErrorMessage xs message={errors.nombres.message} />
            )}
          </div>
          <div className="w-full">
            <Input
              textLabel="Apellidos"
              type="text"
              placeholder="Apellidos"
              registerValue={register('apellidos', { required: true })}
              modal
              defaultValue={userData.apellidos}
            />
            {errors.apellidos && (
              <ErrorMessage xs message={errors.apellidos.message} />
            )}
          </div>
        </InputContainers>
        <InputContainers>
          <div className="w-full">
            <Input
              textLabel="Nombre de usuario"
              type="text"
              placeholder="Nombre de usuario"
              registerValue={register('name', { required: true })}
              modal
              defaultValue={userData.name}
            />
            {errors.name && <ErrorMessage xs message={errors.name.message} />}
          </div>
          <div className="w-full">
            <Input
              textLabel="Email"
              type="email"
              placeholder="Correo"
              registerValue={register('email', { required: true })}
              modal
              defaultValue={userData.email}
            />
            {errors.email && <ErrorMessage xs message={errors.email.message} />}
          </div>
        </InputContainers>
        <InputContainers>
          <div className="w-full">
            <Input
              textLabel="DNI"
              type="text"
              placeholder="DNI"
              registerValue={register('dni', { required: true })}
              modal
              defaultValue={userData.dni}
            />
            {errors.dni && <ErrorMessage xs message={errors.dni.message} />}
          </div>
          <div className="w-full">
            <Input
              textLabel="Teléfono"
              type="tel"
              placeholder="Teléfono"
              name="phoneNumber"
              registerValue={register('telefono', { required: true })}
              modal
              defaultValue={userData.telefono}
            />
            {errors.telefono && (
              <ErrorMessage xs message={errors.telefono.message} />
            )}
          </div>
        </InputContainers>
        <InputContainers>
          <div className="w-full">
            <Input
              textLabel="N° de colegiatura"
              type="text"
              placeholder="N° de colegiatura"
              registerValue={register('no_colegiatura', { required: true })}
              modal
              defaultValue={userData.no_colegiatura}
            />
            {errors.no_colegiatura && (
              <ErrorMessage xs message={errors.no_colegiatura.message} />
            )}
          </div>
          <div className="w-full">
            <Select
              itemArray={fetchSpecialties?.data?.data?.especialidades}
              modal
              setValue={setValue}
              registerValue={register('id_especialidad', { required: true })}
              idSelect="id_especialidad"
              selectBd
              defaultValue={userData.especialidad}
            />
            {fetchSpecialties?.data?.data?.especialidades.length > 0 ? (
              errors.id_especialidad && (
                <ErrorMessage xs message={errors.id_especialidad.message} />
              )
            ) : (
              <ErrorMessage xs message="No hay especialidades registradas" />
            )}
          </div>
        </InputContainers>

        <div className="pt-8 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
          <PrimaryButton type="input" text="Actualizar" blue />
          <PrimaryButton
            text="Cancelar"
            modal
            handleClick={() => setOpened(false)}
          />
        </div>
      </form>
      {isLoading ? (
        <Notification
          loading
          title="Uploading data to the server"
          disallowClose
        >
          Espere hasta que se carguen los datos, no puede cerrar esta
          notificación aún
        </Notification>
      ) : (
        <>
          {isError ? (
            <Notification
              icon={<XIcon size={18} />}
              color="red"
              title="Error"
              onClose={resetQuery}
            >
              Ups! Hubo un error al guardar los datos
            </Notification>
          ) : null}

          {isSuccess ? (
            <Notification
              icon={<CheckCircleIcon size={18} />}
              color="teal"
              title="Correcto"
              onClose={resetQuery}
            >
              Se guardarón los datos correctamente
            </Notification>
          ) : null}
        </>
      )}
    </>
  );
}

ModalFormUser.propTypes = {};

export default ModalFormUser;
