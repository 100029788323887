import PropTypes from 'prop-types';
import { MenuIcon } from '@heroicons/react/outline';
import classNames from '../utils/classNames';

function MenuIconMobile({ colorIcon = false }) {
  return (
    <MenuIcon
      className={classNames(
        'block h-6 w-6',
        colorIcon ? 'text-indigo-500' : 'text-white'
      )}
      aria-hidden="true"
    />
  );
}

MenuIconMobile.propTypes = {
  // eslint-disable-next-line react/require-default-props
  colorIcon: PropTypes.string,
};

export default MenuIconMobile;
