import React from 'react';
import PropTypes from 'prop-types';
import MailOpenIcon from '../../../iconComponets/MailIcon';

function ButtonWhitIcon({ text, handleClick }) {
  return (
    <button
      type="button"
      className="flex gap-2 w-full max-w-xs items-center justify-center bg-purpleT rounded-md text-grayT font-bold text-xl py-2 px-3 hover:bg-blue-600 font-poppins"
      onClick={handleClick}
    >
      <MailOpenIcon />
      {text}
    </button>
  );
}

ButtonWhitIcon.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ButtonWhitIcon;
