import React from 'react';
import ExclamationCircle from '../../../iconComponets/ExclamationCircle';
// import PropTypes from 'prop-types'

function Notification({ message }) {
  return (
    <div className="w-full flex flex-col items-center justify-center text-rojo1 mt-40">
      <ExclamationCircle />
      {message}
    </div>
  );
}

Notification.propTypes = {};

export default Notification;
