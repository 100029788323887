import React from 'react';
// import PropTypes from 'prop-types'
import Modal from '../../../_desingModels/layout/components/modal/Modal';
// import H1 from '../../../_desingModels/textComponents/H1';
// import PrimaryButton from '../../../_desingModels/layout/components/Buttons/PrimaryButton';
// import Line from '../../../_desingModels/layout/components/Line';

function ModalWithBtn({ opened, setOpened, title, children, tamanio }) {
  return (
    <Modal
      title={title}
      opened={opened}
      setOpened={setOpened}
      tamanio={tamanio}
    >
      {/* <header className="w-full flex flex-col justify-center items-center gap-2">
        <H1 text={title} />
        <Line />;
      </header> */}

      <div className="w-64 md:w-full">{children}</div>
    </Modal>
  );
}

ModalWithBtn.propTypes = {};

export default ModalWithBtn;
