import { CheckCircleIcon } from '@heroicons/react/solid';
import classNames from '../utils/classNames';

function CheckCircle({ xs }) {
  return (
    <CheckCircleIcon
      className={classNames('block ', xs ? 'h-3 w-3' : 'h-5 w-5')}
      aria-hidden="true"
    />
  );
}

export default CheckCircle;
