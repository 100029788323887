/* eslint-disable */
import React from 'react';
import { Notification } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '../../../../_desingModels/layout/components/form/Input/Input';
import ErrorMessage from '../../../../_desingModels/layout/components/form/Message/Message';
import CheckCircleIcon from '../../../../_desingModels/iconComponets/CheckCircle';
import XIcon from '../../../../_desingModels/iconComponets/XIcon';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
// import Line from '../../../../_desingModels/layout/components/Line';
import { useMutateRoleEdit } from '../../../../hooks/roles';
import H2 from '../../../../_desingModels/textComponents/H2';
import MultiSelectComponent from '../../../../_desingModels/layout/components/select/MultiSelect';
import RoleCard from '../../../layaouts/cards/RoleCard';
import GenericButton from '../../../../_desingModels/layout/components/Buttons/GenericButton';
import TrashIconMobile from '../../../../_desingModels/iconComponets/TrashIcon';

function ModalFormEdited({ modulesName = [], setOpened, dataRol }) {
  const [dataModulo, setDataModulo] = React.useState(dataRol?.arrayModulos);
  const [removedModule, setRemovedModule] = React.useState([]);
  const [error, setError] = React.useState(false);

  const dataSchema = yup.object().shape({
    rol: yup.string().required('*obl').max(50).min(3),
  });
  const {
    control,
    register,
    handleSubmit,
    // setValue,
    reset,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(dataSchema),
  });
  const {
    mutate,
    isLoading,
    isSuccess,
    isError,
    reset: resetQuery,
  } = useMutateRoleEdit();

  const onSubmit = (data) => {
    // mutate(data);
    let flag = true;
    data.arrayMNews?.forEach((element) => {
      if (dataModulo.find((item) => item.id_modulo === element)) {
        console.log('Se repite');
        setError(true);
        flag = false;
      } else {
        flag = true;
        setError(false);
      }
    });
    if (flag) {
      console.log({
        id_rol: dataRol.id,
        rol: data.rol,
        arrayMNews: data.arrayMNews === undefined ? [] : data.arrayMNews,
        arrayMDelete: removedModule,
      });
      mutate({
        id_rol: dataRol.id,
        rol: data.rol,
        arrayMNews: data.arrayMNews === undefined ? [] : data.arrayMNews,
        arrayMDelete: removedModule,
      });
      reset();
    }
  };

  const handleClickDeleted = (module) => {
    const thisExists = dataModulo.find((e) => e.id_modulo === module.id_modulo);
    if (thisExists) {
      const newArray = dataModulo.filter(
        (e) => e.id_modulo !== module.id_modulo
      );
      // console.log(newArray, 'la data');
      setDataModulo(newArray);
      setRemovedModule([...removedModule, module.id_modulo]);
    }
  };
  // console.log(removedModule, 'eliminados');
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pb-5 w-full flex flex-col gap-5 mt-10 justify-center items-center"
      >
        <div className="w-full lg:px-10">
          <Input
            textLabel="Nombre de Rol"
            type="text"
            placeholder="Nombre de rol"
            registerValue={register('rol', { required: true })}
            defaultValue={dataRol?.rol}
            modal
          />
          {errors.rol && <ErrorMessage xs message={errors.rol.message} />}
        </div>
        <div className="w-full lg:px-10 max-h-48 overflow-y-scroll">
          <H2 text="Módulos Asignados" blue />
          <div className="flex flex-wrap gap-2 mt-5 border border-azul2 p-4 w-full ">
            {dataModulo?.map((module, index) => (
              <RoleCard key={index}>
                {module.modulo}
                <GenericButton
                  classNames="px-2 py-2 rounded-md text-white hover:bg-blue-800"
                  handleClick={() => {
                    handleClickDeleted(module);
                  }}
                >
                  <TrashIconMobile />
                </GenericButton>
              </RoleCard>
            ))}
          </div>
        </div>

        <div className="w-full lg:px-10">
          <H2 text="Agregar Módulos" blue />
          {modulesName.length > 0 && (
            <Controller
              control={control}
              name="arrayMNews"
              render={({ field }) => (
                <MultiSelectComponent
                  placeholder="Buscar Módulo"
                  data={modulesName}
                  onChange={(value) => field.onChange(value)}
                  selected={field.value}
                />
              )}
            />
          )}
          {modulesName.length > 0 ? (
            errors.arrayMNews && (
              <ErrorMessage xs message={errors.arrayMNews.message} />
            )
          ) : (
            <ErrorMessage
              xs
              message="No hay módulos disponibles para este rol, contactar con soporte"
            />
          )}
        </div>

        <div className="pt-8 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
          <PrimaryButton type="input" text="Guardar" blue />
          <PrimaryButton
            text="Cancelar"
            modal
            handleClick={() => setOpened(false)}
          />
        </div>
      </form>
      {error ? (
        <Notification
          icon={<XIcon size={18} />}
          color="red"
          title="El rol ya fue asignado!"
          onClose={() => setError(false)}
        >
          Ups! Hubo un error al guardar los datos
        </Notification>
      ) : null}
      {isLoading ? (
        <Notification
          loading
          title="Uploading data to the server"
          disallowClose
        >
          Espere hasta que se carguen los datos, no puede cerrar esta
          notificación aún
        </Notification>
      ) : (
        <>
          {isError ? (
            <Notification
              icon={<XIcon size={18} />}
              color="red"
              title="Error"
              onClose={resetQuery}
            >
              Ups! Hubo un error al guardar los datos
            </Notification>
          ) : null}

          {isSuccess ? (
            <Notification
              icon={<CheckCircleIcon size={18} />}
              color="teal"
              title="Correcto"
              onClose={resetQuery}
            >
              Se guardarón los datos correctamente
            </Notification>
          ) : null}
        </>
      )}
    </>
  );
}

ModalFormEdited.propTypes = {};

export default ModalFormEdited;
