import React from 'react';
// import PropTypes from 'prop-types'
import H6 from '../../../_desingModels/textComponents/H6';

function Section({ title, children }) {
  return (
    <section className="w-full px-5 ">
      <H6>{title}</H6>
      <div className="flex flex-col gap-x-4 gap-y-1 md:gap-y-4 md:flex-row md:flex-wrap md:items-end">
        {children}
      </div>
    </section>
  );
}

Section.propTypes = {};

export default Section;
