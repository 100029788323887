/* eslint-disable react/no-array-index-key */
// import { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import HeaderDashboard from '../../../layaouts/headerDashboard/HeaderDashboard';
// import PatientCard from '../../../layaouts/cards/PatientCard';
import CardContainer from '../../../layaouts/container/CardContainer';
import Wraper from '../../../layaouts/wrapper/Wrapper';
import Pagination from '../../../layaouts/pagination/Pagination';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';

// const pacientes = [
//   {
//     cod: '123-abcd',
//     lastName: 'Castañeda Solorzano',
//     firstName: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Ausente',
//   },
//   {
//     cod: '123-abcd',
//     lastName: 'Castañeda Solorzano',
//     firstName: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Completo',
//   },
//   {
//     cod: '123-abcd',
//     lastName: 'Castañeda Solorzano',
//     firstName: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Atendiendose',
//   },
//   {
//     cod: '123-abcd',
//     lastName: 'Castañeda Solorzano',
//     firstName: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Observado',
//   },
//   {
//     cod: '123-abcd',
//     lastName: 'Castañeda Solorzano',
//     firstName: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Ausente',
//   },
//   {
//     cod: '123-abcd',
//     lastName: 'Castañeda Solorzano',
//     firstName: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Completo',
//   },
//   {
//     cod: '123-abcd',
//     lastName: 'Castañeda Solorzano',
//     firstName: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Atendiendose',
//   },
//   {
//     cod: '123-abcd',
//     lastName: 'Castañeda Solorzano',
//     firstName: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Observado',
//   },
//   {
//     cod: '123-abcd',
//     lastName: 'Castañeda Solorzano',
//     firstName: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Ausente',
//   },
//   {
//     cod: '123-abcd',
//     lastName: 'Castañeda Solorzano',
//     firstName: 'Mario Fernando',
//     protocolo: 'Nombre Protocolo',
//     sexo: 'Masculino',
//     Dni: '123456789',
//     empresa: 'Casagrande SAC',
//     state: 'Completo',
//   },
// ];

function MainCitas() {
  return (
    <Wraper>
      <HeaderDashboard text="Citas" basic>
        <PrimaryButton text="Nuevo" blue />
      </HeaderDashboard>
      <CardContainer>
        {/* {pacientes.map((paciente, index) => (
          <PatientCard key={index} patient={paciente} />
        ))} */}
      </CardContainer>
      <footer className="w-full p-4 flex flex-col gap-5 lg:flex-row lg:justify-between">
        <Pagination />
      </footer>
    </Wraper>
  );
}

MainCitas.propTypes = {};

export default MainCitas;
