/* eslint-disable no-nested-ternary */
import React from 'react';
// import PropTypes from 'prop-types'
// import { Tooltip } from '@mantine/core';
import Card from '../../../_desingModels/layout/components/card/Card';
import HeaderCard from '../../../_desingModels/layout/components/card/HeaderCard';
import H3 from '../../../_desingModels/textComponents/H3';
import P from '../../../_desingModels/textComponents/P';
import H4 from '../../../_desingModels/textComponents/H4';
import CardIcon from '../../../_desingModels/utils/CardIcon';
import classNames from '../../../_desingModels/utils/classNames';

function QuotesCard({ quote, handleClickDetailQuote }) {
  return (
    <Card
      classNames={classNames(
        'flex flex-col border-2 rounded-md w-56',
        quote.estado === 'Ausente' && 'border-negro2',
        (quote.estado === 'Completo' || quote.estado === 'Aceptada') &&
          'border-verde1',
        quote.estado === 'Observado' && 'border-orange-700/80',
        quote.estado === 'Atendiendose' && 'border-indigo-500',
        quote.estado !== 'Aceptada' && 'NoAceptada' && 'border-azul2'
      )}
    >
      <HeaderCard
        classNames={classNames(
          'text-blanc1 p-2',
          quote.estado === 'Ausente' && 'bg-negro2',
          quote.estado === 'Completo' && 'bg-verde1',
          quote.estado === 'Observado' && 'bg-orange-700/80',
          quote.estado === 'Atendiendose' && 'bg-indigo-500',
          quote.estado === 'Aceptada' && 'bg-verde1',
          (quote.estado === 'Aceptada' ? quote.state : 'NoAceptada') &&
            'bg-azul2'
        )}
      >
        <div className="flex justify-between">
          <P text={quote?.estado} />
          <P text={quote?.fecha_cotizacion} />
          {quote.estado === 'Aceptada' && <CardIcon estado={quote.estado} />}
        </div>
        <H3 text={quote?.no_cotizacion} />
        <P text={quote?.categoria} />
      </HeaderCard>

      <section>
        <div className="text-gray-800 p-2">
          <P text={quote?.razonSocial} />
          <P text={quote?.rucDni} />
          <div className="flex justify-between pr-2 pt-2">
            <div>
              <H4 text="Sub Total" />
              <P text="100 Soles" />
            </div>
            <div>
              <H4 text="Total" />
              <P text="118 Soles" />
            </div>
          </div>
        </div>
        <div className="p-2">
          <button
            className={classNames(
              'text-white py-1 px-2 rounded-md w-5/12',

              quote.estado === 'Aceptada'
                ? 'bg-teal-600 hover:bg-teal-700'
                : 'bg-azul2 hover:bg-indigo-700'
            )}
            type="button"
            onClick={(e) => {
              handleClickDetailQuote(e, quote);
            }}
          >
            Ver
          </button>
        </div>
      </section>
    </Card>
  );
}

// QuotesCard.propTypes = {}

export default QuotesCard;
