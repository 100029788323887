/* eslint-disable react/no-array-index-key */
import React from 'react';
// import PropTypes from 'prop-types'
import Td from '../../../../../_desingModels/layout/components/table/Td';
import TableContainer from '../../../../../_desingModels/layout/components/table/TableContainer';
import TBody from '../../../../../_desingModels/layout/components/table/TBody';
import Tr from '../../../../../_desingModels/layout/components/table/Tr';
import SecondaryButton from '../../../../../_desingModels/layout/components/Buttons/SecondaryButton';

function TableQuotes({ data }) {
  return (
    <TableContainer>
      <TBody>
        {data.map((item, index) => (
          <Tr key={index}>
            <Td td="td1" mainText={item.name} secondaryText={item.number} />
            <Td
              td="td1"
              mainText={item.primerarevison}
              secondaryText={item.igv}
            />
            <td>
              <SecondaryButton text="Atender" />
            </td>
          </Tr>
        ))}
      </TBody>
    </TableContainer>
  );
}

TableQuotes.propTypes = {};

export default TableQuotes;
