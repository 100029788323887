// import PropTypes from 'prop-types';
import { PlusIcon } from '@heroicons/react/outline';
import classNames from '../utils/classNames';

function PlusIconMobile({ colorIcon = false }) {
  return (
    <PlusIcon
      className={classNames(
        'block h-8 w-8',
        colorIcon ? 'text-indigo-500' : 'text-white'
      )}
      aria-hidden="true"
    />
  );
}
/* PlusIconMobile.propTypes = {
  colorIcon: PropTypes.string.isRequired,
}; */

export default PlusIconMobile;
