/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

function CheckboxDefault({ exam, tree, checkSi }) {
  const [checked, setChecked] = useState(exam);
  const [expanded, setExpanded] = useState([]);
  useEffect(() => {
    checkSi(checked);
  }, [checked, checkSi]);
  return (
    <div className="w-full h-52 overflow-scroll">
      <CheckboxTree
        nodes={tree}
        checked={checked}
        expanded={expanded}
        iconsClass="fa5"
        onCheck={(check) => setChecked(check)}
        onExpand={(expand) => setExpanded(expand)}
      />
    </div>
  );
}

export default CheckboxDefault;
