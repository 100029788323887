import React from 'react';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/outline';

export function ChevronDoubleLeft() {
  return <ChevronDoubleLeftIcon className="h-5 w-5 " aria-hidden="true" />;
}

export function ChevronDoubleRigt() {
  return <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />;
}
