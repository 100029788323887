import React from 'react';
// import PropTypes from 'prop-types';
import { MultiSelect } from '@mantine/core';

function MultiSelectComponent({
  data,
  placeholder,
  defaultValue,
  clearable,
  ...props
}) {
  // console.log(defaultValue, 'defaultValue');
  return (
    <MultiSelect
      data={data}
      label=""
      placeholder={placeholder}
      defaultValue={defaultValue}
      searchable
      clearable
      nothingFound="No se encontraron resultados"
      classNames={{
        wrapper: '',
        input:
          'border-0 rounded-lg p-1.5  mt-1 text-black/60 bg-white border border-indigo-500',
      }}
      {...props}
    />
  );
}

// MultiSelect.propTypes = {}

export default MultiSelectComponent;
