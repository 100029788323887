/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query';
import { showModules } from '../api/requests';

const key = 'clients';

// export const useMutateClient = () => {
//   const queryClient = useQueryClient();

//   return useMutation(showModules, {
//     onSuccess: () => {
//       queryClient.invalidateQueries(key);
//     },
//   });
// };

export const useModule = () => useQuery(key, showModules);
