import React from 'react';
import { Tooltip } from '@mantine/core';

function H6({ children }) {
  return (
    <Tooltip
      label={children}
      color="dark"
      transition="fade"
      position="bottom-start"
    >
      <h1 className="text-xl text-blue-800 font-poppins font-bold truncate">
        {children}
      </h1>
    </Tooltip>
  );
}

export default H6;
