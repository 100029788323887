/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-array-index-key */
import React from 'react';
// import PropTypes from 'prop-types'
import { Disclosure } from '@headlessui/react';
import MenuIcon from '../../../iconComponets/MenuIcon';
import XIcon from '../../../iconComponets/XIcon';
import SubMenu from '../Menu/SubMenu';

function BurguerMenu({ open, navigation = [], navigatioSubMenu }) {
  return (
    <>
      <Disclosure.Button className="bg-indigo-500 inline-flex items-center justify-center p-2 rounded-md hover:text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white font-medium font-poppins">
        <span className="sr-only">Open main menu</span>
        {open ? <XIcon /> : <MenuIcon />}
      </Disclosure.Button>

      <Disclosure.Panel className="font-medium ">
        <div as="nav" className="px-2 pt-2 pb-3 space-y-1 overflow-y-auto">
          {navigation.map((item, index) =>
            item.name === 'Cotizaciones' ? (
              <SubMenu
                key={index}
                text={item.name}
                navigation={navigatioSubMenu}
              />
            ) : (
              <Disclosure.Button
                key={index}
                as="a"
                href={item.link_href}
                className="font-poppins block px-3 py-2 rounded-md text-base font-medium text-white "
                aria-current={item.current ? 'page' : undefined}
              >
                {item.name}
              </Disclosure.Button>
            )
          )}
        </div>
      </Disclosure.Panel>
    </>
  );
}

BurguerMenu.propTypes = {};

export default BurguerMenu;
