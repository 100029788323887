/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import classNames from '../../../../utils/classNames';

function TextArea({
  textLabel,
  placeholder,
  defaultValue,
  registerValue,
  modal,
}) {
  return (
    <label className={classNames('flex flex-col  font-bold text-purpleT')}>
      {textLabel}
      <textarea
        className={classNames(
          'border-0 rounded-lg p-1.5  mt-1 text-black/60 ',
          modal ? 'bg-white border border-purpleT' : 'bg-blanc1'
        )}
        id={registerValue.name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...registerValue}
      />
    </label>
  );
}

TextArea.propTypes = {};

export default TextArea;
