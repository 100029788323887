/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import classNames from '../../../utils/classNames';

function SelectDefault({
  itemArray = [],
  setValue,
  registerValue,
  idSelect,
  defaultValue,
  disableSelect,
}) {
  const handleChangeSelect = (e) => {
    setValue(idSelect, e.target.value, { shouldValidate: true });
  };
  return itemArray.length === 0 ? null : (
    <div className="w-fit">
      <select
        disabled={disableSelect}
        {...registerValue}
        onChange={handleChangeSelect}
        className={classNames(
          'w-full truncate font-poppins text-purpleTH font-bold rounded-md border border-purpleT pr-6 text-sm flex'
        )}
      >
        <option value={defaultValue?.[0]?.value}>
          {defaultValue?.[0]?.label}
        </option>
        {itemArray?.map((e, index) => (
          <option key={index} value={e?.value} className="">
            {e?.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SelectDefault;
