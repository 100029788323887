import React from 'react';
// import PropTypes from 'prop-types';

function Tr({ children }) {
  return <th className="w-full flex mr-12 ml-5 text-blue-800">{children}</th>;
}

Tr.propTypes = {};

export default Tr;
