import React from 'react';
import CardIcon from '../../../../../_desingModels/utils/CardIcon';
import H5 from '../../../../../_desingModels/textComponents/H5';
import HeaderCard from '../../../../../_desingModels/layout/components/card/HeaderCard';
import Card from '../../../../../_desingModels/layout/components/card/Card';

function Dictionary() {
  return (
    <section className="flex flex-col gap-3 md:flex-row bg-gray-100 p-3 border shadow">
      <Card state="Ausente" wCard>
        <HeaderCard state="Ausente">
          <div className="flex text-md gap-1 justify-center items-center">
            <CardIcon state="Ausente" />
            <H5 text="Ausente" />
          </div>
        </HeaderCard>
      </Card>

      <Card state="Atendiendose" wCard>
        <HeaderCard state="Atendiendose">
          <div className="flex text-md gap-1 justify-center items-center">
            <CardIcon state="Atendiendose" />
            <H5 text="Atendiendose" />
          </div>
        </HeaderCard>
      </Card>
      <Card state="Completo" wCard>
        <HeaderCard state="Completo">
          <div className="flex text-md gap-1 justify-center items-center">
            <CardIcon state="Completo" />
            <H5 text="Completo" />
          </div>
        </HeaderCard>
      </Card>
      <Card state="Observado" wCard>
        <HeaderCard state="Observado">
          <div className="flex text-md gap-1 justify-center items-center">
            <CardIcon state="Observado" />
            <H5 text="Observado" />
          </div>
        </HeaderCard>
      </Card>
    </section>
  );
}

export default Dictionary;
