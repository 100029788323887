import { Select } from '@mantine/core';
import React from 'react';

function SelectSearch({ dataClient, defaultValue, ...props }) {
  return (
    <Select
      radius="md"
      placeholder=""
      searchable
      nothingFound="No existe"
      defaultValue={defaultValue}
      data={dataClient}
      {...props}
    />
  );
}

export default SelectSearch;
