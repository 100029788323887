import React from 'react';
// import PropTypes from 'prop-types';
import { Tooltip } from '@mantine/core';
import classNames from '../utils/classNames';

function P({ text, blue, fail }) {
  return (
    <Tooltip label={text} color="dark" transition="fade" position="bottom">
      <p
        className={classNames(
          'text-sm font-poppins truncate',
          blue ? 'text-purpleT' : fail && 'text-rojo1'
        )}
      >
        {text}
      </p>
    </Tooltip>
  );
}

// P.propTypes = {
//   text: PropTypes.string.isRequired,
// };

export default P;
