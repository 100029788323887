/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import { Accordion, Tooltip } from '@mantine/core';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import P from '../../../../_desingModels/textComponents/P';
import RoleCard from '../../../layaouts/cards/RoleCard';

function AccordionLabel({ rol }) {
  return (
    <div className="flex items-start gap-2 flex-col md:flex-row">
      <div className="flex flex-col w-56">
        <Tooltip label={rol} color="dark" transition="fade" position="bottom">
          <h2 className="text-blue-800 font-poppins font-bold text-lg truncate">
            {rol}
          </h2>
        </Tooltip>
      </div>
    </div>
  );
}

function AccordionItem({ roles, handleClickEdited }) {
  return (
    <Accordion
      classNames={{
        label: 'text-blue-800 font-poppins font-bold text-lg truncate',
      }}
    >
      {roles?.map((item, index) => (
        <Accordion.Item value={item.rol} key={index}>
          <Accordion.Control>
            <AccordionLabel {...item} />
          </Accordion.Control>
          <Accordion.Panel>
            <div className="text-negro font-bold items-center flex flex-col md:flex-row gap-2">
              <PrimaryButton
                type="button"
                text="Editar"
                handleClick={() => handleClickEdited(item)}
              />
              <P text={`${item?.numUsuarios} usuarios`} />
              <P text={`${item?.numModulos} módulos`} />
            </div>
            {item.arrayModulos?.length > 0 && (
              <div className="flex flex-wrap gap-2 mt-5 border border-azul2 p-4">
                {item?.arrayModulos.map((module, index) => (
                  <RoleCard key={index}>{module.modulo}</RoleCard>
                ))}
              </div>
            )}
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

AccordionItem.propTypes = {};

export default AccordionItem;
