import React from 'react';
import PropTypes from 'prop-types';

function SecondaryButton({ text }) {
  return (
    <button
      type="button"
      className="bg-purpleT rounded-md text-white font-semibold text-xs py-2 px-3 hover:bg-indigo-700 font-poppins"
    >
      {text}
    </button>
  );
}

SecondaryButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SecondaryButton;
