import React from 'react';
// import PropTypes from 'prop-types'
import HeaderDashboard from '../../../layaouts/headerDashboard/HeaderDashboard';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import Table from './Table';
import Wraper from '../../../layaouts/wrapper/Wrapper';
import Pagination from '../../../layaouts/pagination/Pagination';

const headItems = [
  { name: 'Fecha', id: 1 },
  { name: 'ombre/Razón Social', id: 2 },
  { name: 'RUC', id: 3 },
  { name: 'Comprobante', id: 4 },
  { name: 'Concepto', id: 5 },
  { name: 'Sub total', id: 6 },
  { name: 'Total', id: 7 },
  { name: 'Tipo de pago ', id: 7 },
  { name: 'Estado de pago', id: 7 },
];
const dataFake = [
  {
    fecha: '02/04/22',
    nombreoRazonSocial: 'Casagrande SAC',
    ruc: '123456789012',
    tipoComprobante: 'Factura',
    numComprobante: 'F001-1824',
    concepto: 'Examen ocupacional',
    subTotal: '100 Soles',
    total: '118 Soles',
    tipoPago: 'Crédito',
    diasCredito: '15 días',
    estadoPago: 'Cancelado',
  },
  {
    fecha: '02/04/22',
    nombreoRazonSocial: 'Casagrande SAC',
    ruc: '123456789012',
    tipoComprobante: 'Factura',
    numComprobante: 'F001-1824',
    concepto: 'Examen ocupacional',
    subTotal: '100 Soles',
    total: '118 Soles',
    tipoPago: 'Crédito',
    diasCredito: '15 días',
    estadoPago: 'Pendiente',
  },
  {
    fecha: '02/04/22',
    nombreoRazonSocial: 'Casagrande SAC',
    ruc: '123456789012',
    tipoComprobante: 'Factura',
    numComprobante: 'F001-1824',
    concepto: 'Examen ocupacional',
    subTotal: '100 Soles',
    total: '118 Soles',
    tipoPago: 'Crédito',
    diasCredito: '15 días',
    estadoPago: 'Por Cobrar',
  },
  {
    fecha: '02/04/22',
    nombreoRazonSocial: 'Casagrande SAC',
    ruc: '123456789012',
    tipoComprobante: 'Factura',
    numComprobante: 'F001-1824',
    concepto: 'Examen ocupacional',
    subTotal: '100 Soles',
    total: '118 Soles',
    tipoPago: 'Crédito',
    diasCredito: '15 días',
    estadoPago: 'Cancelado',
  },
  {
    fecha: '02/04/22',
    nombreoRazonSocial: 'Casagrande SAC',
    ruc: '123456789012',
    tipoComprobante: 'Factura',
    numComprobante: 'F001-1824',
    concepto: 'Examen ocupacional',
    subTotal: '100 Soles',
    total: '118 Soles',
    tipoPago: 'Crédito',
    diasCredito: '15 días',
    estadoPago: 'Pendiente',
  },
  {
    fecha: '02/04/22',
    nombreoRazonSocial: 'Casagrande SAC',
    ruc: '123456789012',
    tipoComprobante: 'Factura',
    numComprobante: 'F001-1824',
    concepto: 'Examen ocupacional',
    subTotal: '100 Soles',
    total: '118 Soles',
    tipoPago: 'Crédito',
    diasCredito: '15 días',
    estadoPago: 'Por Cobrar',
  },
  {
    fecha: '02/04/22',
    nombreoRazonSocial: 'Casagrande SAC',
    ruc: '123456789012',
    tipoComprobante: 'Factura',
    numComprobante: 'F001-1824',
    concepto: 'Examen ocupacional',
    subTotal: '100 Soles',
    total: '118 Soles',
    tipoPago: 'Crédito',
    diasCredito: '15 días',
    estadoPago: 'Pendiente',
  },
];

function MainSales() {
  return (
    <Wraper>
      <HeaderDashboard text="Ventas">
        <PrimaryButton text="Nuevo" blue />
      </HeaderDashboard>
      <div className="w-full px-8">
        <Table headItems={headItems} bodyItems={dataFake} />
      </div>
      <footer className="w-full p-4 flex justify-center items-center">
        <Pagination />
      </footer>
    </Wraper>
  );
}

MainSales.propTypes = {};

export default MainSales;
