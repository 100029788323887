import React from 'react';
// import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '../../../_desingModels/layout/components/form/Input/Input';
import ErrorMessage from '../../../_desingModels/layout/components/form/Message/Message';
import InputSend from '../../../_desingModels/layout/components/form/inputSend/InputSend';

function ForgotPassword() {
  const navigate = useNavigate();

  const ForgotPassSchema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required().max(10),
  });
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ForgotPassSchema),
  });

  const onSubmit = (data) => data;
  return (
    <div className="flex flex-col p-10 justify-center items-center lg:flex-row lg:justify-between ">
      <form
        className="w-full mt-20 md:border md:p-10 md:rounded-lg md:shadow-lg lg:mt-14"
        onSubmit={handleSubmit(onSubmit)}
      >
        <header className="flex flex-col gap-2 items-center justify-center mb-10">
          <h2 className="text-xl font-bold text-black/80">Forgot Password ?</h2>
          <p className="text-base font-semibold text-black/20">
            Enter your email to reset your password.
          </p>
        </header>
        <div className="flex flex-col mt-5">
          <Input
            textLabel="Email"
            type="email"
            placeholder="Email address"
            registerValue={register('email', { required: true })}
          />
          {errors.email && <ErrorMessage message={errors.email.message} />}
        </div>
        <div className="flex flex-col mt-10 gap-5 lg:flex-row">
          <InputSend type="submit" text="Submit" />

          <button
            type="button"
            className=" w-full border-0 rounded-lg p-2 bg-indigo-500/50 text-white font-bold text-lg cursor-pointer hover:bg-indigo-700"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

ForgotPassword.propTypes = {};

export default ForgotPassword;
