/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import ThirdButton from '../../../../_desingModels/layout/components/Buttons/ThirdButton';
import H1 from '../../../../_desingModels/textComponents/H1';
import ModalWithBtn from '../../../layaouts/modal/ModalWithBtn';
import Wrapper from '../../../layaouts/wrapper/Wrapper';
import DinamicFormEdit from '../dinamicForm/DinamicFormEdit';
import H2 from '../../../../_desingModels/textComponents/H2';
import PlusIconMobile from '../../../../_desingModels/iconComponets/PlusIcon';
import {
  useExamCombo,
  useGroupCombo,
  useMutateCreateExam,
} from '../../../../hooks/exams';
import ModalAddGroup from './ModalAddGroup';
import H4 from '../../../../_desingModels/textComponents/H4';
import Check from '../../../../_desingModels/iconComponets/Check';

const selectOption = [
  { value: 'input', label: 'Input' },
  { value: 'select', label: 'Select' },
  { value: 'checkbox', label: 'Checkbox' },
  { value: 'radio', label: 'Radio' },
  { value: 'titulo', label: 'Titulo' },
  { value: 'subtitulo', label: 'SubTitulo' },
  { value: 'textarea', label: 'TextArea' },
  { value: 'tableRadio', label: 'Tabla Radio/Checkbox' },
  { value: 'tableNPHE', label: 'Tabla NPHE' },
  { value: 'tableTextarea', label: 'Tabla TextArea/text' },
  { value: 'tableSelect', label: 'Tabla Select' },
  { value: 'onlyInput', label: 'Input sin Label' },
  { value: 'examOido', label: 'Datos Oido' },
];

function GenerateExam() {
  const [newForm, setNewForm] = useState();
  const [formGroup, setFormGroup] = useState();
  const [newFormView, setNewFormView] = useState();
  const [newPreview, setNewPreview] = useState();
  const [showButton, setShowButton] = useState(true);
  const [type, setType] = useState('input');
  const [typeComponent, setTypeComponent] = useState([]);
  const [tabMenu, setTabMenu] = useState([]);
  const comboExam = useExamCombo();
  const comboGroupExam = useGroupCombo();
  const { mutate, isLoading, isError, isSuccess } = useMutateCreateExam();
  /* const jsonExam = useNewExam(22);
  // const newFormData = JSON.parse(`${jsonExam?.data?.slice(0, -3).substr(19)}`);
  console.log(JSON.parse(jsonExam.data?.[0]?.[0]?.json_template)); */
  const selectTab = (campo, index) => (
    <div className="mt-4 flex flex-row justify-between">
      <label
        className="mr-6 flex flex-col  font-bold text-purpleT"
        htmlFor={`${campo.tipo}-${campo.tipo}-selecttab-${index}`}
      >
        Tab
      </label>
      <select
        className="md:w-[195px] truncate font-poppins text-purpleT font-bold rounded-md border border-purpleT pr-6 text-sm flex"
        id={`${campo.tipo}-${campo.tipo}-selecttab-${index}`}
        name={`${campo.tipo}-${campo.tipo}-selecttab-${index}`}
      >
        {tabMenu.map((op, i) => (
          <option key={i} value={op}>
            {op}
          </option>
        ))}
      </select>
    </div>
  );

  const generarCampo = (campo, index) => {
    switch (campo.tipo) {
      case 'input':
        return (
          <div className="flex flex-col">
            <div>
              <H2 text="Tipo: Input" />
            </div>
            <div className="flex gap-16">
              <section className="w-full flex flex-col">
                {selectTab(campo, index)}
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="mr-6 flex flex-col  font-bold text-purpleT"
                    htmlFor={`input-${campo.tipo}-select-${index}`}
                  >
                    SubTipo
                  </label>
                  <select
                    className="md:w-[195px] truncate font-poppins text-purpleT font-bold rounded-md border border-purpleT pr-6 text-sm flex"
                    id={`input-${campo.tipo}-select-${index}`}
                    name={`input-${campo.tipo}-select-${index}`}
                  >
                    <option value="text">Text</option>
                    <option value="number">Number</option>
                    <option value="date">Date</option>
                  </select>
                </div>
              </section>
              <section className="w-full flex flex-col">
                <div className="mt-4 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`input-${campo.tipo}-nombre-${index}`}
                  >
                    Nombre
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`input-${campo.tipo}-nombre-${index}`}
                    name={`input-${campo.tipo}-nombre-${index}`}
                    type="text"
                  />
                </div>
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`input-${campo.tipo}-label-${index}`}
                  >
                    Label
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`input-${campo.tipo}-label-${index}`}
                    name={`input-${campo.tipo}-label-${index}`}
                    type="text"
                  />
                </div>
              </section>
            </div>
          </div>
        );
      case 'select':
        return (
          <div className="flex flex-col">
            <div className="flex flex-row justify-between">
              <H2 text="Tipo: Select" />
            </div>
            <div className="flex gap-16">
              <section className="w-full flex flex-col">
                {selectTab(campo, index)}
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`select-${campo.tipo}-nombre-${index}`}
                  >
                    Nombre
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`select-${campo.tipo}-nombre-${index}`}
                    name={`select-${campo.tipo}-nombre-${index}`}
                    type="text"
                  />
                </div>
              </section>
              <section className="w-full flex flex-col">
                <div className="mt-4 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`select-${campo.tipo}-label-${index}`}
                  >
                    Label
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`select-${campo.tipo}-label-${index}`}
                    name={`select-${campo.tipo}-label-${index}`}
                    type="text"
                  />
                </div>
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`select-${campo.tipo}-opcion-${index}`}
                  >
                    Opciones
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`select-${campo.tipo}-opcion-${index}`}
                    name={`select-${campo.tipo}-opcion-${index}`}
                    type="text"
                  />
                </div>
              </section>
            </div>
          </div>
        );
      case 'checkbox':
        return (
          <div className="flex flex-col">
            <div className="flex flex-row justify-between">
              <H2 text="Tipo: Checkbox" />
            </div>
            <div className="flex flex-row gap-16">
              <section className="w-full flex flex-col">
                {selectTab(campo, index)}
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`checkbox-${campo.tipo}-nombre-${index}`}
                  >
                    Nombre
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`checkbox-${campo.tipo}-nombre-${index}`}
                    name={`checkbox-${campo.tipo}-nombre-${index}`}
                    type="text"
                  />
                </div>
              </section>
              <section className="w-full flex flex-col">
                <div className="mt-4 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`checkbox-${campo.tipo}-label-${index}`}
                  >
                    Label
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`checkbox-${campo.tipo}-label-${index}`}
                    name={`checkbox-${campo.tipo}-label-${index}`}
                    type="text"
                  />
                </div>
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`checkbox-${campo.tipo}-opcion-${index}`}
                  >
                    Opciones
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`checkbox-${campo.tipo}-opcion-${index}`}
                    name={`checkbox-${campo.tipo}-opcion-${index}`}
                    type="text"
                  />
                </div>
              </section>
            </div>
          </div>
        );
      case 'radio':
        return (
          <div className="flex flex-col">
            <div className="flex flex-row justify-between">
              <H2 text="Tipo: Radio" />
            </div>
            <div className="flex flex-row gap-16">
              <section className="w-full flex flex-col">
                {selectTab(campo, index)}
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`radio-${campo.tipo}-nombre-${index}`}
                  >
                    Nombre
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`radio-${campo.tipo}-nombre-${index}`}
                    name={`radio-${campo.tipo}-nombre-${index}`}
                    type="text"
                  />
                </div>
              </section>
              <section className="w-full flex flex-col">
                <div className="mt-4 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`radio-${campo.tipo}-label-${index}`}
                  >
                    Label
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`radio-${campo.tipo}-label-${index}`}
                    name={`radio-${campo.tipo}-label-${index}`}
                    type="text"
                  />
                </div>
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`radio-${campo.tipo}-opcion-${index}`}
                  >
                    Opciones
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`radio-${campo.tipo}-opcion-${index}`}
                    name={`radio-${campo.tipo}-opcion-${index}`}
                    type="text"
                  />
                </div>
              </section>
            </div>
          </div>
        );
      case 'textarea':
        return (
          <div className="flex flex-col">
            <div className="flex flex-row justify-between">
              <H2 text="Tipo: TextArea" />
            </div>
            <div className="flex flex-row gap-16">
              <section className="w-full flex flex-col">
                {selectTab(campo, index)}
              </section>
              <section className="w-full flex flex-col">
                <div className="mt-4 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`textarea-${campo.tipo}-nombre-${index}`}
                  >
                    Nombre
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`textarea-${campo.tipo}-nombre-${index}`}
                    name={`textarea-${campo.tipo}-nombre-${index}`}
                    type="text"
                  />
                </div>
                <div className=" mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col font-bold text-purpleT"
                    htmlFor={`textarea-${campo.tipo}-label-${index}`}
                  >
                    Label
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`textarea-${campo.tipo}-label-${index}`}
                    name={`textarea-${campo.tipo}-label-${index}`}
                    type="text"
                  />
                </div>
              </section>
            </div>
          </div>
        );
      case 'tableRadio':
        return (
          <div className="flex flex-col">
            <div className="flex flex-row justify-between">
              <H2 text="Tipo: Table con Radio/Checkbox Button" />
            </div>
            <div className="flex flex-row gap-16">
              <section className="w-full flex flex-col">
                {selectTab(campo, index)}
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`tableRadio-${campo.tipo}-nombre-${index}`}
                  >
                    Titulo
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`tableRadio-${campo.tipo}-nombre-${index}`}
                    name={`tableRadio-${campo.tipo}-nombre-${index}`}
                    type="text"
                  />
                </div>
              </section>
              <section className="w-full flex flex-col">
                <div className="mt-4 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`tableRadio-${campo.tipo}-opcionlabel-${index}`}
                  >
                    Label Radio
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`tableRadio-${campo.tipo}-opcionlabel-${index}`}
                    name={`tableRadio-${campo.tipo}-opcionlabel-${index}`}
                    type="text"
                  />
                </div>
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`tableRadio-${campo.tipo}-opcionname-${index}`}
                  >
                    Name Radio
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`tableRadio-${campo.tipo}-opcionname-${index}`}
                    name={`tableRadio-${campo.tipo}-opcionname-${index}`}
                    type="text"
                  />
                </div>
              </section>
            </div>
            <div className="my-1 flex flex-row gap-2 items-center">
              <label
                className="flex flex-col  font-bold text-purpleT"
                htmlFor={`tableRadio-${campo.tipo}-anadir-${index}`}
              >
                Añadir Input text
              </label>
              <input
                className="bg-white border border-purpleT"
                id={`tableRadio-${campo.tipo}-anadir-${index}`}
                name={`tableRadio-${campo.tipo}-anadir-${index}`}
                type="checkbox"
              />
            </div>
            <div className="my-1 flex flex-row gap-2 items-center">
              <label
                className="flex flex-col  font-bold text-purpleT"
                htmlFor={`tableRadio-${campo.tipo}-cambiar-${index}`}
              >
                Cambiar a Checkbox
              </label>
              <input
                className="bg-white border border-purpleT"
                id={`tableRadio-${campo.tipo}-cambiar-${index}`}
                name={`tableRadio-${campo.tipo}-cambiar-${index}`}
                type="checkbox"
              />
            </div>
          </div>
        );
      case 'tableNPHE':
        return (
          <div className="flex flex-col">
            <div className="flex flex-row justify-between">
              <H2 text="Tipo: Tabla NPHE" />
            </div>
            <div className="flex flex-row gap-16">
              <section className="w-full flex flex-col">
                {selectTab(campo, index)}
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`tableNPHE-${campo.tipo}-nombre-${index}`}
                  >
                    Titulo
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`tableNPHE-${campo.tipo}-nombre-${index}`}
                    name={`tableNPHE-${campo.tipo}-nombre-${index}`}
                    type="text"
                  />
                </div>
              </section>
              <section className="w-full flex flex-col">
                <div className="mt-4 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`tableNPHE-${campo.tipo}-opcionlabel-${index}`}
                  >
                    Label Radio
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`tableNPHE-${campo.tipo}-opcionlabel-${index}`}
                    name={`tableNPHE-${campo.tipo}-opcionlabel-${index}`}
                    type="text"
                  />
                </div>
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`tableNPHE-${campo.tipo}-opcionname-${index}`}
                  >
                    Name Radio
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`tableNPHE-${campo.tipo}-opcionname-${index}`}
                    name={`tableNPHE-${campo.tipo}-opcionname-${index}`}
                    type="text"
                  />
                </div>
              </section>
            </div>
          </div>
        );
      case 'tableTextarea':
        return (
          <div className="flex flex-col">
            <div className="flex flex-row justify-between">
              <H2 text="Tipo: Tabla TextArea/ Input Text" />
            </div>
            <div className="flex flex-row gap-16">
              <section className="w-full flex flex-col">
                {selectTab(campo, index)}
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`tableTextarea-${campo.tipo}-nombre-${index}`}
                  >
                    Titulo
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`tableTextarea-${campo.tipo}-nombre-${index}`}
                    name={`tableTextarea-${campo.tipo}-nombre-${index}`}
                    type="text"
                  />
                </div>
              </section>
              <section className="w-full flex flex-col">
                <div className="mt-4 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`tableTextarea-${campo.tipo}-opcionlabel-${index}`}
                  >
                    Label TextArea
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`tableTextarea-${campo.tipo}-opcionlabel-${index}`}
                    name={`tableTextarea-${campo.tipo}-opcionlabel-${index}`}
                    type="text"
                  />
                </div>
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`tableTextarea-${campo.tipo}-opcionname-${index}`}
                  >
                    Name TextArea
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`tableTextarea-${campo.tipo}-opcionname-${index}`}
                    name={`tableTextarea-${campo.tipo}-opcionname-${index}`}
                    type="text"
                  />
                </div>
              </section>
            </div>
            <div className="my-1 flex flex-row gap-2 items-center">
              <label
                className="flex flex-col  font-bold text-purpleT"
                htmlFor={`tableTextarea-${campo.tipo}-anadir-${index}`}
              >
                Cambiar a Input text
              </label>
              <input
                className="bg-white border border-purpleT"
                id={`tableTextarea-${campo.tipo}-anadir-${index}`}
                name={`tableTextarea-${campo.tipo}-anadir-${index}`}
                type="checkbox"
              />
            </div>
          </div>
        );
      case 'tableSelect':
        return (
          <div className="flex flex-col">
            <div className="flex flex-row justify-between">
              <H2 text="Tipo: Tabla Select" />
            </div>
            <div className="flex flex-row gap-16">
              <section className="w-full flex flex-col">
                {selectTab(campo, index)}
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`tableSelect-${campo.tipo}-nombre-${index}`}
                  >
                    Titulo
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`tableSelect-${campo.tipo}-nombre-${index}`}
                    name={`tableSelect-${campo.tipo}-nombre-${index}`}
                    type="text"
                  />
                </div>
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`tableSelect-${campo.tipo}-subnombre-${index}`}
                  >
                    SubTitulo
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`tableSelect-${campo.tipo}-subnombre-${index}`}
                    name={`tableSelect-${campo.tipo}-subnombre-${index}`}
                    type="text"
                  />
                </div>
              </section>
              <section className="w-full flex flex-col">
                <div className="mt-4 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`tableSelect-${campo.tipo}-opcionlabel-${index}`}
                  >
                    Label Select
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`tableSelect-${campo.tipo}-opcionlabel-${index}`}
                    name={`tableSelect-${campo.tipo}-opcionlabel-${index}`}
                    type="text"
                  />
                </div>
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`tableSelect-${campo.tipo}-opcionname-${index}`}
                  >
                    Name Select
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`tableSelect-${campo.tipo}-opcionname-${index}`}
                    name={`tableSelect-${campo.tipo}-opcionname-${index}`}
                    type="text"
                  />
                </div>
              </section>
            </div>
          </div>
        );
      case 'onlyInput':
        return (
          <div className="flex flex-col">
            <div className="flex flex-row justify-between">
              <H2 text="Tipo: Tabla input sin Label" />
            </div>
            <div className="flex flex-row gap-16">
              <section className="w-full flex flex-col">
                {selectTab(campo, index)}
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`onlyInput-${campo.tipo}-nombre-${index}`}
                  >
                    Titulo
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`onlyInput-${campo.tipo}-nombre-${index}`}
                    name={`onlyInput-${campo.tipo}-nombre-${index}`}
                    type="text"
                  />
                </div>
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`onlyInput-${campo.tipo}-subnombrefirst-${index}`}
                  >
                    SubTitulo 1
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`onlyInput-${campo.tipo}-subnombrefirst-${index}`}
                    name={`onlyInput-${campo.tipo}-subnombrefirst-${index}`}
                    type="text"
                  />
                </div>
              </section>
              <section className="w-full flex flex-col">
                <div className="mt-4 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`onlyInput-${campo.tipo}-subnombresecond-${index}`}
                  >
                    SubTitulo 2
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`onlyInput-${campo.tipo}-subnombresecond-${index}`}
                    name={`onlyInput-${campo.tipo}-subnombresecond-${index}`}
                    type="text"
                  />
                </div>
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`onlyInput-${campo.tipo}-subnombrethird-${index}`}
                  >
                    SubTitulo 3
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`onlyInput-${campo.tipo}-subnombrethird-${index}`}
                    name={`onlyInput-${campo.tipo}-subnombrethird-${index}`}
                    type="text"
                  />
                </div>
                <div className="mt-1 flex flex-row justify-between">
                  <label
                    className="flex flex-col  font-bold text-purpleT"
                    htmlFor={`onlyInput-${campo.tipo}-subnombrefourth-${index}`}
                  >
                    SubTitulo 4
                  </label>
                  <input
                    className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                    id={`onlyInput-${campo.tipo}-subnombrefourth-${index}`}
                    name={`onlyInput-${campo.tipo}-subnombrefourth-${index}`}
                    type="text"
                  />
                </div>
              </section>
            </div>
          </div>
        );
      case 'examOido':
        return (
          <div className="flex flex-col">
            <div className="flex flex-row justify-between">
              <H2 text="Tipo: Tabla Oido" />
            </div>
            <div className="w-fit flex flex-col">{selectTab(campo, index)}</div>
          </div>
        );
      case 'titulo':
        return (
          <div className="flex flex-col">
            <div className="flex flex-row justify-between">
              <H2 text="Tipo: Título" />
            </div>
            <div className="w-fit flex flex-col">{selectTab(campo, index)}</div>
            <div className="mt-1 flex gap-2">
              <label
                className="font-bold text-purpleT"
                htmlFor={`titulo-${campo.tipo}-nombre-${index}`}
              >
                Titulo
              </label>
              <input
                className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                id={`titulo-${campo.tipo}-nombre-${index}`}
                name={`titulo-${campo.tipo}-nombre-${index}`}
                type="text"
              />
            </div>
          </div>
        );
      case 'subtitulo':
        return (
          <div className="flex flex-col">
            <div className="flex flex-row justify-between">
              <H2 text="Tipo: SubTítulo" />
            </div>
            <div className="w-fit flex flex-col">{selectTab(campo, index)}</div>
            <div className="mt-1 flex gap-2">
              <label
                className="font-bold text-purpleT"
                htmlFor={`subtitulo-${campo.tipo}-nombre-${index}`}
              >
                SubTitulo
              </label>
              <input
                className="rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                id={`subtitulo-${campo.tipo}-nombre-${index}`}
                name={`subtitulo-${campo.tipo}-nombre-${index}`}
                type="text"
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const generarId = () => {
    const r = (Math.random() + 1).toString(36).substring(5);
    return r;
  };

  const handleNewForm = (event) => {
    event.preventDefault();
    setNewForm(true);
  };
  const handleNewGroup = (event) => {
    event.preventDefault();
    setFormGroup(true);
  };

  const agregarCampo = () => {
    const idComponent = generarId();
    setTypeComponent([
      ...typeComponent,
      {
        tipo: type,
        id: idComponent,
      },
    ]);
    setShowButton(true);
  };

  const eliminarCampo = (index) => {
    setTypeComponent(typeComponent.filter((_, i) => i !== index));
    setShowButton(true);
  };
  const handleActivate = () => {
    setShowButton(false);
  };
  const handleSelect = (e) => {
    setType(e.target.value);
  };
  const handleTab = (e) => {
    setTabMenu(e.target.value.split('|'));
  };

  const submitData = () => {
    // console.log(newFormView);
    mutate(newFormView);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const newfield = typeComponent.map((campo, index) => {
      if (campo.tipo === 'input') {
        return {
          id: index,
          tipo: 'input',
          widthContent: 'w-fit',
          tabSelection: formData.get(
            `${campo.tipo}-${campo.tipo}-selecttab-${index}`
          ),
          subtipo: formData.get(`input-${campo.tipo}-select-${index}`),
          nombre: formData.get(`input-${campo.tipo}-nombre-${index}`),
          label: formData.get(`input-${campo.tipo}-label-${index}`),
        };
      }
      if (campo.tipo === 'select') {
        const options = formData.get(`select-${campo.tipo}-opcion-${index}`);
        return {
          id: index,
          tipo: 'select',
          widthContent: 'w-fit',
          tabSelection: formData.get(
            `${campo.tipo}-${campo.tipo}-selecttab-${index}`
          ),
          nombre: formData.get(`select-${campo.tipo}-nombre-${index}`),
          label: formData.get(`select-${campo.tipo}-label-${index}`),
          opciones: options.split('|'),
        };
      }
      if (campo.tipo === 'radio') {
        const options = formData.get(`radio-${campo.tipo}-opcion-${index}`);
        return {
          id: index,
          tipo: 'radio',
          widthContent: 'w-fit',
          tabSelection: formData.get(
            `${campo.tipo}-${campo.tipo}-selecttab-${index}`
          ),
          nombre: formData.get(`radio-${campo.tipo}-nombre-${index}`),
          label: formData.get(`radio-${campo.tipo}-label-${index}`),
          opciones: options.split('|'),
        };
      }
      if (campo.tipo === 'checkbox') {
        const options = formData.get(`checkbox-${campo.tipo}-opcion-${index}`);
        return {
          id: index,
          tipo: 'checkbox',
          widthContent: 'w-fit',
          tabSelection: formData.get(
            `${campo.tipo}-${campo.tipo}-selecttab-${index}`
          ),
          nombre: formData.get(`checkbox-${campo.tipo}-nombre-${index}`),
          label: formData.get(`checkbox-${campo.tipo}-label-${index}`),
          opciones: options.split('|'),
        };
      }
      if (campo.tipo === 'textarea') {
        return {
          id: index,
          tipo: 'textarea',
          widthContent: 'w-full',
          tabSelection: formData.get(
            `${campo.tipo}-${campo.tipo}-selecttab-${index}`
          ),
          nombre: formData.get(`textarea-${campo.tipo}-nombre-${index}`),
          label: formData.get(`textarea-${campo.tipo}-label-${index}`),
        };
      }
      if (campo.tipo === 'tableRadio') {
        const optionsName = formData.get(
          `tableRadio-${campo.tipo}-opcionname-${index}`
        );
        const optionsLabel = formData.get(
          `tableRadio-${campo.tipo}-opcionlabel-${index}`
        );
        const checked = formData.get(
          `tableRadio-${campo.tipo}-anadir-${index}`
        );
        const changed = formData.get(
          `tableRadio-${campo.tipo}-cambiar-${index}`
        );
        return {
          id: index,
          tipo: 'tableRadio',
          widthContent: 'w-full',
          tabSelection: formData.get(
            `${campo.tipo}-${campo.tipo}-selecttab-${index}`
          ),
          nombre: formData.get(`tableRadio-${campo.tipo}-nombre-${index}`),
          optionname: optionsName.split('|'),
          optionlabel: optionsLabel.split('|'),
          withInput: checked !== null,
          withChange: changed !== null,
        };
      }
      if (campo.tipo === 'tableNPHE') {
        const optionsName = formData.get(
          `tableNPHE-${campo.tipo}-opcionname-${index}`
        );
        const optionsLabel = formData.get(
          `tableNPHE-${campo.tipo}-opcionlabel-${index}`
        );
        return {
          id: index,
          tipo: 'tableNPHE',
          widthContent: 'w-full',
          tabSelection: formData.get(
            `${campo.tipo}-${campo.tipo}-selecttab-${index}`
          ),
          nombre: formData.get(`tableNPHE-${campo.tipo}-nombre-${index}`),
          optionname: optionsName.split('|'),
          optionlabel: optionsLabel.split('|'),
        };
      }
      if (campo.tipo === 'tableTextarea') {
        const optionsName = formData.get(
          `tableTextarea-${campo.tipo}-opcionname-${index}`
        );
        const optionsLabel = formData.get(
          `tableTextarea-${campo.tipo}-opcionlabel-${index}`
        );
        const checked = formData.get(
          `tableTextarea-${campo.tipo}-anadir-${index}`
        );
        return {
          id: index,
          tipo: 'tableTextarea',
          widthContent: 'w-full',
          tabSelection: formData.get(
            `${campo.tipo}-${campo.tipo}-selecttab-${index}`
          ),
          nombre: formData.get(`tableTextarea-${campo.tipo}-nombre-${index}`),
          optionname: optionsName.split('|'),
          optionlabel: optionsLabel.split('|'),
          withInput: checked !== null,
        };
      }
      if (campo.tipo === 'tableSelect') {
        const optionsName = formData.get(
          `tableSelect-${campo.tipo}-opcionname-${index}`
        );
        const optionsLabel = formData.get(
          `tableSelect-${campo.tipo}-opcionlabel-${index}`
        );
        return {
          id: index,
          tipo: 'tableSelect',
          widthContent: 'w-full',
          tabSelection: formData.get(
            `${campo.tipo}-${campo.tipo}-selecttab-${index}`
          ),
          nombre: formData.get(`tableSelect-${campo.tipo}-nombre-${index}`),
          subnombre: formData.get(
            `tableSelect-${campo.tipo}-subnombre-${index}`
          ),
          optionname: optionsName.split('|'),
          optionlabel: optionsLabel.split('|'),
        };
      }
      if (campo.tipo === 'onlyInput') {
        return {
          id: index,
          tipo: 'onlyInput',
          widthContent: 'w-full',
          tabSelection: formData.get(
            `${campo.tipo}-${campo.tipo}-selecttab-${index}`
          ),
          nombre: formData.get(`onlyInput-${campo.tipo}-nombre-${index}`),
          subnombrefirst: formData.get(
            `onlyInput-${campo.tipo}-subnombrefirst-${index}`
          ),
          subnombresecond: formData.get(
            `onlyInput-${campo.tipo}-subnombresecond-${index}`
          ),
          subnombrethird: formData.get(
            `onlyInput-${campo.tipo}-subnombrethird-${index}`
          ),
          subnombrefourth: formData.get(
            `onlyInput-${campo.tipo}-subnombrefourth-${index}`
          ),
        };
      }
      if (campo.tipo === 'examOido') {
        return {
          id: index,
          tipo: 'examOido',
          widthContent: 'w-full',
          tabSelection: formData.get(
            `${campo.tipo}-${campo.tipo}-selecttab-${index}`
          ),
        };
      }
      if (campo.tipo === 'titulo') {
        return {
          id: index,
          tipo: 'titulo',
          widthContent: 'w-full',
          tabSelection: formData.get(
            `${campo.tipo}-${campo.tipo}-selecttab-${index}`
          ),
          nombre: formData.get(`titulo-${campo.tipo}-nombre-${index}`),
        };
      }
      if (campo.tipo === 'subtitulo') {
        return {
          id: index,
          tipo: 'subtitulo',
          widthContent: 'w-full',
          tabSelection: formData.get(
            `${campo.tipo}-${campo.tipo}-selecttab-${index}`
          ),
          nombre: formData.get(`subtitulo-${campo.tipo}-nombre-${index}`),
        };
      }
      return null;
    });
    const jsonData = {
      tabs: tabMenu,
      camposAdicionales: newfield,
      nombre_examen: formData.get('nombreExamen'),
    };
    const data = {
      tipo_examen: formData.get('tipoExamen'),
      nombre_examen: formData.get('nombreExamen'),
      descripcion: formData.get('descripcion'),
      parent_id: formData.get('grupoExamen'),
      precio_minimo: formData.get('minPrice'),
      precio_normal: formData.get('normalPrice'),
      precio_alto: formData.get('highPrice'),
      json_template: JSON.stringify(jsonData),
    };
    // console.log(data);
    // mutate(data);
    console.log(data);
    setNewFormView(data);
    setNewPreview(JSON.parse(data.json_template));
  };

  return (
    <Wrapper>
      <form
        className="w-full pt-6 px-8 flex flex-col items-start"
        onSubmit={handleSubmit}
      >
        <div className="w-full flex flex-row justify-between">
          <H1 text="Nuevo Examen" />
          <div className="flex flex-row gap-4">
            <PrimaryButton
              handleClick={handleActivate}
              type="submit"
              text="Pre-Guardar"
            />
            <PrimaryButton
              disabled={showButton}
              handleClick={submitData}
              text="Guardar"
            />
            <PrimaryButton
              disabled={showButton}
              handleClick={handleNewForm}
              text="Preview Form"
            />
          </div>
          <ModalWithBtn
            opened={newForm}
            setOpened={setNewForm}
            title="Formulario de examen"
            tamanio="95%"
          >
            <DinamicFormEdit
              view
              setOpened={setNewForm}
              dataForm={newPreview}
            />
          </ModalWithBtn>
        </div>
        <div className="w-full mt-4 flex flex-wrap justify-between">
          <section className="w-[49%] flex flex-col">
            <div className="flex justify-between">
              <label className="font-bold text-purpleT" htmlFor="tipoExamen">
                Tipo de examen:
              </label>
              <select
                className="md:w-[195px] truncate font-poppins text-purpleT font-bold rounded-md border border-purpleT pr-6 text-sm flex"
                id="tipoExamen"
                name="tipoExamen"
              >
                {/* <option value="0">Escoge una opción</option> */}
                {comboExam?.data?.data?.tipo_examen.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-between">
              <label className="font-bold text-purpleT" htmlFor="nombreExamen">
                Nombre de examen:
              </label>
              <input
                className="rounded-lg p-1.5  mt-1 text-black/60 bg-white border border-purpleT"
                id="nombreExamen"
                name="nombreExamen"
                type="text"
              />
            </div>
            <div className="flex justify-between">
              <label className="font-bold text-purpleT" htmlFor="tabs">
                Tabs:
              </label>
              <input
                onBlur={handleTab}
                className="rounded-lg p-1.5  mt-1 text-black/60 bg-white border border-purpleT"
                id="tabs"
                name="tabs"
                type="text"
              />
            </div>
            <div className="flex justify-between">
              <label className="font-bold text-purpleT" htmlFor="descripcion">
                Descripción:
              </label>
              <textarea
                className="w-[195px] rounded-lg p-1.5  mt-1 text-black/60 border border-purpleT  resize-none"
                id="descripcion"
                name="descripcion"
              />
            </div>
          </section>
          <section className="w-[49%] flex flex-col">
            <div className="flex justify-between">
              <label className="font-bold text-purpleT" htmlFor="grupoExamen">
                Grupo de examen:
              </label>
              <div className="flex gap-1 justify-center items-center">
                <div
                  role="button"
                  tabIndex={0}
                  className="bg-purpleT rounded-full flex justify-center items-center w-8 h-8 p-1"
                  onClick={handleNewGroup}
                >
                  <PlusIconMobile />
                </div>
                <ModalWithBtn
                  opened={formGroup}
                  setOpened={setFormGroup}
                  title="Nuevo Grupo"
                  tamanio="xs"
                >
                  <ModalAddGroup setOpened={setFormGroup} />
                </ModalWithBtn>
                <select
                  className="md:w-[195px] truncate font-poppins text-purpleT font-bold rounded-md border border-purpleT pr-6 text-sm flex"
                  id="grupoExamen"
                  name="grupoExamen"
                >
                  {comboGroupExam?.data?.data?.grupo_examenes.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex justify-between">
              <label className="font-bold text-purpleT" htmlFor="minPrice">
                Precio mínimo:
              </label>
              <input
                className="rounded-lg p-1.5  mt-1 text-black/60 bg-white border border-purpleT"
                id="minPrice"
                name="minPrice"
                type="number"
              />
            </div>
            <div className="flex justify-between">
              <label className="font-bold text-purpleT" htmlFor="normalPrice">
                Precio normal:
              </label>
              <input
                className="rounded-lg p-1.5  mt-1 text-black/60 bg-white border border-purpleT"
                id="normalPrice"
                name="normalPrice"
                type="number"
              />
            </div>
            <div className="flex justify-between">
              <label className="font-bold text-purpleT" htmlFor="highPrice">
                Precio alto:
              </label>
              <input
                className="rounded-lg p-1.5  mt-1 text-black/60 bg-white border border-purpleT"
                id="highPrice"
                name="highPrice"
                type="number"
              />
            </div>
          </section>
        </div>
        <div>
          {isLoading ? (
            <div className="flex gap-2">
              <div className="animate-spin h-5 w-5 rounded-full border-4 border-l-azul" />
              <H4 text="Cargando" />
            </div>
          ) : (
            <>
              {isError ? <H4 text="error" /> : null}

              {isSuccess ? (
                <div className="flex gap-2">
                  <Check />
                  <H4 text="Datos Guardados" />
                </div>
              ) : null}
            </>
          )}
        </div>
        <div className="my-6 border border-purpleT w-[calc(100%-50px)]" />
        {typeComponent.map((campo, index) => (
          <div id={campo.id} key={campo.id} className="w-[calc(100%-50px)]">
            {generarCampo(campo, index)}
            <ThirdButton
              text="Eliminar"
              handleClick={() => eliminarCampo(index, campo)}
            />
            <div className="my-6 border border-purpleT w-full" />
          </div>
        ))}
        <section className="flex gap-2 mb-4">
          <select
            onChange={handleSelect}
            className="w-[150px] truncate font-poppins text-purpleT font-bold rounded-md border border-purpleT pr-6 text-sm"
          >
            {selectOption.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <PrimaryButton text="Nuevo Campo" handleClick={agregarCampo} />
        </section>
      </form>
    </Wrapper>
  );
}

export default GenerateExam;
