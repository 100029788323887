/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Table } from '@mantine/core';
import classNames from '../../../../_desingModels/utils/classNames';
import Wraper from '../../../layaouts/wrapper/Wrapper';
import dataMock from './dataMock';
import HeaderDashHorizont from '../../../layaouts/headerDashboard/HeaderDashHorizont';
import ModalWithBtn from '../../../layaouts/modal/ModalWithBtn';
import ModalAddPettyCash from './ModalAddPettyCash';
import Pagination from '../../../layaouts/pagination/Pagination';

function MainPettyCash({ data = dataMock }) {
  const [openedModal, setOpenedModal] = useState(false);
  const ths = (
    <tr>
      <th>N°</th>
      <th>Fecha</th>
      <th>Nombre/Razón Social</th>
      <th>ID</th>
      <th>Fecha Comprobante</th>
      <th>Concepto</th>
      <th>Responsable</th>
      <th>Sustento</th>
      <th>Importe</th>
      <th>Saldo</th>
    </tr>
  );

  const rows = data?.map((element) => (
    <tr key={element.no_cotizacion}>
      <td>{element.number}</td>
      <td>{element.date}</td>
      <td>{element.name}</td>
      <td>{element.id}</td>
      <td>{element.dateComprobante}</td>
      <td>{element.concept}</td>
      <td>{element.responsable}</td>
      <td>
        <div className="p-2">
          <p
            className={classNames(
              'text-white py-1 px-2 rounded-md w-full text-center',
              element.support === true
                ? 'bg-teal-600 hover:bg-teal-700'
                : 'bg-purpleT hover:bg-indigo-700'
            )}
          >
            Ver
          </p>
        </div>
      </td>
      <td>
        <p
          className={classNames(
            // eslint-disable-next-line no-nested-ternary
            element.import > 0
              ? 'text-blue-600'
              : element.import === 0
              ? 'text-black'
              : 'text-red-600'
          )}
        >
          {element.import}
        </p>
      </td>
      <td>
        <p
          className={classNames(
            // eslint-disable-next-line no-nested-ternary
            element.balance > 0
              ? 'text-blue-600'
              : element.import === 0
              ? 'text-black'
              : 'text-red-600'
          )}
        >
          {element.import}
        </p>
      </td>
    </tr>
  ));

  return (
    <div className="flex w-full min-h-full flex-col lg:flex-row gap-3">
      <Wraper nofullScreen>
        <HeaderDashHorizont title="Caja Chica" handleClick={setOpenedModal}>
          <ModalWithBtn
            opened={openedModal}
            setOpened={setOpenedModal}
            title="Caja Chica"
          >
            <ModalAddPettyCash setOpened={setOpenedModal} />
          </ModalWithBtn>
        </HeaderDashHorizont>
        <section className="w-full p-4 overflow-x-scroll">
          <Table highlightOnHover>
            <thead>{ths}</thead>
            <tbody>{rows}</tbody>
          </Table>
        </section>
        <Pagination />
      </Wraper>
    </div>
  );
}

MainPettyCash.propTypes = {};

export default MainPettyCash;
