import React from 'react';

// import PropTypes from 'prop-types'
import { Notification } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import Input from '../../../../_desingModels/layout/components/form/Input/Input';
import ErrorMessage from '../../../../_desingModels/layout/components/form/Message/Message';
import CheckCircleIcon from '../../../../_desingModels/iconComponets/CheckCircle';
import XIcon from '../../../../_desingModels/iconComponets/XIcon';
// import InputSend from '../../../../_desingModels/layout/components/form/inputSend/InputSend';
import Select from '../../../../_desingModels/layout/components/select/Select';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
// import Line from '../../../../_desingModels/layout/components/Line';

// import { useMutateClient } from '../../../../hooks/clients';
import H2 from '../../../../_desingModels/textComponents/H2';
import MultiSelectComponent from '../../../../_desingModels/layout/components/select/MultiSelect';
import { useMutateExam } from '../../../../hooks/exams';

function ModalFormExams({ examsData = [], setOpened, permissionData, userId }) {
  // console.log(examsData, 'examsData');
  const dataSchema = yup.object().shape({
    arrayExam: yup.array().min(1).required('*obl'),
    permission: yup
      .number('*obl')
      .typeError('*obl')
      .required('*obl')
      .positive('*obl')
      .integer('*obl'),
  });
  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(dataSchema),
  });
  const {
    mutate,
    isLoading,
    isSuccess,
    isError,
    reset: resetQuery,
  } = useMutateExam();

  const onSubmit = (data) => {
    // console.log(userId, 'usuario');
    const dataExam = {
      id_usuario: userId,
      id_permiso: data.permission,
      array_examen: data.arrayExam,
    };
    // console.log(dataExam, 'dataExam');
    mutate(dataExam);
    reset();
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pb-5 w-full flex flex-col gap-5 mt-10 justify-center items-center"
      >
        <div className="flex w-full gap-2 lg:px-10">
          <H2 text="Exámen" blue />
          {/* <div className="w-full">
            <Select
              itemArray={permissionData}
              modal
              setValue={setValue}
              registerValue={register('roles', { required: true })}
              idSelect="roles"
              isSelect
            />
            {errors.roles && <ErrorMessage xs message={errors.roles.message} />}
          </div> */}
          <div className="w-full md:w-fit">
            <Select
              itemArray={permissionData}
              modal
              setValue={setValue}
              registerValue={register('permission', { required: true })}
              idSelect="permission"
              selectBd
              defaultValue="Permisos"
            />
            {permissionData.length > 0 ? (
              errors.permission && (
                <ErrorMessage xs message={errors.permission.message} />
              )
            ) : (
              <ErrorMessage xs message="No hay permisos registrados" />
            )}
          </div>
        </div>

        <div className="w-full lg:px-10">
          {examsData.length > 0 && (
            <Controller
              control={control}
              name="arrayExam"
              render={({ field }) => (
                <MultiSelectComponent
                  placeholder="Buscar Exámen"
                  data={examsData}
                  onChange={(value) => field.onChange(value)}
                  selected={field.value}
                />
              )}
            />
          )}
          {examsData.length > 0 ? (
            errors.arrayExam && (
              <ErrorMessage xs message={errors.arrayExam.message} />
            )
          ) : (
            <ErrorMessage
              xs
              message="No hay exámenes disponibles, contactar con soporte"
            />
          )}
        </div>

        {/* <div className="pt-6">
        <Line />
      </div> */}
        <div className="pt-8 w-full flex flex-col gap-2 justify-center items-center md:flex-row">
          <PrimaryButton type="input" text="Guardar" blue />
          <PrimaryButton
            text="Cancelar"
            modal
            handleClick={() => setOpened(false)}
          />
        </div>
      </form>
      {isLoading ? (
        <Notification
          loading
          title="Uploading data to the server"
          disallowClose
        >
          Espere hasta que se carguen los datos, no puede cerrar esta
          notificación aún
        </Notification>
      ) : (
        <>
          {isError ? (
            <Notification
              icon={<XIcon size={18} />}
              color="red"
              title="Error"
              onClose={resetQuery}
            >
              Ups! Hubo un error al guardar los datos
            </Notification>
          ) : null}

          {isSuccess ? (
            <Notification
              icon={<CheckCircleIcon size={18} />}
              color="teal"
              title="Correcto"
              onClose={resetQuery}
            >
              Se guardarón los datos correctamente
            </Notification>
          ) : null}
        </>
      )}
    </>
  );
}

ModalFormExams.propTypes = {};

export default ModalFormExams;
