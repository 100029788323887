/* eslint-disable react/no-array-index-key */
import React from 'react';
// import PropTypes from 'prop-types'

import TableContainer from '../../../../_desingModels/layout/components/table/TableContainer';
import TBody from '../../../../_desingModels/layout/components/table/TBody';
import Thead from '../../../../_desingModels/layout/components/table/Thead';
import Tr from '../../../../_desingModels/layout/components/table/Tr';
import Th from '../../../../_desingModels/layout/components/table/Th';
import Td from '../../../../_desingModels/layout/components/table/Td';
import classNames from '../../../../_desingModels/utils/classNames';
import GenericButton from '../../../../_desingModels/layout/components/Buttons/GenericButton';

function Table({ headItems, bodyItems }) {
  return (
    <>
      <div className="md:hidden">
        <TableContainer>
          <Thead>
            <Tr>
              {headItems.map((item, index) => (
                <Th key={index}>{item.name}</Th>
              ))}
            </Tr>
          </Thead>
          <TBody>
            {bodyItems.map((item, index) => (
              <Tr key={index}>
                <Td full mainText={item.fecha} />
                <Td full mainText={item.nombreoRazonSocial} />
                <Td full mainText={item.ruc} />
                <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap text-center">
                  <div className="flex flex-col justify-center items-center">
                    <p>{item.tipoComprobante}</p>
                    <p>{item.numComprobante}</p>
                  </div>
                </td>
                <Td full mainText={item.concepto} />
                <Td full mainText={item.subTotal} />
                <Td full mainText={item.total} />
                <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap text-center">
                  <div className="flex flex-col justify-center items-center">
                    <p>{item.tipoPago}</p>
                    <p>{item.diasCredito}</p>
                  </div>
                </td>
                <td>
                  <div
                    className={classNames(
                      'rounded-md px-2 py-1 text-center text-gray-900 font-medium',
                      item.estadoPago === 'Cancelado' && 'bg-azul2/30',
                      item.estadoPago === 'Pendiente' && 'bg-rojo1/30',
                      item.estadoPago === 'Por Cobrar' && 'bg-verde1/30'
                    )}
                  >
                    {item.estadoPago}
                  </div>
                </td>
                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap flex justify-center">
                  <GenericButton classNames="bg-azul2 px-3 py-3 rounded-md text-blanc1 hover:bg-azul">
                    Detalle
                  </GenericButton>
                </td>
              </Tr>
            ))}
          </TBody>
        </TableContainer>
      </div>

      <div className="hidden md:flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 ">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full">
                <thead className="border-b">
                  <tr>
                    {headItems.map((item, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="text-sm font-medium text-blue-800 px-3 py-3 text-center"
                      >
                        {item.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {bodyItems.map((item, index) => (
                    <tr key={index} className="border-b">
                      <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap text-center">
                        {item.fecha}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap text-center">
                        {item.nombreoRazonSocial}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap text-center">
                        {item.ruc}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap text-center">
                        <div className="flex flex-col justify-center items-center">
                          <p>{item.tipoComprobante}</p>
                          <p>{item.numComprobante}</p>
                        </div>
                      </td>
                      <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap text-center">
                        {item.concepto}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap text-center">
                        {item.subTotal}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap text-center">
                        {item.total}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap text-center">
                        <div className="flex flex-col justify-center items-center">
                          <p>{item.tipoPago}</p>
                          <p>{item.diasCredito}</p>
                        </div>
                      </td>
                      <td className="text-sm text-gray-900 font-light px-3 py-1 whitespace-nowrap">
                        <div
                          className={classNames(
                            'rounded-md px-2 py-1 text-center text-gray-900 font-medium',
                            item.estadoPago === 'Cancelado' && 'bg-azul2/30',
                            item.estadoPago === 'Pendiente' && 'bg-rojo1/30',
                            item.estadoPago === 'Por Cobrar' && 'bg-verde1/30'
                          )}
                        >
                          <p className="text-negro">{item.estadoPago}</p>
                        </div>
                      </td>
                      <td className="text-sm text-gray-900 font-light pl-4 py-2 whitespace-nowrap">
                        <GenericButton classNames="bg-azul2 px-6 py-2 rounded-md text-blanc1 hover:bg-azul">
                          Detalle
                        </GenericButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Table.propTypes = {};

export default Table;
