/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React from 'react';
// import PropTypes from 'prop-types'
// import { Tooltip } from '@mantine/core';
import Card from '../../../_desingModels/layout/components/card/Card';
import HeaderCard from '../../../_desingModels/layout/components/card/HeaderCard';
import H3 from '../../../_desingModels/textComponents/H3';
import P from '../../../_desingModels/textComponents/P';
import H4 from '../../../_desingModels/textComponents/H4';
// import CardIcon from '../../../_desingModels/utils/CardIcon';
import classNames from '../../../_desingModels/utils/classNames';

function UserCard({ user, handleClickDestailUser }) {
  // console.log(user);
  // console.log(user?.roles, 'roles');
  return (
    <Card
      classNames={classNames(
        'flex flex-col border-2 rounded-md w-56 border-azul2 h-46'
      )}
    >
      <HeaderCard classNames={classNames('text-blanc1 p-2 bg-azul2 h-17')}>
        <div className="flex flex-wrap gap-2 h-10 mb-3">
          <H3 text={user?.nombres} />
          <H3 text={user?.apellidos} />
        </div>
        <div className="truncate">
          <P
            text={user?.roles?.map((role, index) =>
              index === 0 ? role.rol : `, ${role.rol}`
            )}
          />
        </div>
      </HeaderCard>

      <section>
        <div className="text-gray-800 m-2 truncate">
          <H4 text={user?.email} />

          <div className="flex flex-col pr-2 pt-1 w-full ">
            <H4 text="Módulos" blue />
            <section className="flex gap-2 w-full flex-wrap ">
              <P
                text={user?.modulos?.map((item, index) =>
                  index === 0 ? item.modulo : `, ${item.modulo}`
                )}
              />
            </section>
          </div>
        </div>
        <div className="p-2">
          <button
            className={classNames(
              'text-white py-1 px-2 rounded-md w-5/12 bg-azul2 hover:bg-indigo-700'
            )}
            type="button"
            onClick={(e) => {
              handleClickDestailUser(e, user);
            }}
          >
            Editar
          </button>
        </div>
      </section>
    </Card>
  );
}

// UserCard.propTypes = {}

export default UserCard;
