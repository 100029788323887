/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderDashVertical from '../../../layaouts/headerDashboard/HeaderDashVertical';
import HeaderDashHorizont from '../../../layaouts/headerDashboard/HeaderDashHorizont';
import Wraper from '../../../layaouts/wrapper/Wrapper';
import PatientCard from '../../../layaouts/cards/PatientCard';
import Pagination from '../../../layaouts/pagination/Pagination';
import Select from '../../../../_desingModels/layout/components/select/Select';
import { usePatient } from '../../../../hooks/patients';
// import StickTable from '../../../../_desingModels/layout/components/table/StickTable';
import CardContainer from '../../../layaouts/container/CardContainer';
import Loading from '../../../../_desingModels/layout/components/loading/Loading';
import Notification from '../../../../_desingModels/layout/components/notifications/Notification';

const option = [
  { name: 'Nombres y apellidos', id: 1 },
  { name: 'DNI', id: 2 },
  { name: 'Empresa', id: 3 },
];
const option1 = [
  { name: 'Por atención', id: 1 },
  { name: 'Atendiendose', id: 2 },
  { name: 'Atendiendose', id: 3 },
  { name: 'Completo', id: 4 },
  { name: 'Observado', id: 5 },
];
const option2 = [
  { name: 'Por Examén', id: 1 },
  { name: 'Preocupacional', id: 2 },
  { name: 'Periódico', id: 3 },
  { name: 'Preventivo', id: 4 },
  { name: 'Retiro', id: 5 },
  { name: 'Reubicación', id: 6 },
  { name: 'Otros', id: 7 },
  { name: 'Visita', id: 8 },
  { name: 'Reincorporación laboral', id: 9 },
];

const option3 = [
  { name: 'Aptitud', id: 1 },
  { name: 'Apto', id: 2 },
  { name: 'No apto', id: 3 },
  { name: 'Observado', id: 4 },
  { name: 'Apto con restricción', id: 5 },
  { name: 'Evaluado', id: 6 },
  { name: 'Pendiente', id: 7 },
];
function MainPatients({ handleClickDetailPatient }) {
  const navigate = useNavigate();
  const { isLoading, isError, data } = usePatient();

  const handleClick = (event) => {
    event.preventDefault();
    navigate('../pacientes/nuevo');
  };
  // console.log(data);
  return (
    <div className="flex w-full flex-col lg:flex-row gap-3">
      <HeaderDashVertical text="Pacientes" select={option}>
        {null}
      </HeaderDashVertical>
      <Wraper>
        <HeaderDashHorizont title="Últimos Pacientes" handleClick={handleClick}>
          <Select itemArray={option1} />
          <Select itemArray={option2} />
          <Select itemArray={option3} />
        </HeaderDashHorizont>
        <CardContainer>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {isError ? (
                <Notification message="Ups hubo un error, intentar más tarde!" />
              ) : (
                data.data.patients?.map((paciente, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <PatientCard
                    key={index}
                    patient={paciente}
                    moduleType="patient"
                    handleClickDetailPatient={handleClickDetailPatient}
                  />
                ))
              )}
            </>
          )}
        </CardContainer>
        {/* <StickTable
          patient={data?.data?.patients}
          handleClickDetailPatient={handleClickDetailPatient}
        /> */}
        <footer className="w-full p-4 flex flex-col gap-5">
          {isLoading ? null : <>{isError ? null : <Pagination />}</>}
        </footer>
      </Wraper>
    </div>
  );
}

MainPatients.propTypes = {};

export default MainPatients;
