import { Loader } from '@mantine/core';

function Loading() {
  return (
    <div className="w-full flex justify-center items-center flex-1">
      <Loader />
    </div>
  );
}

export default Loading;
