/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
// import PropTypes from 'prop-types'
import HeaderDashboard from '../../../layaouts/headerDashboard/HeaderDashboard';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import Wraper from '../../../layaouts/wrapper/Wrapper';
import CardContainer from '../../../layaouts/container/CardContainer';
import Pagination from '../../../layaouts/pagination/Pagination';
import ProtocolsCard from '../../../layaouts/cards/ProtocolsCard';
import ModalWithBtn from '../../../layaouts/modal/ModalWithBtn';
import ModalAddProtocol from './ModalAddProtocol';

const protocols = [
  {
    state: 'Primera Revisión',
    date: '12/05/22',
    quoteName: 'Nombre cotización',
    protocolo: 'Preocupacional',
    company: 'CASAGRANDE SAC',
    ruc: '1234567890123',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    state: 'Aceptada',
    date: '12/05/22',
    quoteName: 'Nombre cotización',
    protocolo: 'Preocupacional',
    company: 'CASAGRANDE SAC',
    ruc: '1234567890123',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    state: 'Primera Revisión',
    date: '12/05/22',
    quoteName: 'Nombre cotización',
    protocolo: 'Preocupacional',
    company: 'CASAGRANDE SAC',
    ruc: '1234567890123',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    state: 'Aceptada',
    date: '12/05/22',
    quoteName: 'Nombre cotización',
    protocolo: 'Preocupacional',
    company: 'CASAGRANDE SAC',
    ruc: '1234567890123',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    state: 'Primera Revisión',
    date: '12/05/22',
    quoteName: 'Nombre cotización',
    protocolo: 'Preocupacional',
    company: 'CASAGRANDE SAC',
    ruc: '1234567890123',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    state: 'Aceptada',
    date: '12/05/22',
    quoteName: 'Nombre cotización',
    protocolo: 'Preocupacional',
    company: 'CASAGRANDE SAC',
    ruc: '1234567890123',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    state: 'Primera Revisión',
    date: '12/05/22',
    quoteName: 'Nombre cotización',
    protocolo: 'Preocupacional',
    company: 'CASAGRANDE SAC',
    ruc: '1234567890123',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    state: 'Aceptada',
    date: '12/05/22',
    quoteName: 'Nombre cotización',
    protocolo: 'Preocupacional',
    company: 'CASAGRANDE SAC',
    ruc: '1234567890123',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    state: 'Primera Revisión',
    date: '12/05/22',
    quoteName: 'Nombre cotización',
    protocolo: 'Preocupacional',
    company: 'CASAGRANDE SAC',
    ruc: '1234567890123',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
  {
    state: 'Aceptada',
    date: '12/05/22',
    quoteName: 'Nombre cotización',
    protocolo: 'Preocupacional',
    company: 'CASAGRANDE SAC',
    ruc: '1234567890123',
    subtotal: '100 Soles',
    total: '118 Soles',
  },
];
function MainProtocols({ handleClickDetailProtocol }) {
  const [newProtocol, setNewProtocol] = useState();
  const handleNewProtocol = (event) => {
    event.preventDefault();
    setNewProtocol(true);
  };
  return (
    <div className="flex w-full flex-col lg:flex-row gap-3">
      <Wraper nofullScreen>
        <HeaderDashboard text="Protocolos">
          <PrimaryButton text="Nuevo" blue handleClick={handleNewProtocol} />
          <ModalWithBtn
            opened={newProtocol}
            setOpened={setNewProtocol}
            title="Agregar Protocolo"
          >
            <ModalAddProtocol setOpened={setNewProtocol} />
          </ModalWithBtn>
        </HeaderDashboard>

        <CardContainer>
          {protocols.map((protocol, index) => (
            <ProtocolsCard
              key={index}
              protocol={protocol}
              handleClickDetailProtocol={handleClickDetailProtocol}
            />
          ))}
        </CardContainer>
        <Pagination />
      </Wraper>
    </div>
  );
}

MainProtocols.propTypes = {};

export default MainProtocols;
