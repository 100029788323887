import React from 'react';
// import PropTypes from 'prop-types';
import { Tooltip } from '@mantine/core';
import classNames from '../utils/classNames';

function H3({ text, blue }) {
  return (
    <Tooltip label={text} color="dark" transition="fade" position="bottom">
      <h3
        className={classNames(
          'text-base md:text-sm font-bold truncate font-poppins',
          blue && 'text-azul'
        )}
      >
        {text}
      </h3>
    </Tooltip>
  );
}

// H3.propTypes = {
//   text: PropTypes.string.isRequired,
// };

export default H3;
