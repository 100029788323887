/* eslint-disable react/no-array-index-key */
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import classNames from '../../../utils/classNames';
// import PropTypes from 'prop-types'

function SubMenu({ text, navigation }) {
  return (
    <Menu as="div" className=" relative">
      <div>
        <Menu.Button className="text-sm">
          <p className="text-white font-semibold hover:bg-indigo-400 px-2 py-1 rounded-md lg:px-1 lg:text-sm 2xl:text-base active:bg-blue-600 font-poppins truncate lg:w-14 xl:w-fit">
            {text}
          </p>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-left absolute mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {navigation.map((item, index) => (
            <Menu.Item key={index}>
              {({ active }) => (
                <Link
                  to={item.href}
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700'
                  )}
                >
                  {item.name}
                </Link>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

SubMenu.propTypes = {};

export default SubMenu;
