/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../../utils/classNames';

function PrimaryButton({
  generate,
  type = 'button',
  text,
  blue,
  modal,
  handleClick,
  delet,
  disabled = false,
}) {
  return (
    <button
      disabled={disabled}
      type={type}
      className={
        generate
          ? 'bg-purpleTH rounded-md text-white py-1 px-1 font-semibold text-sm hover:bg-indigo-700 font-poppins'
          : classNames(
              'rounded-md font-bold text-base py-1.5 px-3 font-poppins',
              disabled
                ? 'text-white bg-gray-500'
                : blue
                ? 'text-white bg-purpleT hover:bg-purpleTH'
                : modal
                ? 'text-purpleT bg-white hover:bg-purpleTH hover:text-white border border-purpleT'
                : delet
                ? 'text-red-800 bg-white hover:bg-red-600 hover:text-white border border-red-600'
                : 'text-white bg-purpleT hover:bg-purpleTH'
            )
      }
      onClick={handleClick}
    >
      {text}
    </button>
  );
}

PrimaryButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default PrimaryButton;
