/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Tabs } from '@mantine/core';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import classNames from '../../../../_desingModels/utils/classNames';

function DinamicFormEdit({ dataForm, setOpened, view, data }) {
  const [tabN, setTabN] = useState(null);
  const [formulario, setFormulario] = useState({});
  const isChecked = true;

  useEffect(() => {
    if (dataForm?.tabs && dataForm.tabs.length > 0) {
      setTabN(dataForm.tabs[0]);
    }
  }, [dataForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const dataExamForm = dataForm?.camposAdicionales?.map((form) => {
      if (form.tipo === 'input') {
        return {
          [form.nombre]: formData.get(form.nombre),
        };
      }
      if (form.tipo === 'select') {
        return {
          [form.nombre]: formData.get(form.nombre),
        };
      }
      if (form.tipo === 'checkbox') {
        const checkOption = form.opciones.map((opt) => ({
          [opt]: formData.get(opt) !== null,
        }));
        return {
          [form.nombre]: checkOption,
        };
      }
      if (form.tipo === 'radio') {
        return {
          [form.nombre]: formData.get(form.nombre),
        };
      }
      if (form.tipo === 'titulo') {
        return {
          [form.nombre]: form.nombre,
        };
      }
      if (form.tipo === 'subtitulo') {
        return {
          [form.nombre]: form.nombre,
        };
      }
      if (form.tipo === 'textarea') {
        return {
          [form.nombre]: formData.get(form.nombre),
        };
      }
      if (form.tipo === 'tableRadio') {
        const listrows = form.optionname.map((opt) => {
          const row = {
            [opt]: null,
          };

          if (form.withChange) {
            row[opt] = formData.get(`${opt}check`);
          } else {
            row[opt] = formData.get(`${opt}radio`);
          }

          if (form.withInput) {
            row[`${opt}Input`] = formData.get(`${opt}input`);
          }

          return row;
        });
        return {
          [form.nombre.split(' ').join('')]: form.nombre,
          listrows,
        };
      }
      if (form.tipo === 'tableNPHE') {
        const listrows = form.optionname.map((opt) => ({
          [opt]: formData.get(`${opt}`),
          [`${opt}Input`]: formData.get(`${opt}input`),
        }));
        return {
          [form.nombre.split(' ').join('')]: form.nombre,
          listrows,
        };
      }
      if (form.tipo === 'tableTextarea') {
        const listrows = form.optionname.map((opt) => ({
          [opt]: formData.get(`${opt}`),
        }));
        return {
          [form.nombre.split(' ').join('')]: form.nombre,
          listrows,
        };
      }
      if (form.tipo === 'tableSelect') {
        const listrows = form.optionname.map((opt) => ({
          [`${opt}-select`]: formData.get(`${opt}select`),
          [`${opt}-input`]: formData.get(`${opt}input`),
        }));
        return {
          [form.nombre.split(' ').join('')]: form.nombre,
          listrows,
        };
      }
      if (form.tipo === 'onlyInput') {
        const inputs = [1, 2, 3];
        const firstRow = inputs.map((opt) => ({
          [`${form.subnombrefirst}${opt}`]: formData.get(
            `${form.subnombrefirst}-${opt}`
          ),
        }));
        const secondRow = inputs.map((opt) => ({
          [`${form.subnombresecond}${opt}`]: formData.get(
            `${form.subnombresecond}-${opt}`
          ),
        }));
        const thirdRow = inputs.map((opt) => ({
          [`${form.subnombrethird}${opt}`]: formData.get(
            `${form.subnombrethird}-${opt}`
          ),
        }));
        const fourthRow = inputs.map((opt) => ({
          [`${form.subnombrefourth}${opt}`]: formData.get(
            `${form.subnombrefourth}-${opt}`
          ),
        }));
        return {
          [form.nombre.split(' ').join('')]: form.nombre,
          firstRow,
          secondRow,
          thirdRow,
          fourthRow,
        };
      }
      if (form.tipo === 'examOido') {
        const inputsj = [1, 2, 3, 4, 5, 6, 7, 8, 9];
        const inputsi = [0, 1, 2, 3];
        const tableD = inputsi.map((colum) =>
          inputsj.map((fila) => ({
            [`d-${colum}-${fila}`]: formData.get(`dInput.${colum}.${fila}`),
          }))
        );
        const tableI = inputsi.map((colum) =>
          inputsj.map((fila) => ({
            [`d-${colum}-${fila}`]: formData.get(`iInput.${colum}.${fila}`),
          }))
        );
        return {
          [`${form.id}derecho`]: formData.get(`${form.id}derecho`),
          [`${form.id}izquierdo`]: formData.get(`${form.id}izquierdo`),
          [`${form.id}select-D`]: formData.get(`${form.tipo}derecho-select`),
          [`${form.id}select-I`]: formData.get(`${form.tipo}izquierdo-select`),
          [`${form.id}observ`]: formData.get(`${form.id}observ`),
          tableD,
          tableI,
        };
      }
      return null;
    });
    /* console.log(data, 'hola');
    console.log(dataForm?.camposAdicionales, 'hola2'); */
  };
  const generarCampo = (campo, dataExam) => {
    switch (campo.tipo) {
      case 'input':
        return (
          <div key={campo.id} className="w-fit flex flex-row">
            <label className="flex flex-col font-bold text-purpleT">
              {campo.label}
              <input
                className="w-[250px] rounded-lg p-1.5 text-black/60 bg-white border border-purpleT"
                type={campo.subtipo}
                name={campo.nombre}
                onChange={(e) =>
                  setFormulario({
                    ...formulario,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </label>
          </div>
        );
      case 'select':
        return (
          <div key={campo.id} className="w-fit flex flex-row">
            <label className="flex flex-col font-bold text-purpleT">
              {campo.label}
              <select
                className="w-[195px] p-2 truncate text-purpleT font-bold rounded-md border border-purpleT pr-6 text-sm flex"
                name={campo.nombre}
                onChange={(e) =>
                  setFormulario({
                    ...formulario,
                    [e.target.name]: e.target.value,
                  })
                }
              >
                {campo.opciones.map((opcion) => (
                  <option key={opcion} value={opcion}>
                    {opcion}
                  </option>
                ))}
              </select>
            </label>
          </div>
        );
      case 'checkbox':
        return (
          <fieldset
            className="w-[195px] rounded-md border border-purpleT px-2 pb-2"
            key={campo.id}
          >
            <legend className="px-2 font-bold text-purpleT">
              {campo.label}
            </legend>
            {campo.opciones.map((option) => (
              <div key={option} className="flex">
                <label>
                  <input
                    className="mr-2 bg-white border border-purpleT"
                    type="checkbox"
                    name={option}
                    onChange={(e) =>
                      setFormulario({
                        ...formulario,
                        [e.target.name]: e.target.checked,
                      })
                    }
                  />
                  {option}
                </label>
              </div>
            ))}
          </fieldset>
        );
      case 'radio':
        return (
          <fieldset
            className="w-[195px] rounded-md border border-purpleT px-2 pb-2"
            key={campo.id}
          >
            <legend className="px-2 font-bold text-purpleT">
              {campo.label}
            </legend>
            {campo.opciones.map((option) => (
              <div key={option} className="flex">
                <label>
                  <input
                    className="mr-2 bg-white border border-purpleT"
                    type="radio"
                    value={option}
                    name={campo.nombre}
                    onChange={(e) =>
                      setFormulario({
                        ...formulario,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {option}
                </label>
              </div>
            ))}
          </fieldset>
        );
      case 'textarea':
        return (
          <div key={campo.id} className="w-full">
            <label className="w-full flex flex-col  font-bold text-purpleT">
              {campo.label}
              <textarea
                className="w-full rounded-lg p-1.5 text-black/60 bg-white border border-purpleT resize-none"
                name={campo.nombre}
                onChange={(e) =>
                  setFormulario({
                    ...formulario,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </label>
          </div>
        );
      case 'tableRadio':
        return (
          <table
            key={campo.id}
            className="w-[50%] border-collapse border border-purpleT"
          >
            <thead>
              <tr>
                <td className="w-[40%] border border-purpleT px-2">
                  {campo.nombre}
                </td>
              </tr>
            </thead>
            <tbody>
              {campo?.optionlabel.map((element, index) => (
                <tr key={element} className="h-[35px]">
                  <td className="border border-purpleT px-2">{element}</td>
                  {campo.withChange ? (
                    <td className="w-fit border border-purpleT px-2">
                      <input
                        className="bg-white border border-purpleT"
                        type="checkbox"
                        name={`${campo.optionname[index]}check`}
                      />
                    </td>
                  ) : (
                    <>
                      <td
                        className={classNames(
                          'border border-purpleT px-2',
                          campo.withInput ? 'w-[15%]' : 'w-[30%]'
                        )}
                      >
                        <div className="flex">
                          <label>
                            <input
                              className="mr-2 bg-white border border-purpleT"
                              type="radio"
                              name={`${campo.optionname[index]}radio`}
                              value="si"
                            />
                            Si
                          </label>
                        </div>
                      </td>
                      <td
                        className={classNames(
                          'border border-purpleT px-2',
                          campo.withInput ? 'w-[15%]' : 'w-[30%]'
                        )}
                      >
                        <div className="flex">
                          <label>
                            <input
                              className="mr-2 bg-white border border-purpleT"
                              type="radio"
                              name={`${campo.optionname[index]}radio`}
                              defaultChecked={isChecked}
                              value="no"
                            />
                            No
                          </label>
                        </div>
                      </td>
                    </>
                  )}
                  {campo.withInput ? (
                    <td className="border border-purpleT px-2">
                      <input
                        className="w-full h-full py-1 rounded-lg text-black/60 bg-white border border-purpleT"
                        type="text"
                        name={`${campo.optionname[index]}input`}
                      />
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        );
      case 'tableNPHE':
        return (
          <table
            key={campo.id}
            className="w-[100%] border-collapse border border-purpleT"
          >
            <thead>
              <tr>
                <td className="w-[30%] border border-purpleT px-2">
                  {campo.nombre}
                </td>
              </tr>
            </thead>
            <tbody>
              {campo?.optionlabel.map((element, index) => (
                <tr key={element} className="h-[35px]">
                  <td className="border border-purpleT px-2">{element}</td>
                  <td className="border border-purpleT px-2">
                    <div className="flex">
                      <label>
                        <input
                          className="mr-2 bg-white border border-purpleT"
                          type="radio"
                          name={campo.optionname[index]}
                          defaultChecked={isChecked}
                          value="nada"
                        />
                        Nada
                      </label>
                    </div>
                  </td>
                  <td className="border border-purpleT px-2">
                    <div className="flex">
                      <label>
                        <input
                          className="mr-2 bg-white border border-purpleT"
                          type="radio"
                          name={campo.optionname[index]}
                          value="poco"
                        />
                        Poco
                      </label>
                    </div>
                  </td>
                  <td className="border border-purpleT px-2">
                    <div className="flex">
                      <label>
                        <input
                          className="mr-2 bg-white border border-purpleT"
                          type="radio"
                          name={campo.optionname[index]}
                          value="habitual"
                        />
                        Habitual
                      </label>
                    </div>
                  </td>
                  <td className="border border-purpleT px-2">
                    <div className="flex">
                      <label>
                        <input
                          className="mr-2 bg-white border border-purpleT"
                          type="radio"
                          name={campo.optionname[index]}
                          value="excesivo"
                        />
                        Excesivo
                      </label>
                    </div>
                  </td>
                  <td className="border border-purpleT px-2">
                    <input
                      className="w-full h-full py-1 rounded-lg text-black/60 bg-white border border-purpleT"
                      type="text"
                      name={`${campo.optionname[index]}input`}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      case 'tableTextarea':
        return (
          <table
            key={campo.id}
            className="w-[80%] border-collapse border border-purpleT"
          >
            <thead>
              <tr>
                <td className="w-[35%] border border-purpleT px-2">
                  {campo.nombre}
                </td>
              </tr>
            </thead>
            <tbody>
              {campo?.optionlabel.map((element, index) => (
                <tr
                  key={element}
                  className={campo.withInput ? 'h-[35px]' : 'h-fit'}
                >
                  <td className="border border-purpleT px-2">{element}</td>
                  <td
                    className={
                      campo.withInput
                        ? 'w-[65%] border border-purpleT px-2'
                        : 'w-[65%] border border-purpleT px-2 pt-1'
                    }
                  >
                    {campo.withInput ? (
                      <input
                        className="w-full h-full py-1 rounded-lg text-black/60 bg-white border border-purpleT"
                        type="text"
                        name={campo.optionname[index]}
                      />
                    ) : (
                      <textarea
                        className="w-full h-[40px] p-1 text-black/60 bg-white border border-purpleT resize-none"
                        name={campo.optionname[index]}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      case 'tableSelect':
        return (
          <table
            key={campo.id}
            className="w-[100%] border-collapse border border-purpleT"
          >
            <thead>
              <tr className="border border-purpleT">
                <th colSpan="3" className="pl-2">
                  {campo.nombre}
                </th>
              </tr>
              <tr className="border border-purpleT">
                <td className="pl-2">{campo.subnombre}</td>
                <td className="border border-purpleT" />
                <td className="pl-2">Comentarios</td>
              </tr>
            </thead>
            <tbody>
              {campo?.optionlabel.map((element, index) => (
                <tr key={element} className="h-fit">
                  <td className="w-[30%] border border-purpleT px-2">
                    {element}
                  </td>
                  <td className="w-[25%] border border-purpleT px-2 pt-1">
                    <select
                      className="w-[160px] truncate font-poppins text-purpleT font-bold rounded-md border border-purpleT pr-6 text-sm flex"
                      name={`${campo.optionname[index]}select`}
                    >
                      {['Normal', 'Grave', 'Muy Grave'].map((op) => (
                        <option key={`${op}-${element}`} value={op}>
                          {op}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="w-[45%] border border-purpleT px-2">
                    <input
                      className="w-full h-full py-1 rounded-lg text-black/60 bg-white border border-purpleT"
                      type="text"
                      name={`${campo.optionname[index]}input`}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      case 'onlyInput':
        return (
          <div className="w-full">
            <table
              key={campo.nombre}
              className="border-collapse border border-purpleT w-full mb-1"
            >
              <thead>
                <tr className="border border-purpleT">
                  <th colSpan="2" className="pl-2">
                    {campo.nombre}
                  </th>
                </tr>
                <tr>
                  <th className="pl-2 border border-purpleT">
                    {campo.subnombrefirst}
                  </th>
                  <th className="pl-2 border border-purpleT">
                    {campo.subnombresecond}
                  </th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3].map((element) => (
                  <tr key={element}>
                    <td className="border-r border-purpleT px-2">
                      <input
                        className="w-full py-1 rounded-lg text-black/60 bg-white border border-purpleT"
                        type="text"
                        name={`${campo.subnombrefirst}-${element}`}
                      />
                    </td>
                    <td className="border-r border-purpleT px-2">
                      <input
                        className="w-full py-1 rounded-lg text-black/60 bg-white border border-purpleT"
                        type="text"
                        name={`${campo.subnombresecond}-${element}`}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table
              key={campo.subnombrethird}
              className="border-collapse border border-purpleT w-full"
            >
              <thead>
                <tr>
                  <th className="pl-2 border border-purpleT">
                    {campo.subnombrethird}
                  </th>
                  <th className="pl-2 border border-purpleT">
                    {campo.subnombrefourth}
                  </th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3].map((element) => (
                  <tr key={element}>
                    <td className="w-[70%] border-r border-purpleT px-2">
                      <input
                        className="w-full py-1 rounded-lg text-black/60 bg-white border border-purpleT"
                        type="text"
                        name={`${campo.subnombrethird}-${element}`}
                      />
                    </td>
                    <td className="w-[30%] border-r border-purpleT px-2">
                      <input
                        className="w-full py-1 rounded-lg text-black/60 bg-white border border-purpleT"
                        type="text"
                        name={`${campo.subnombrefourth}-${element}`}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      case 'examOido':
        return (
          <>
            <div key={`otoscopia${campo.id}`}>
              <table className="w-[100%] border-collapse border border-purpleT mb-1">
                <thead>
                  <tr className="border border-purpleT">
                    <th colSpan="4">OTOSCOPÍA</th>
                  </tr>
                  <tr>
                    <th className="w-[20%] border border-purpleT">
                      Oído derecho
                    </th>
                    <td className=" w-[30%]border border-purpleT px-1 pt-1">
                      <textarea
                        name={`${campo.id}derecho`}
                        className="w-full h-full p-1 text-black/60 bg-white border border-purpleT resize-none"
                      />
                    </td>
                    <th className="w-[20%] border border-purpleT">
                      Oído derecho
                    </th>
                    <td className="w-[30%]border border-purpleT px-1 pt-1">
                      <textarea
                        name={`${campo.id}izquierdo`}
                        className="w-full h-full p-1 text-black/60 bg-white border border-purpleT resize-none"
                      />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      className="border border-purpleT px-2 py-0.5"
                      colSpan="2"
                    >
                      <select
                        className="w-full truncate font-poppins text-purpleT font-bold rounded-md border border-purpleT pr-6 text-sm flex"
                        name={`${campo.tipo}derecho-select`}
                      >
                        <option value="CAEDerecho">
                          CAE: Permeable, Se visualiza membrana timpánica
                          íntegra
                        </option>
                      </select>
                    </td>
                    <td
                      className="border border-purpleT px-2 py-0.5"
                      colSpan="2"
                    >
                      <select
                        className="w-full truncate font-poppins text-purpleT font-bold rounded-md border border-purpleT pr-6 text-sm flex"
                        name={`${campo.tipo}izquierdo-select`}
                      >
                        <option value="CAEIzquierdo">
                          CAE: Permeable, Se visualiza membrana timpánica
                          íntegra
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-purpleT px-2" colSpan="4">
                      Observaciones:
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-purpleT px-2 pt-1" colSpan="4">
                      <textarea
                        name={`${campo.id}observ`}
                        className="w-full h-full p-1 text-black/60 bg-white border border-purpleT resize-none"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="w-full flex justify-between">
              <table
                key={`oidoIzquierdo${campo.id}`}
                className="w-[50%] border-collapse border border-purpleT mb-1"
              >
                <thead>
                  <tr className="border border-purpleT">
                    <th colSpan="10" className="pl-2">
                      Oído Izquierdo
                    </th>
                  </tr>
                  <tr>
                    {[
                      '',
                      125,
                      250,
                      500,
                      1000,
                      2000,
                      3000,
                      4000,
                      6000,
                      8000,
                    ].map((e) => (
                      <th
                        key={`headFilaIzquierdo${e}`}
                        className={
                          e === ''
                            ? 'w-[10%] text-sm border border-purpleT px-2'
                            : 'text-sm border border-purpleT px-2'
                        }
                      >
                        {e}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {['V.A.', 'V.O.', 'Enmasc. aérea', 'Enmasc. ósea'].map(
                    (element, index) => (
                      <tr key={`inputsColumnaIzquierdo${index}`}>
                        <td className="text-sm border border-purpleT px-1">
                          {element}
                        </td>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((e) => (
                          <td
                            key={`inputsFilaIzquierdo${e}`}
                            className="text-sm border border-purpleT px-2 py-1"
                          >
                            <input
                              className="w-full p-[2px] rounded-sm text-sm text-black/60 bg-white border border-purpleT"
                              type="text"
                              name={`iInput.${index}.${e}`}
                            />
                          </td>
                        ))}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <table
                key={`oidoDerecho${campo.id}`}
                className="w-[50%] border-collapse border border-purpleT mb-1"
              >
                <thead>
                  <tr className="border border-purpleT">
                    <th colSpan="10" className="pl-2">
                      Oído Derecho
                    </th>
                  </tr>
                  <tr>
                    {[
                      '',
                      125,
                      250,
                      500,
                      1000,
                      2000,
                      3000,
                      4000,
                      6000,
                      8000,
                    ].map((e) => (
                      <th
                        key={`headFilaDerecho${e}`}
                        className={
                          e === ''
                            ? 'w-[10%] text-sm border border-purpleT px-2'
                            : 'text-sm border border-purpleT px-2'
                        }
                      >
                        {e}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {['V.A.', 'V.O.', 'Enmasc. aérea', 'Enmasc. ósea'].map(
                    (element, index) => (
                      <tr key={`inputsColumnaDerecho${index}`}>
                        <td className="text-sm border border-purpleT px-1">
                          {element}
                        </td>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((e) => (
                          <td
                            key={`inputsFilaDerecho${e}`}
                            className="text-sm border border-purpleT px-2 py-1"
                          >
                            <input
                              className="w-full p-[2px] rounded-sm text-sm text-black/60 bg-white border border-purpleT"
                              type="text"
                              name={`dInput.${index}.${e}`}
                            />
                          </td>
                        ))}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </>
        );
      case 'titulo':
        return (
          <div key={campo.id} className="w-full mt-0 flex flex-row">
            <h1 className="w-full text-start text-xl font-bold text-purpleT">
              {campo.nombre}
            </h1>
          </div>
        );
      case 'subtitulo':
        return (
          <div key={campo.id} className="w-full mt-0 flex flex-row">
            <h2 className="w-full text-start text-lg font-medium text-purpleT">
              {campo.nombre}
            </h2>
          </div>
        );
      default:
        return null;
    }
  };
  // console.log(dataForm?.tabs[0]);
  return tabN ? (
    <form onSubmit={handleSubmit}>
      <Tabs value={tabN} onTabChange={setTabN}>
        <Tabs.List grow>
          {dataForm?.tabs?.map((tab) => (
            <Tabs.Tab key={tab} value={tab}>
              {tab}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        <section className="w-full flex flex-wrap gap-4 mt-2 mb-6 px-4">
          {dataForm?.camposAdicionales?.map((campo, index) => (
            <Tabs.Panel
              className={campo.widthContent}
              key={index}
              value={campo.tabSelection}
            >
              {generarCampo(campo)}
            </Tabs.Panel>
          ))}
        </section>
      </Tabs>

      <section className="mb-4 mx-4">
        {view ? (
          <PrimaryButton
            text="Cerrar"
            modal
            handleClick={() => setOpened(false)}
          />
        ) : (
          <PrimaryButton text="Enviar" type="submit" />
        )}
      </section>
    </form>
  ) : (
    <div>Seleccione un exámen</div>
  );
}

export default DinamicFormEdit;
