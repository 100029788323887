/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Notification } from '@mantine/core';
import Wrapper from '../../../layaouts/wrapper/Wrapper';
import H2 from '../../../../_desingModels/textComponents/H2';
import H4 from '../../../../_desingModels/textComponents/H4';
import P from '../../../../_desingModels/textComponents/P';
import H1 from '../../../../_desingModels/textComponents/H1';
import RoleCard from '../../../layaouts/cards/RoleCard';
import PrimaryButton from '../../../../_desingModels/layout/components/Buttons/PrimaryButton';
import ModalWithBtn from '../../../layaouts/modal/ModalWithBtn';
import ModalFormExams from './ModalFormExams';
import GenericButton from '../../../../_desingModels/layout/components/Buttons/GenericButton';
import TrashIconMobile from '../../../../_desingModels/iconComponets/TrashIcon';
// import Input from '../../../../_desingModels/layout/components/form/Input/Input';
import { useExam, usePermissions, useExamsUser } from '../../../../hooks/exams';
import { useRoleCombo } from '../../../../hooks/roles';
import ModalFormUser from './ModalFormUser';
import ModalFormRole from './ModalFormRole';
// import DropzoneImage from '../../../../_desingModels/layout/components/image/DropzoneImage';
import ModalFirma from './ModalFirma';
import {
  useDeleteExamUser,
  useDeleteRoleUser,
  useUpdateExamPermission,
} from '../../../../hooks/users';
import XIcon from '../../../../_desingModels/iconComponets/XIcon';
import CheckCircleIcon from '../../../../_desingModels/iconComponets/CheckCircle';
import ReadingIcon from '../../../../_desingModels/iconComponets/Reading';
import WritingIcon from '../../../../_desingModels/iconComponets/Writing';
import signUser from '../../../../_desingModels/assets/media/sign.jpg';

// import Select from '../../../../_desingModels/layout/components/select/Select';
// import Segment from '../../../../_desingModels/layout/components/select/Segment';

function UserDeatil({ data }) {
  const [opened, setOpened] = useState(false);
  const [openedUser, setOpenedUser] = useState(false);
  const [dataRole, setDataRole] = useState(false);
  const [firma, setFirma] = useState(false);
  const comboExams = useExam();
  const comboPermissions = usePermissions();
  const detailExamsUser = useExamsUser(data.id);
  const comboRole = useRoleCombo();
  // console.log(detailExamsUser?.data?.user_exames, 'permisoUser');
  // console.log(comboPermissions?.data?.data?.permiso, 'permiso');
  const signUserUrl = `https://api.euro-salud.com/${data.path}`;
  // console.log(data?.path, 'dataFirma');
  // console.log(detailExamsUser.data?.user_exames, 'examenes');

  const handleClick = () => {
    setOpened(true);
  };
  const handleClickUser = () => {
    setOpenedUser(true);
  };
  const handleClickRole = () => {
    setDataRole(true);
  };
  const {
    mutate,
    isLoading,
    isSuccess,
    isError,
    reset: resetQuery,
  } = useDeleteRoleUser();
  const handleClickDeletedRole = (id) => {
    const newData = {
      id_rol: id,
      id_usuario: data.id,
    };
    mutate(newData);
  };

  const mutateExamPermission = useUpdateExamPermission();
  // console.log(mutateExamPermission);
  const handleChangePermission = (idPer, idExam) => {
    const newPer = {
      id_permiso: parseInt(idPer, 10),
      id_examen: idExam,
      id_usuario: data.id,
    };
    // console.log(newPer);
    mutateExamPermission.mutate(newPer);
  };

  const mutateExam = useDeleteExamUser();
  const handleClickDeleteExam = (idExam) => {
    const newExam = {
      id_usuario: data.id,
      id_examen: idExam,
    };
    // console.log(newExam, 'id examen');
    mutateExam.mutate(newExam);
  };
  const handleClickFirma = () => {
    setFirma(true);
  };

  return (
    <Wrapper nofullScreen>
      <div className="w-full flex p-7 flex-col md:flex-row gap-5">
        <section className="md:w-1/3 w-full flex flex-col">
          <H1 text="Detalle" />
          <div className="mt-4 flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              <H2 text={`${data.nombres} ${data.apellidos}`} />
              <H4 text="Documento de identidad:" blue />
              <P text={data.dni} />
            </div>
            <H4 text="Teléfono:" blue />
            <P text={data.telefono} />
            <H4 text="Email:" blue />
            <P text={data.email} />
            <H4 text="Especialidad:" blue />
            <P text={data.especialidad} />
            <H4 text="N° Colegiatura:" blue />
            <P text={data.no_colegiatura} />
            <PrimaryButton text="Editar" handleClick={handleClickUser} />
            <ModalWithBtn
              opened={openedUser}
              setOpened={setOpenedUser}
              title="Actualizar usuario"
            >
              <ModalFormUser userData={data} setOpened={setOpenedUser} />
            </ModalWithBtn>
            <P text="Reestablecer contraseña" blue />
            <H4 text="Firma Digital" blue />
            <div className="w-full">
              <img
                className="w-60 h-full object-cover object-center"
                src={data.path ? signUserUrl : signUser}
                alt="Firma"
              />
            </div>
            <div className="w-full">
              <PrimaryButton
                text="Agregar firma"
                modal
                handleClick={handleClickFirma}
              />
              <ModalWithBtn
                opened={firma}
                setOpened={setFirma}
                title="Agregar firma digital"
              >
                <ModalFirma userData={data} setOpened={setFirma} />
              </ModalWithBtn>
            </div>
          </div>
        </section>
        <section className="md:w-2/3 w-full flex flex-col h-full">
          <article className="h-2/6">
            <H2 text="Rol" />
            <div className="flex flex-wrap gap-2 border border-azul2 p-4 w-full ">
              <div className="flex flex-wrap gap-2 w-full ">
                {data.roles?.map((role) => (
                  <RoleCard key={role.id}>
                    {role.rol}
                    <GenericButton
                      classNames="px-2 py-2 rounded-md text-white hover:bg-blue-800"
                      handleClick={() => {
                        handleClickDeletedRole(role.id);
                      }}
                    >
                      <TrashIconMobile />
                    </GenericButton>
                  </RoleCard>
                ))}
              </div>
              {isLoading ? (
                <Notification
                  loading
                  title="Uploading data to the server"
                  disallowClose
                >
                  Espere hasta que se carguen los datos, no puede cerrar esta
                  notificación aún
                </Notification>
              ) : (
                <>
                  {isError ? (
                    <Notification
                      icon={<XIcon size={18} />}
                      color="red"
                      title="Error"
                      onClose={resetQuery}
                    >
                      Ups! Hubo un error al guardar los datos
                    </Notification>
                  ) : null}

                  {isSuccess ? (
                    <Notification
                      icon={<CheckCircleIcon size={18} />}
                      color="teal"
                      title="Correcto"
                      onClose={resetQuery}
                    >
                      Se guardarón los datos correctamente
                    </Notification>
                  ) : null}
                </>
              )}
              <div className="flex justify-center w-full ">
                <PrimaryButton
                  text="Agregar"
                  handleClick={handleClickRole}
                  modal
                />
                <ModalWithBtn
                  opened={dataRole}
                  setOpened={setDataRole}
                  title="Actualizar roles de usuario"
                >
                  <ModalFormRole
                    roleUser={data.roles}
                    roleCombo={comboRole?.data?.data?.roles}
                    userId={data.id}
                    setOpened={setDataRole}
                  />
                </ModalWithBtn>
              </div>
            </div>
            <H2 text="Modulos" />
            <div>
              <div className="max-h-36 overflow-y-auto flex flex-wrap gap-2 mt-5 border border-azul2 p-4">
                {data.modulos?.map((module) => (
                  <RoleCard key={module.id}>{module.modulo}</RoleCard>
                ))}
              </div>
            </div>
          </article>
          <article className="h-4/6 mt-2">
            <div className="mt-2 flex items-center gap-3 flex-col md:flex-row">
              <H2 text="Examenes médicos" />
              <PrimaryButton text="Agregar" handleClick={handleClick} />
              <ModalWithBtn
                opened={opened}
                setOpened={setOpened}
                title="Agregar Examen"
              >
                <ModalFormExams
                  userId={data.id}
                  examsData={comboExams?.data?.data?.examenes}
                  setOpened={setOpened}
                  permissionData={comboPermissions?.data?.data?.permiso}
                />
              </ModalWithBtn>
            </div>

            <div className="max-h-80 overflow-y-auto flex flex-col gap-2 mt-4">
              {detailExamsUser.data?.user_exames?.map((exam) => (
                <div
                  className="
                flex justify-between gap-2 p-2 w-full "
                  key={exam.id_examen}
                >
                  <div className="flex ">
                    {exam.permiso === 'Lectura' ? (
                      <ReadingIcon />
                    ) : (
                      <WritingIcon />
                    )}
                    <H4 text={exam.examen} blue />
                  </div>
                  <div className="flex gap-1">
                    <div className="w-full md:w-fit">
                      {/* create select and set defeault value */}
                      <select
                        className="w-40 lg:w-fit truncate font-poppins text-blue-800 font-bold rounded-md border border-blue-600 pr-6 text-sm flex"
                        onChange={(e) => {
                          handleChangePermission(
                            e.target.value,
                            exam.id_examen
                          );
                        }}
                      >
                        <option value={exam.id_permiso}>{exam.permiso}</option>
                        {comboPermissions?.data?.data?.permiso.map(
                          (per) =>
                            per.label !== exam.permiso && (
                              <option key={per.value} value={per.value}>
                                {per.label}
                              </option>
                            )
                        )}
                      </select>
                    </div>
                    <GenericButton
                      classNames="px-2 py-2 rounded-md text-white bg-red-800"
                      handleClick={() => {
                        handleClickDeleteExam(exam.id_examen);
                      }}
                    >
                      <TrashIconMobile />
                    </GenericButton>
                  </div>
                </div>
              ))}
            </div>
          </article>
        </section>
      </div>
    </Wrapper>
  );
}

export default UserDeatil;
